export const manageLookupType = [
  { value: "calltypes", label: "Call Types" },
  { value: "departments", label: "Departments" },
  { value: "phonetypes", label: "Phone Types" },
];

export const manageLookupConfig = {
  headings: [
    {
      headingLabel: "Value",
      icon: "",
      width: "80%",
    },
    {
      headingLabel: "Active",
      icon: "",
      width: "15%",
    },
  ],
  dataNodes: ["label", "check"],
  rowColorNeeded: false,
  primaryKey: "value",
};
export const jsonData = [
  { id: 1, label: "Good", is_active: 0 },
  { id: 2, label: "Fair", is_active: 0 },
  { id: 3, label: "Poor", is_active: 1 },
  { id: 4, label: "STRUCK", is_active: 1 },
];
