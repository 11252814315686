import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTabs from "../../../components/SharedComponents/BasicTabs/BasicTabs";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableComponent from "../../../components/SharedComponents/Table";
import { ProductionCompaniesConfig } from "./Config";
import MessageModal from "../../../../src/components/SharedComponents/MessageModal";
import * as Constants from "../../../constants/constants";
import PostWatchService from "../../../../src/services/service";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
} from "../../../../src/Common/Helper";
import "./ProductionCompanies.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import Message from "../../../../src/Common/Messages.json";

class ProductionCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.deleteItem = null;
    this.state = {
      postInitiated: false,
      renderList: [],
      isSubmit: false,
      isLoading: false,
      tableListSorted: [],
      filters: { comp_name: null },
      page: 1,
      rowsPerPage: 10,
      totalRows: 0,
      currency: null,
      isFetchingCurrency: false,
      sortBy: null,
      sortOrder: "asc",
      a_z: false,
      config: JSON.parse(JSON.stringify({ ...ProductionCompaniesConfig })),
      isMessageModalOpen: false,
      isErrorMessageModalOpen: false,
    };
  }

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  componentDidMount() {
    this.getProductionCompanyDetails();
    this.getCurrency();
  }

  handleInlineEdits = (id, dataItem) => {
    let productCompanyDetails = this.state.renderList;
    this.setState({
      renderList: productCompanyDetails.map(
        (item) => {
          if (item.prod_company_id === id) item["editing"] = true;
          return item;
        },
        () => {
          console.log("handleInlineEdit", this.state.renderList);
        }
      ),
    });
  };

  saveInlineEdits = (obj, index) => {
    let data = { ...obj[index] };
    data.is_active = data.is_active ? 0 : 1;
    if (this.validateBeforeSave(data)) {
      this.postData(data);
    }
  };

  validateBeforeSave = (productionCompanyData) => {
    let canSubmit =
      productionCompanyData?.comp_name?.length > 0 &&
      (productionCompanyData?.comp_name
        ? validateCharctersNumberSpecialCharOnly(
            productionCompanyData?.comp_name
          )
        : true);
    return canSubmit;
  };

  postData = (data) => {
    this.setState({ isPosting: true });
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl +
        `/postproductioncompanies?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id,
      1
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isErrorMessageModalOpen: true,
            isMessageModalOpen: false,
            isPosting: false,
          });
        } else {
          this.setState({
            isMessageModalOpen: false,
            isPosting: false,
            postInitiated: false,
          });
          this.getProductionCompanyDetails();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({ isPosting: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  getProductionCompanyDetails = () => {
    let filters = { ...this.state.filters };
    filters.page_count = this.state.rowsPerPage;
    filters.page_no = this.state.page;
    filters.sort_by = this.state.sortBy;
    filters.sort_order = this.state.sortOrder;
    this.setState({
      isLoading: true,
      isSubmit: true,
      filters,
    });
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getproductioncompanies",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let tableResults = res.data?.records.map((obj) => {
          obj.is_active = obj.is_active ? 0 : 1;
          return obj;
        });
        let productionCompanyDetails = tableResults;
        this.setState({
          renderList: productionCompanyDetails,
          tableListSorted: tableResults,
          totalRows: res.data?.total_records_count,
          isLoading: false,
          isSubmit: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  getPosition = (val) => {
    switch (val) {
      case "Inactive":
        return 0;
      case "Name":
        return 1;
      case "Currency":
        return 2;
      case "Created On":
        return 7;
    }
  };

  getColumnName = (val) => {
    switch (val) {
      case "Inactive":
        return "is_active";
      case "Name":
        return "comp_name";
      case "Currency":
        return "currency_code";
      case "Created On":
        return "created_at";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let order = null;
    let config = JSON.parse(JSON.stringify(this.state?.config));
    if (this.state.a_z && config?.headings[Position]?.icon != "") {
      if (config?.headings[Position]?.icon == "sort-alpha-up") {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-down";
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      config?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      config.headings[Position].icon = "sort-alpha-up";
      order = "asc";
    }
    this.setState(
      { sortBy: col_name, sortOrder: order, config, page: 1 },
      () => {
        this.getProductionCompanyDetails();
      }
    );
  };

  getCurrency = () => {
    this.setState({ isFetchingCurrency: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getcurrencies",
      "",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let filterObj = response.data?.records.map((item) => {
          return {
            value: item.currency_id,
            label: item.currency_code,
            is_active: item.is_active,
          };
        });

        let config = { ...this.state.config };
        config.headings[2].selectOptions = filterObj;
        this.setState({
          currency: response.data?.records,
          showsFilterOptions: filterObj,
          isFetchingCurrency: false,
          config,
        });
      },
      (err) => {}
    );
  };

  onKeyUpChange = (filter, value) => {
    this.setState({ page: 1 });
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [filter]: value,
          page_count: this.state.rowsPerPage,
          page_no: 1,
        },
      }),
      () => {
        this.getProductionCompanyDetails();
      }
    );
  };

  onFilterChange = (filter, value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filter]: value,
      },
    }));
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (this.validateBeforeSave(newDataItem)) {
      let list = [...this.state.renderList];
      let newData = {
        is_active: newDataItem?.is_active ? 0 : 1,
        comp_name: newDataItem?.comp_name,
        currency_code: newDataItem?.currency_code,
        currency_id: newDataItem?.currency_id,
        address: newDataItem?.address,
        city: newDataItem?.city,
        state: newDataItem?.state,
        zip: newDataItem?.zip,
      };
      list.push(newData);
      this.setState({
        renderList: list,
      });
      this.postData(newData);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => {
      this.getProductionCompanyDetails();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        page: 1,
      },
      () => {
        this.getProductionCompanyDetails();
      }
    );
  };

  handleDelCallBack = (id, dataItem) => {
    this.deleteItem = dataItem;
    this.setState({ isMessageModalOpen: true });
  };

  onModalConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postData(item);
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };

  render() {
    let isLoading = this.state.isFetchingCurrency || this.state.isPosting;
    return (
      <div>
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <MDBContainer className="productionCompaniesContainer">
              <MDBRow>
                <MDBCol md={3} className="search">
                  <IconTextField
                    placeholder={"Search by Production Co Name "}
                    value={this.state?.filters?.comp_name || null}
                    onChange={(e) =>
                      validateCharctersNumberSpecialCharOnly(e.target.value) &&
                      this.onFilterChange(
                        "comp_name",
                        e.target.value === null || e.target.value === ""
                          ? null
                          : e.target.value
                      )
                    }
                    icon={"search"}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        this.onKeyUpChange(
                          "comp_name",
                          e.target.value === null || e.target.value === ""
                            ? null
                            : e.target.value
                        );
                      }
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="table-striped">
                {isLoading ? (
                  <div className="ContentLoader">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {this.state.tableListSorted &&
                      this.state?.config?.headings[2]?.selectOptions?.length >
                        0 && (
                        <TableComponent
                          list={this.state.tableListSorted || []}
                          isLoading={this.state?.isLoading || false}
                          className={"clearencRecord"}
                          sortCallback={this.sortColumn}
                          stickyHeader={true}
                          config={this.state?.config}
                          handleAddNewItem={this.handleAddNewItem.bind(this)}
                          editCallback={this.handleInlineEdits.bind(this)}
                          saveCallback={this.saveInlineEdits.bind(this)}
                          addItemToList={true}
                          showMandatory={this.state.postInitiated}
                          handleDelCallBack={this.handleDelCallBack}
                        />
                      )}
                    <div className="Pagination">
                      <TablePagination
                        component="div"
                        count={this.state?.totalRows}
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        page={this.state?.page - 1}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state?.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </div>
                  </>
                )}
              </MDBRow>
            </MDBContainer>
            {this.state?.isMessageModalOpen && (
              <MessageModal
                open={this.state.isMessageModalOpen}
                showCustom={true}
                message={Message.deleteAdmin}
                title={"Confirm Deletion"}
                primaryButtonText={"Ok"}
                secondaryButtonText={"Cancel"}
                hideCancel={false}
                onConfirm={() => this.onModalConfirm()}
                handleClose={(e) =>
                  this.setState({ isMessageModalOpen: false })
                }
              />
            )}
            {this.state?.isErrorMessageModalOpen && (
              <MessageModal
                open={this.state.isErrorMessageModalOpen}
                showCustom={true}
                message={Message.deleteAdminError}
                title={"Message"}
                primaryButtonText={"Ok"}
                hideCancel={true}
                onConfirm={() => this.onErrorModalConfirm()}
                handleClose={(e) =>
                  this.setState({ isErrorMessageModalOpen: false })
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withUserContext(ProductionCompanies);
