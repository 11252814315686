import { Subject } from "rxjs";

const shows$ = new Subject();
const messageModal$ = new Subject();

export const AppService = {
  updateShows: (data) => shows$.next(data),
  getShows: () => shows$.asObservable(),
  showMessageModal: (state) => messageModal$.next(state),
  getMessageModalState: () => messageModal$.asObservable(),
};
