import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import SelectField from "../SelectField/SelectField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicTextField from "../BasicTextField/BasicTextField";
import "./PhoneList.scss";
import {
  validateCustomPhoneNumber,
  validatePlusCharacter,
} from "../../../Common/Helper";

export default class PhoneList extends React.Component {
  constructor(props) {
    super(props);
    this.placeHolderObj = {
      [this.props.numberValueProperty]: null,
      [this.props.typeValueProperty]: null,
    };
  }

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      placeHolderObj: {
        ...prevState.placeHolderObj,
        [field]: value,
      },
    }));
  };

  render() {
    return (
      <MDBRow className="g-0">
        {this.props.items?.map((item, index) => {
          return (
            <>
              <MDBCol md={5} className={"mt-1"}>
                <BasicTextField
                  phoneNumberPlaceholder={
                    this.props?.phoneNumberPlaceholder || ""
                  }
                  onChange={(e) => {
                    this.props.handlePhoneListOnChange(
                      e.target.value,
                      index,
                      this.props.numberValueProperty
                    );
                  }}
                  showMandatory={this.props.showMandatory && index === 0}
                  isMandatory={this.props.isMandatory && index === 0}
                  value={item[this.props.numberValueProperty] || null}
                  limit={this.props?.limit}
                  limitWarning={this.props?.limitWarning}
                  // fieldValid={
                  //   item[this.props.numberValueProperty]
                  //     ? !validateNumbersOnly(
                  //         item[this.props.numberValueProperty]
                  //       )
                  //     : false
                  // }
                  fieldValid={
                    item[this.props.numberValueProperty]
                      ? !validateCustomPhoneNumber(
                          item[this.props.numberValueProperty]
                        )
                      : false
                  }
                  inValidInput={"Please enter valid phone"}
                />
              </MDBCol>
              <MDBCol md={4}>
                <SelectField
                  size="small"
                  options={
                    this.props?.callTypeOptions?.map((item) => ({
                      value: item.id,
                      label: item.value,
                    })) || []
                  }
                  showMandatory={this.props.showMandatory && index === 0}
                  isMandatory={this.props.isMandatory && index === 0}
                  value={item[this.props.typeValueProperty] || null}
                  onChange={(e) => {
                    this.props.handlePhoneListOnChange(
                      e.target.value,
                      index,
                      this.props.typeValueProperty
                    );
                  }}
                  placeholder={this.props?.phoneTypePlaceholder || "-Select-"}
                />
              </MDBCol>
              <MDBCol md={3} className="actions">
                {index !== this.props.items.length - 1 && (
                  <RemoveCircleIcon
                    className="m-1"
                    onClick={() => this.props?.removeCallback(index)}
                  />
                )}{" "}
                {index === this.props.items.length - 1 &&
                  item[this.props.typeValueProperty] &&
                  item[this.props.numberValueProperty] && (
                    <MDBCol md={3} className="actions">
                      <AddCircleIcon
                        className="m-1"
                        onClick={this.props?.addCallback}
                      />
                    </MDBCol>
                  )}
              </MDBCol>
            </>
          );
        })}
      </MDBRow>
    );
  }
}
