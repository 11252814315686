import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import React from "react";
import {
  manageLookupType,
  manageLookupConfig,
} from "../../components/ManageLookup/ManageLookupConfig";
import TableComponent from "../../components/SharedComponents/Table";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import {
  handlePaginationList,
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import sizeLimits from "../../Common/SizeLimits.json";
import { withUserContext } from "../../contexts/UserContext";
import { historyMessageConfig } from "../HistoryMessage/HistoryMessageConfig";
import "./HistoryMessage.scss";

class HistoryMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "",
      tableData: [],
      config: manageLookupConfig,
      showContent: false,
      inlineButtonField: "",
      setIndex: 0,
      sortCount: 0,
      sortBy: "Value",
      isSortByAsc: true,
      formEdited: false,
      rowsPerPage: 5,
      page: 0,
      lookupType: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ lookupType: manageLookupType });
    this.fetchDetails();
  }

  fetchDetails = (val) => {
    this.setState({ selectedType: val, isLoading: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getcallloghistory?callLogId=" +
        this.props?.callLogId
    ).then(
      (response) => {
        // let formattedList = response?.map((item) => ({
        //   value: item.id,
        //   label: item.name,
        //   id: item.id,
        //   check: item.is_active === 1 ? true : false,
        // }));
        this.setState({
          tableData: response.data,
          showContent: true,
          inlineButtonField: "",
          page: 0,
          setIndex: 0,
          isLoading: false,
        });
        this.setState({
          renderList: handlePaginationList(
            this.state.page,
            this.state.rowsPerPage,
            response.data
          ),
        });
      },
      (err) => {
        this.setState({ selectedType: val, isLoading: false });
        console.log("Error in fetching History:", err);
      }
    );
  };

  render() {
    return (
      <MDBContainer className="HistoryContainer">
        <MDBRow>
          <div className="history-popup" id="history-list">
            <TableComponent
              list={this.state.tableData}
              config={historyMessageConfig}
              tableMode={"light"}
              isLoading={this.state.isLoading}
              sortCallback={this.sortSetName}
              className="history-popup-table"
              hyperLinkNavigationCallback={this.handleSetSelection}
            />
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md={8}></MDBCol>
          <MDBCol md={2}></MDBCol>
          <MDBCol md={2} className="d-flex justify-content-end mb-2">
            <BasicButton
              type="inline"
              variant="outlined"
              text={"Close"}
              onClick={this.props.onClose}
              disabled={false}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(HistoryMessage);
