export const userListConfig = {
  headings: [
    {
      headingLabel: "Account",
      icon: "",
      width: "33%",
      dataNode: "acc_code",
      isColumn: true,
    },
    {
      headingLabel: "Description",
      icon: "",
      width: "33%",
      dataNode: "acc_desc",
      isColumn: true,
    },
    {
      headingLabel: "Commits",
      icon: "",
      width: "33%",
      inputType: "text",
      dataNode: "Commits",
      isColumn: true,
    },
  ],
  dataNodes: ["acc_code", "acc_desc", "commit_value"],
  primaryKey: "chartofaccount_id",
};

export const tableData = [
  {
    account: "3706",
    description: "Description Test",
    commit: 2385636,
  },
  {
    account: "2134",
    description: "Do not edit",
    commit: 123344,
  },
];
