export const VIEW_EVENT_PERMISSIONS = "View Event"; //1
export const VIEW_GIFT_PERMISSIONS = "View Gift"; //2
export const CREATE_EVENT_PERMISSIONS = "Create Event"; //1
export const CREATE_GIFT_PERMISSIONS = "Create Gift"; //2
export const MANAGE_PEOPLE_PERMISSIONS = "Manage People"; //3
export const MANAGE_DATA_PERMISSIONS = "Manage Data"; //4
export const LABEL_MANAGE_LOOKUP = "MANAGE_LOOKUP";
// export const APPROVAL_PERMISSIONS = 'Default Approve Request'; //3
// export const CANCEL_PERMISSIONS = 'Cancel Request'; //4
// export const VIEWALLDEALS_PERMISSIONS = 'Can see all Deals'; //5
// export const ASSIGN_PERMISSIONS = 'Assign Request'; //6
// export const FINALAPPROVAL_PERMISSIONS = 'Final Request Approval'; //7
// export const CLOSE_PERMISSIONS = 'Close an Approved Request'; //8

export const PERMISSIONS = [
  CREATE_EVENT_PERMISSIONS,
  CREATE_GIFT_PERMISSIONS,
  MANAGE_PEOPLE_PERMISSIONS,
  MANAGE_DATA_PERMISSIONS,
];
export const LABEL_TYPE = "phonetype_id";
export const LABEL_NUMBER = "phonevalue";
export const LABEL_ASSISTANTS = "Assistants";
export const LABEL_NEW = "New";
export const LABEL_EDIT = "Edit";
export const LABEL_FIND = "Find";
export const LABEL_CALLTYPES = "calltypes";
export const LABEL_PHONETYPES = "phonetypes";
export const MAX_CHAR_5 =
  "Exceeded 5 characters limit. Please update less than 5 characters  ";
export const MAX_CHAR_15 =
  "Exceeded 15 characters limit. Please update less than 15 characters  ";
export const MAX_CHAR_20 =
  "Exceeded 20 characters limit. Please update less than 20 characters  ";
export const MAX_CHAR_50 =
  "Exceeded 50 characters limit. Please update less than 50 characters";
export const MAX_CHAR_100 =
  "Exceeded 100 characters limit. Please update less than 100 characters";
export const MAX_CHAR_2000 =
  "Exceeded 2000 characters limit. Please update less than 2000 characters";
export const MAX_CHAR_2000_2 =
  "Exceeded 2000 characters limit. Please update message less than 2000 characters";
