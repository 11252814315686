export const ProductionCompaniesConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "6%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Name",
      icon: "",
      width: "19%",
      inputType: "text",
    },
    {
      headingLabel: "Currency",
      icon: "",
      width: "10%",
      inputType: "select",
      selectOptions: [],
    },
    {
      headingLabel: "Address",
      width: "20%",
      inputType: "text",
    },
    {
      headingLabel: "City",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "State",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Zip",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Created On",
      icon: "",
      inputType: "text",
      width: "10%",
    },
  ],
  dataNodes: [
    "is_active",
    "comp_name",
    "currency_id",
    "address",
    "city",
    "state",
    "zip",
    "created_at",
  ],
  primaryKey: "prod_company_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "5%",
    },
    {
      dataNode: "comp_name",
      width: "19%",
      isMandatory: true,
    },
    {
      dataNode: "currency_id",
      width: "9%",
      isMandatory: true,
    },
    {
      dataNode: "address",
      width: "20%",
    },
    {
      dataNode: "city",
      width: "9%",
    },
    {
      dataNode: "state",
      width: "10%",
    },
    {
      dataNode: "zip",
      width: "9%",
    },
  ],
};
export const productionCompanyList = [
  {
    id: 1,
    inactive: "yes",
    comp_name: "[Production Company Name]",
    currency_code: "USD",
    address: "[Address]",
    city: "[city]",
    state: "[state]",
    zip: "[zip]",
  },
  {
    id: 2,
    inactive: "yes",
    comp_name: "[Production Company Name]",
    currency_code: "USD",
    address: "[Address]",
    city: "[city]",
    state: "[state]",
    zip: "[zip]",
  },
  {
    id: 3,
    inactive: "yes",
    comp_name: "[Production Company Name]",
    currency_code: "USD",
    address: "[Address]",
    city: "[city]",
    state: "[state]",
    zip: "[zip]",
  },
  {
    id: 4,
    inactive: "yes",
    comp_name: "[Production Company Name]",
    currency_code: "USD",
    address: "[Address]",
    city: "[city]",
    state: "[state]",
    zip: "[zip]",
  },
  {
    id: 5,
    inactive: "yes",
    comp_name: "[Production Company Name]",
    currency_code: "USD",
    address: "[Address]",
    city: "[city]",
    state: "[state]",
    zip: "[zip]",
  },
];
