import { createTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import * as COLORS from "../constants/materialThemeConstants";

const MaterialTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY_COLOR,
    },
    secondary: {
      main: COLORS.SECONDARY_COLOR,
    },
    tertiary: {
      main: COLORS.TERTIARY_COLOR,
    },
    septanary: {
      main: COLORS.SEPTANARY_COLOR,
    },
  },
  typography: {
    fontFamily: [
      "Warner-Bros-Sans",
      "Verdana",
      "acumin-pro-condensed",
      "sans-serif",
    ].join(","),
  },
});

export default MaterialTheme;
