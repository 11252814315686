export const configObjectAllPoUsers = {
  iscolumnFilterRequired: true,
  headings: [
    {
      headingLabel: "PO#",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Date",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Vendor",
      icon: "",
      width: "15%",
    },
    {
      headingLabel: "Total Cost",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Extended Cost",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Show",
      icon: "",
      width: "15%",
    },
    {
      headingLabel: "Season",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Production Company",
      icon: "",
      width: "20%",
    },
  ],

  dataNodes: [
    "po_number",
    "created_at",
    "vendor_name",
    "total_cost_value",
    "extended_cost_value",
    "show_name",
    "season_name",
    "comp_name",
  ],
  primaryKey: "po_id",
  hyperlinks: ["po_number", "show_name"],
  filtersLanding: true,
};

export const allUsersList = [
  {
    id: 1,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 2,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 3,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 4,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 5,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 6,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
];

const configObjectAllUsersModal = {
  dataNode: ["user_name"],
  primaryKey: "id",
  actions: ["view", "trash-alt"],
};

export const initialDashboardDropdownDetails = {
  show_id: null,
  season_id: null,
  episode_id: null,
};
export const ActiveOrInActive = [
  {
    value: 0,
    label: "Inactive",
  },
  {
    value: 1,
    label: "Active",
  },
];
export const postFilters = {
  show_name: null,
  po_prefix: null,
  prod_company_name: null,
  vendor_name: null,
  season_name: null,
  gl_number: null,
  total_cost_below: null,
  total_cost_above: null,
  ext_cost_below: null,
  ext_cost_above: null,
  acc_code: null,
  po_date_before: null,
  po_date_after: null,
  sort_by: null,
  sort_order: null,
  page_count: 10,
  page_no: 1,
  showyear_filter: 1,
  po_number: null,
};

export const chipFilterObject = {
  show_name: { label: "Show Name", value: "" },
  po_prefix: { label: "PO #", value: "" },
  prod_company_name: { label: "Production Company", value: "" },
  vendor_name: { label: "Vendor Name", value: "" },
  season_name: { label: "Season ", value: "" },
  gl_number: { label: "Episode#", value: "" },
  acc_code: { label: "Detail Number", value: "" },
  total_cost_below: { label: "Cost($) Below", value: "" },
  total_cost_above: { label: "Cost($) Above", value: "" },
  ext_cost_below: { label: "ExtCost($) Below", value: "" },
  ext_cost_above: { label: "ExtCost($) above", value: "" },
  po_date_before: { label: "PO date before", value: "" },
  po_date_after: { label: "PO date after", value: "" },
  po_number: { label: "PO#", value: "" },
};
