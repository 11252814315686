export const searchResultsConfig = {
  headings: [
    {
      headingLabel: "In/Out",
      icon: "",
      width: "5%",
    },
    {
      headingLabel: "Call Date",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Call Type",
      icon: "",
      width: "8%",
    },
    {
      headingLabel: "Call From",
      icon: "",
      width: "13%",
    },
    {
      headingLabel: "Message",
      icon: "",
      width: "20%",
    },
    {
      headingLabel: "Call Back No",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "Urgent",
      icon: "",
      width: "4%",
    },
    {
      headingLabel: "TakenBy",
      icon: "",
      width: "10%",
    },
    {
      headingLabel: "",
      icon: "",
      width: "15%",
    },
  ],
  dataNodes: [
    "call_direction_value",
    "created_at",
    "calltype_value",
    "callfrom",
    "message",
    "callback_no",
    "is_urgent",
    "calltaken_by",
  ],
  primaryKey: "calllog_id",
  actionButtons: ["Respond", "Close"],
  urgentCheck: [{ column: "is_urgent", icon: "check" }],
  rowColorNeeded: true,
  rowColorFlag: "is_urgent",
  hyperlinks: ["call_direction"],
  isSearchBar: true,
};

export const SearchData = [
  {
    calllog_id: 1,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "called",
    callfrom: "ABC company",
    message: "Please return message regarding movie1 ",
    callback_no: "999-999-99",
    calltaken_by: "Sudarshan",
    is_urgent: true,
  },

  {
    calllog_id: 2,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "left type",
    callfrom: "ABC company",
    message: "called to say Hi",
    callback_no: "999-999-99",
    calltaken_by: "xyz Reddy",
    is_urgent: true,
  },
  {
    calllog_id: 3,
    call_direction: "In",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "Sudarshan Reddy",
    is_urgent: false,
  },
  {
    calllog_id: 4,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "called",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: true,
  },
  {
    calllog_id: 5,
    call_direction: "In",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 6,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 7,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 8,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "called",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 9,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 10,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: true,
  },
  {
    calllog_id: 11,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 12,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
  {
    calllog_id: 13,
    call_direction: "Out",
    created_at: "4/11/2020",
    calltype_value: "[call type]",
    callfrom: "[Name], [company]",
    message: "[message]",
    callback_no: "999-999-99",
    calltaken_by: "[User Name]",
    is_urgent: false,
  },
];
