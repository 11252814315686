const modalStyles = {
  position: "absolute",
  // overflowY: 'auto',
  // overflowY: 'hidden',
  overflowX: "hidden",
  border: "2px solid grey",
  borderRadius: "8px",
};

export const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    ...modalStyles,
    boxShadow: theme.shadows[5],
    backgroundMDBColor: theme.palette.background.paper,
    width: "60%",
    height: "80%",
  },
  // statusDate: {
  //     maxWidth: '440px !important',
  // },
  addLocation: {
    maxWidth: "55% !important",
  },
  NewProductionDetails: {
    maxWidth: "55% !important",
  },
  addWorkweek: {
    maxWidth: "440px !important",
  },
  newEpisode: {
    maxWidth: "720px !important",
  },
  newSeason: {
    maxWidth: "680px !important",
  },
  newShow: {
    maxWidth: "770px !important",
  },
  staffMember: {
    maxWidth: "50% !important",
  },
  ChangeLocation: {
    maxWidth: "440px !important",
  },
  roles: {
    maxWidth: "900px !important",
  },
  AddPerformer: {
    maxWidth: "900px !important",
  },
  ManageLookup: {
    maxWidth: "450px !important",
  },

  label: {
    fontSize: "0.75rem",
  },
});
