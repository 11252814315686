import React from "react";
import Button from "@material-ui/core/Button";
import { MDBIcon } from "mdbreact";
import "./BasicButton.scss";

export default class BasicButton extends React.Component {
  handleFileSelect = (event) => {
    var fileName = event.target.files?.[0]?.name;
    if (fileName == undefined || fileName == "") alert("Please select file");
    else this.props?.uploadFile(event.target.files?.[0]);
  };
  render() {
    return (
      <div
        className={
          this.props?.type === "inline"
            ? "BasicButtonContainer Button-inline"
            : "BasicButtonContainer"
        }
      >
        {this.props?.inputType == "file" ? (
          <label htmlFor={"upload-file-" + this.props?.id}>
            <input
              type="file"
              style={{ display: "none" }}
              id={"upload-file-" + this.props?.id}
              onChange={this.handleFileSelect.bind(this)}
              value=""
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            {this.props.text ? (
              <Button
                component="div"
                variant={this.props?.variant || "contained"}
                size="small"
                // color="tertiary"
                ref={this.props.ref || ""}
                className={
                  "text-tertiary " +
                  (this.props?.className ? this.props?.className : "")
                }
                disabled={this.props?.disabled ? this.props?.disabled : false}
              >
                {this.props?.icon ? <MDBIcon icon={this.props?.icon} /> : null}
                {this.props?.text ? this.props?.text : ""}
              </Button>
            ) : (
              <MDBIcon className={"action-icon"} icon={this.props?.icon} />
            )}
          </label>
        ) : (
          <Button
            onClick={this.props?.onClick}
            variant={this.props?.variant || "contained"}
            size="small"
            // color="tertiary"
            ref={this.props.ref || ""}
            className={
              "text-tertiary " +
              (this.props?.className ? this.props?.className : "")
            }
            disabled={this.props?.disabled ? this.props?.disabled : false}
          >
            {this.props?.isLoading ? <MDBIcon icon={"spinner"} /> : null}
            {this.props?.icon ? <MDBIcon icon={this.props?.icon} /> : null}
            {this.props?.text ? this.props?.text : ""}
          </Button>
        )}
      </div>
    );
  }
}
