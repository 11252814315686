export const poExportConfig = {
  headings: [
    {
      headingLabel: "PO Num",
      icon: "",
      width: "50%",
      inputType: "text",
    },
    {
      headingLabel: "Check to Delete",
      icon: "",
      width: "50%",
      inputType: "checkbox",
    },
  ],
  dataNodes: ["prefixnum", "check"],
  primaryKey: "po_number",
};

export const POExportData = [
  {
    po_id: "SH 12300",
    selected: false,
    created_at: "11/4/2022",
    vendor_name: "Test Vendor 1",
    total_cost: "387323",
  },
  {
    po_id: "CS 33245",
    selected: true,
    created_at: "21/2/2022",
    vendor_name: "Test Vendor 2",
    total_cost: "1634565",
  },
];
