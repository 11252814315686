const ActiveOrInActive = [
  {
    value: 0,
    label: "Inactive",
  },
  {
    value: 1,
    label: "Active",
  },
];

const configObjectAllUsers = {
  iscolumnFilterRequired: true,
  headings: [
    {
      headingLabel: "Full Name",
      icon: "",
      width: "15%",
      inputType: "select",
      isColumn: true,
      dataNode: "full_name",
    },
    {
      headingLabel: "Display Name",
      icon: "",
      width: "13%",
      inputType: "select",
      isColumn: true,
      dataNode: "display_name",
    },
    {
      headingLabel: "Tenant",
      icon: "",
      width: "10%",
      inputType: "select",
      isColumn: true,
      dataNode: "tenant_name",
    },
    {
      headingLabel: "Title",
      icon: "",
      width: "9%",
      inputType: "text",
      isColumn: true,
      dataNode: "title",
    },
    {
      headingLabel: "User Level",
      icon: "",
      width: "10%",
      inputType: "select",
      isColumn: true,
      dataNode: "user_role",
    },
    {
      headingLabel: "Email",
      icon: "",
      width: "18%",
      inputType: "select",
      isColumn: true,
      dataNode: "login_email",
    },
    {
      headingLabel: "Phone",
      icon: "",
      width: "10%",
      inputType: "select",
      isColumn: true,
      dataNode: "phone",
    },
    {
      headingLabel: "Status",
      icon: "",
      width: "7%",
      inputType: "dropdown",
      isColumn: true,
      dataNode: "is_active_text",
      selectOptions: "statusOptions",
    },
    {
      headingLabel: "Last Updated",
      icon: "",
      width: "10%",
      inputType: "dropdown",
      isColumn: true,
      dataNode: "updated_at",
    },
  ],
  dataNodes: [
    "full_name",
    "display_name",
    "tenant_name",
    "title",
    "user_type",
    "login_email",
    "phone",
    "is_active_text",
    "updated_at",
  ],
  primaryKey: "user_id",
  hyperlinks: ["full_name"],
  actions: ["pen"],
  changeColourToRed: ["In-Active"],
  changeColourToGreen: ["Active"],
  filtersLanding: true,
};

const allUsersList = [
  {
    id: 1,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 2,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 3,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 4,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 5,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
  {
    id: 6,
    name: "User Name",
    studio: "Studio",
    group: "Group Name",
    department: "Department",
    title: "Title",
    email: "Email",
    phone: "Phone Number",
    status: "Active",
  },
];

const configObjectAllUsersModal = {
  dataNode: ["user_name"],
  primaryKey: "id",
  actions: ["view", "trash-alt"],
};

export {
  ActiveOrInActive,
  allUsersList,
  configObjectAllUsers,
  configObjectAllUsersModal,
};

export const postFilters = {
  full_name: null,
  display_name: null,
  usertype_id: null,
  title: null,
  is_active: null,
  show_name: null,
  sort_by: null,
  isSortByAsc: null,
  page_count: 10,
  page_no: 1,
};

export const chipFilterObject = {
  full_name: { label: "Full Name", value: "" },
  display_name: { label: "Display Name", value: "" },
  title: { label: "Title", value: "" },
  is_active: { label: "Status", value: "" },
  usertype_id: { label: "User Level", value: "" },
  show_name: { label: "(Has access to)Show Name", value: "" },
};

export const NewUser = {
  user_id: null,
  full_name: null,
  display_name: null,
  tenant_id: null,
  tenant_name: null,
  usertype_id: null,
  user_type: null,
  login_email: null,
  notification_email: null,
  phone: null,
  title: null,
  is_active: 1,
  is_user_provisioner: 0,
  user_shows: [],
  is_active_text: null,
};
