import React from "react";
import { MDBCol, MDBRow } from "mdbreact";

import {
  searchResultsConfig,
  SearchData,
} from "../../components/PhoneLogResults/PhoneLogResultsConfig";
import TableComponent from "../../components/SharedComponents/Table";
import { withUserContext } from "../../contexts/UserContext";
import "./PhoneLogResults.scss";

class PhoneLogResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewSetModal: false,
      isLoadingSetList: false,
      tableData: props.data,
      filterCriteria: {
        selectedSetId: "",
        selectedShowId: "",
        selectedIdPrefix: "",
        selectedIdSuffix: "",
        selectedCategoryId: "",
        selectedConditionId: "",
        selectedLocationId: "",
        isOpen: null,
        isStruck: null,
        keywords: "",
        sortBy: "",
        order: "",
      },
      data: null,
      pageNo: 1,
      rowsPerPage: 10,
      isLoadingSets: false,
      setResults: [],
    };
    this.noOfRows = 10;
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
    document
      .getElementById("phoneLogTableContainer")
      .addEventListener("scroll", () => {
        this.handleScroll();
      });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data?.length !== state.data?.length) {
      //Change in props
      return {
        data: props.data,
      };
    }
    return null; // No change to state
  }

  handleScroll = (event) => {
    var scrollTop = document.getElementById("phoneLogTableContainer").scrollTop;
    var scrollHeight = document.getElementById("phoneLogTableContainer")
      .scrollHeight;
    let scrollCeil = scrollHeight - Math.ceil(scrollTop);
    let scrollFloor = scrollHeight - Math.floor(scrollTop);
    if (scrollCeil === 400 || scrollFloor === 400) {
      this.props?.onScroll();
      document.removeEventListener("scroll", () => {
        this.handleScroll();
      });
    }
  };

  render() {
    return (
      <div className="results-container">
        <MDBCol>
          <div
            className="landing-table-content search-content"
            id="phoneLogTableContainer"
          >
            <TableComponent
              list={this.props.data}
              config={searchResultsConfig}
              tableMode={"light"}
              isLoading={this.props.isLoading}
              sortCallback={this.sortSetName}
              hyperLinkNavigationCallback={this.handleSetSelection}
              maxHeight={"400"}
              className="landing-page-table"
              handleShowMenu={true}
              onRowClick={this.props.onRowClick}
              onResponseClick={this.props.onResponseClick}
              onCloseClick={this.props.onCloseClick}
            />
          </div>
        </MDBCol>
      </div>
    );
  }
}

export default withUserContext(PhoneLogResults);
