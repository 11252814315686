export const optionalRightsConfig = {
  headings: [
    {
      headingLabel: "GL No.",
      inputType: "select",
      icon: "",
      border: true,
      width: "7%",
      selectOptions: [],
    },
    {
      headingLabel: "Set Unit",
      inputType: "searchselect",
      icon: "",
      border: true,
      width: "12%",
      selectOptions: [],
    },
    {
      headingLabel: "Detail",
      inputType: "searchselect",
      border: true,
      width: "14%",
      selectOptions: [],
    },
    {
      headingLabel: "Description",
      inputType: "text",
      border: true,
      width: "20%",
    },
    {
      headingLabel: "Qty",
      inputType: "number",
      border: true,
      width: "6%",
      selectOptions: [],
    },
    {
      headingLabel: "Unit Cost",
      inputType: "number",
      border: true,
      width: "7%",
    },
    {
      headingLabel: "Sub Total",
      inputType: "label",
      border: true,
      width: "8%",
    },
    {
      headingLabel: "Adj.",
      inputType: "select",
      border: true,
      width: "8%",
    },
    {
      headingLabel: "Total Cost",
      inputType: "label",
      border: true,
      width: "10%",
    },
  ],
  dataNodes: [
    "gl_number",
    "setunit_id",
    "chartofaccount_id",
    "description",
    "quantity",
    "unit_cost",
    "subtotal_value",
    "currency_exchange",
    "total_cost_value",
  ],
  primaryKey: ["po_template_id"],
  nodeFields: [
    {
      column: "info",
    },
  ],
  inlineEdits: [
    {
      dataNode: "gl_number",
      width: "7%",
      placeholder: "GL No.",
    },
    {
      dataNode: "setunit_id",
      width: "11%",
      placeholder: "Set Unit",
    },
    {
      dataNode: "chartofaccount_id",
      width: "13%",
      placeholder: "Detail",
    },
    {
      dataNode: "description",
      width: "19%",
      placeholder: "Description",
    },
    {
      dataNode: "quantity",
      width: "6%",
      validateMethod: "validateNumberFloatOnly",
      placeholder: "Qty",
    },
    {
      dataNode: "unit_cost",
      width: "6%",
      placeholder: "Unit Cost",
      validateMethod: "validateNumberFloatOnly",
    },
    {
      dataNode: "subtotal_value",
      width: "7%",
    },
    {
      dataNode: "currency_exchange",
      width: "8%",
      placeholder: "Adj.",
    },
    {
      dataNode: "total_cost_value",
      width: "10%",
    },
    ,
  ],

  actions: ["file", "pen", "times"],
  isClearFields: true,
};
export const templateList = [
  { label: "Template 1", value: 1 },
  { label: "Template 2", value: 2 },
];
export const vendorList = [
  { label: "Vendor 1", value: 1 },
  { label: "Vendor 2", value: 2 },
];
export const initialPODetails = {
  template_id: null,
  template_name: null,
  vendor_id: null,
};
export const newTemplate = {
  po_template_name: "New Template",
  po_template_header_id: 9999,
};
export const poList = [
  {
    set_unit: "97 Detailing",
    detail: "5120 On-line Editing",
    description: "[PO range]",
    qty: "1",
    unitCost: "$200.00",
    subtotal: "$200.00",
    adj: "1054(EUR)",
    total_cost: "$24726",
    id: 1,
    is_delete: 0,
  },
];
export const optionalRightsList = [
  {
    id: 1,
    right_abbr: "6 YRS HOME VIDEO 06/07",
    fee: "$1500.00",
    term_id: "1 year",
    territory_id: "WW",
    opt_exp_term_id: "1 year",
    option_acquired_date: "MM/DD/YYYY ",
    incl_init: " ",
    CR: " ",
    info: "Test Info 1",
  },
  {
    id: 1,
    right_abbr: "6 YRS HOME VIDEO 06/07",
    fee: "$1500.00",
    term_id: "1 year",
    territory_id: "WW",
    opt_exp_term_id: "1 year",
    option_acquired_date: "MM/DD/YYYY ",
    incl_init: " ",
    CR: " ",
    info: "Test Info 2",
  },
];
export const initialPoTemplateConfig = {
  set_unit: null,
  detail: null,
  description: null,
  qty: null,
  unitCost: null,
  subtotal: null,
  adj: null,
  total_cost: null,
  id: null,
  is_delete: 0,
};
export const NewPOHeader = {
  po_id: null,
  po_number: null,
  po_prefix: null,
  vendor_id: null,
  vendor_location_id: null,
  notes: null,
  show_season_id: null,
  is_void: 0,
  prod_company_id: null,
};

export const NewPOLine = [
  {
    po_line_id: null,
    po_id: null,
    episode_id: null,
    gl_number: null,
    setunit_id: null,
    set_unit: null,
    chartofaccount_id: null,
    acc_detail: null,
    description: null,
    quantity: null,
    unit_cost: null,
    subtotal: null,
    total_cost: null,
    currency_exchange: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    updated_by: null,
    notes: null,
    change_log: [],
  },
];
