export const ShowsConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      inputType: "text",
      icon: "",
      border: true,
      width: "7%",
    },
    {
      headingLabel: "PO Prefix",
      inputType: "text",
      icon: "",
      width: "7%",
      border: true,
    },
    {
      headingLabel: "PO range",
      inputType: "text",
      border: true,
      width: "15%",
    },
    {
      headingLabel: "Show Name",
      inputType: "text",
      icon: "",
      width: "12%",
      border: true,
    },
    {
      headingLabel: "Season",
      inputType: "text",
      width: "8%",
      border: true,
    },
    {
      headingLabel: "Production Companies",
      inputType: "text",
      width: "20%",
    },
    {
      headingLabel: "Notes",
      inputType: "text",
      width: "16%",
      border: true,
    },
    {
      headingLabel: "Created On",
      icon: "",
      inputType: "text",
      width: "10%",
    },
  ],

  dataNodes: [
    "is_active",
    "po_prefix",
    "po_range",
    "show_name",
    "season_name",
    "prod_companies_label",
    "notes",
    "created_at",
  ],
  primaryKey: "show_id",
  actions: ["pen"],
  hyperlinks: ["show_name"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
};

export const showList = [
  {
    inactive: "yes",
    po_prefix: "[PO prefix]",
    po_range: "[PO range]",
    show_name: "[Show name]",
    season: "[season]",
    production_companies: "[production companies]",
    notes: ["notes"],
  },
  {
    inactive: "yes",
    po_prefix: "[PO prefix]",
    po_range: "[PO range]",
    show_name: "[Show name]",
    season: "[season]",
    production_companies: "[production companies]",
    notes: ["notes"],
  },
];

export const TableFilter = {
  po_prefix: null,
  show_name: null,
  season_id: null,
};
