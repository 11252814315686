export const vendorConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "8%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Name",
      icon: "",
      width: "25%",
      inputType: "text",
    },
    {
      headingLabel: "Tax ID",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "AP Number",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Phone",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Locations",
      icon: "",
      width: "20%",
      inputType: "text",
    },
    {
      headingLabel: "Created On",
      icon: "",
      inputType: "text",
      width: "10%",
    },
  ],
  dataNodes: [
    "is_active",
    "vendor_name",
    "tax_id",
    "ap_number",
    "phone",
    "vendor_locations_label",
    "created_at",
  ],
  primaryKey: "vendor_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
};

export const vendorList = [
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 1,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 2,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 3,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 4,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 5,
  },
];
