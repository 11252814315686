export const filterObject = {
  full_name: "",
  company_name: "",
  department: "",
  title: "",
  email: "",
  phonedetails: "",
  assistants: "",
  is_active: 1,
};
