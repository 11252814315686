export const currenciesConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "10%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Display Name",
      icon: "",
      inputType: "text",
      width: "25%",
    },
    {
      headingLabel: "Season Start",
      icon: "",
      inputType: "text",
      width: "25%",
    },
    {
      headingLabel: "Season End",
      icon: "",
      inputType: "text",
      width: "25%",
    },
    {
      headingLabel: "Created On",
      icon: "",
      width: "10%",
    },
  ],
  dataNodes: [
    "is_active",
    "season_name",
    "start_year",
    "end_year",
    "created_at",
  ],
  primaryKey: "season_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "9%",
    },
    {
      //dataNode: "currency_code",
      width: "24%",
    },
    {
      dataNode: "start_year",
      width: "25%",
      validateMethod: "validateNumbersOnly",
      isMandatory: true,
    },
    {
      dataNode: "end_year",
      width: "25%",
      isMandatory: true,
      validateMethod: "validateNumbersOnly",
    },
  ],
};
