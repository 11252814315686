import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "./BasicTextField/BasicTextField";
import BasicCheckbox from "./BasicCheckbox/BasicCheckbox";
import SelectField from "./SelectField/SelectField";
import SearchSelectField from "./SearchSelectField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { selectOptions, compareOptions } from "../ScripterLandingPage/config";
import "./TableFilters.scss";
import DateField from "../SharedComponents/DateField/DateField";

class TableFilters extends React.Component {
  constructor(props) {
    super(props);
  }

  handleFilters = (e) => {
    this.props.filterChangeHandler(e.target.value);
    this.props.addFilterChangeHandler(e.currentTarget.outerText);
  };

  pickFilters(inputType, name) {
    switch (inputType) {
      case "text":
      case "textarea":
      case "select":
        return (
          <BasicTextField
            id={name}
            value={this.props.inputValue}
            placeholder={this.props?.placeholder || ""}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.props.addFilterChangeHandler(event.target.value);
              }
            }}
            onChange={(e) => this.props.filterChangeHandler(e.target.value)}
          />
        );
        break;
      case "checkbox":
        return (
          <BasicCheckbox
            id={name}
            onChange={(e) =>
              this.props.addFilterChangeHandler(e.target.checked ? "1" : "0")
            }
          />
        );
        break;
      case "dropdown":
        return (
          <SelectField
            id={name}
            className="FilterSelectField"
            value={this.props.inputValue}
            options={this.props?.options}
            placeHolderText={this.props?.placeholder || "-Select-"}
            isLandingPage={true}
            // onKeyUp={(event) => {
            //   if (event.keyCode === 13) {
            //     this.props.addFilterChangeHandler(event.target.value);
            //   }

            // }}
            onChange={this.handleFilters}
          />
        );
        break;
      case "searchselect":
        return (
          <SearchSelectField
            id={"search-select"}
            width={"100px"}
            className="FilterSearchSelect"
            value={this.props.inputValue}
            options={this.props?.options}
            onChange={this.handleFilters}
          />
        );
        break;
      case "autocomplete":
        return (
          <Autocomplete
            id={"searchSelect"}
            options={this.props?.options || []}
            disablePortal
            getOptionLabel={(option) => option.label}
            value={this.props.inputValue ? this.props.inputValue : null}
            onChange={(e, v) => {
              this.props.filterChangeHandler(v);
              this.props.addFilterChangeHandler(v?.label);
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="-Search Select-"
                onChange={(ev) => {
                  // dont fire API if the user delete or not entered anything
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    this.props.autoCompleteCallBack(
                      this.props?.configHeading,
                      ev.target.value
                    );
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
          />
        );
      case "daterange":
        return (
          <div className="d-flex DateFilter">
            <DateField
              label=""
              value={this.props.inputValue}
              onChange={(e) => this.props.filterChangeHandler(e.target.value)}
            />
            <SelectField
              id={name}
              className="FilterSelectField"
              value={this.props.inputValue}
              options={this.props?.options}
              placeHolderText={"-Select-"}
              isLandingPage={true}
              onChange={(e) =>
                this.props.addFilterChangeHandler(e.currentTarget.outerText)
              }
            />
          </div>
        );
        break;
      // case 'rangepicker':
      //   return (
      //     <div>
      //       <BasicTextField id={name}
      //         value={this.props.inputValue}
      //         // onKeyUp={(event) => {
      //         //   if (event.keyCode === 13) {
      //         //     this.props.addFilterChangeHandler(event.target.value);
      //         //   }

      //         // }}
      //         onChange={(e) => this.props.filterChangeHandler(e.target.value)}
      //       />
      //       <BasicTextField id={"PageCnt"}
      //        // value={this.props.inputValue}
      //         // onKeyUp={(event) => {
      //         //   if (event.keyCode === 13) {
      //         //     this.props.addFilterChangeHandler(event.target.value);
      //         //   }

      //         // }}
      //         // onChange={(e) => this.props.filterChangeHandler(e.target.value)}
      //         onChange={(e) => this.props.addFilterChangeHandler(event.target.value)}
      //       />
      //     </div>);
      //   break;
      default:
        return <></>;
    }
  }

  render() {
    return <>{this.pickFilters(this.props.inputType, this.props.name)}</>;
  }
}

export default TableFilters;
