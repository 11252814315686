import React from "react";
import BasicTextField from "../BasicTextField/BasicTextField";
import { MDBIcon } from "mdbreact";
import { InputAdornment } from "@material-ui/core";
export default class IconTextField extends React.Component {
  render() {
    return (
      <div
        className={`IconTextFieldContainer ${this.props.className}`}
        style={{ width: this.props?.width ? this.props?.width : "" }}
      >
        <BasicTextField
          id={this.props?.id}
          value={this.props?.value || ""}
          label={this.props.label}
          limit={this.props.limit}
          limitWarning={this.props.limitWarning}
          onChange={this.props?.onChange}
          onKeyUp={this.props?.onKeyUp}
          placeholder={this.props?.placeholder || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MDBIcon
                  icon={this.props?.icon}
                  size="sm"
                  onClick={this.props.iconOnClick}
                />
              </InputAdornment>
            ),
          }}
          width={this.props?.width}
          disabled={this.props?.disabled || false}
        />
      </div>
    );
  }
}
