import React from "react";
import "./BasicLabel.scss";

export default class BasicLabel extends React.Component {
  render() {
    let isMandatory = this.props?.isMandatory || false;
    return (
      <div className={this.props?.text ? "BasicLabelContainer" : "hidden"}>
        <label className={this.props?.type || "text"}>
          {this.props?.text}
          <span>{isMandatory ? "*" : ""}</span>
        </label>
      </div>
    );
  }
}
