import React from "react";
import BasicTextField from "../BasicTextField/BasicTextField";
import Button from "@material-ui/core/Button";
import BasicLabel from "../BasicLabel/BasicLabel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import "./InlineButtonField.scss";

export default class InlineButtonField extends React.Component {
  render() {
    return (
      <div className="InlineButtonFieldContainer">
        {this.props?.label && (
          <BasicLabel
            text={this.props?.label || ""}
            type={"text"}
            isMandatory={this.props?.isMandatory || false}
          />
        )}
        <div className="InlineButtonField">
          <BasicTextField
            id={this.props?.id}
            className="TextField1"
            value={this.props?.value || ""}
            onChange={this.props?.onChange}
            disabled={this.props?.disabled || false}
            placeholder={this.props?.placeholder || ""}
            fieldValid={this.props?.fieldValid}
            inValidInput={this.props?.inValidInput}
            limit={this.props?.limit}
            limitWarning={this.props?.limitWarning || ""}
          />
          <Button
            onClick={this.props?.buttonOnClick}
            variant="text"
            size="small"
            color="primary"
            disabled={
              this.props?.disabled ||
              this.props.fieldValid ||
              this.props?.value?.trim().length === 0 ||
              false
            }
          >
            {this.props.placeholder === "Edit Value" ? (
              <EditIcon fontSize="small" />
            ) : (
              <AddCircleIcon fontSize="small" />
            )}
          </Button>
        </div>
      </div>
    );
  }
}
