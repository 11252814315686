import React from "react";
import TextField from "@material-ui/core/TextField";
import BasicLabel from "../BasicLabel/BasicLabel";
import "./BasicTextArea.scss";

export default class BasicTextArea extends React.Component {
  render() {
    let error =
      this.props?.value?.length > this.props.limit ||
      (this.props.showMandatory && !this.props?.value) ||
      this.props.fieldValid ||
      false;
    let mandatoryWarning = "Mandatory Field";
    return (
      <div
        className="BasicTextAreaContainer"
        style={{ width: this.props?.width ? this.props?.width : "" }}
      >
        {this.props?.label && (
          <BasicLabel
            text={this.props?.label || ""}
            type={"text"}
            isMandatory={this.props?.isMandatory || false}
          />
        )}
        {this.props.showAsLabel ? (
          <div className="showAsLabel">{this.props.value || ""} </div>
        ) : (
          <TextField
            id={this.props?.id}
            variant="outlined"
            multiline
            rows={this.props.rows || 3}
            autoComplete="off"
            value={this.props?.value || ""}
            cols="50"
            onChange={this.props?.onChange}
            disabled={this.props?.disabled || false}
            placeholder={this.props?.placeholder || ""}
            className={this.props?.dividers ? "textAreaDividers" : ""}
          />
        )}
        {error && (
          <div className="errorText">
            {(this.props.showMandatory && !this.props?.value
              ? mandatoryWarning
              : this.props?.fieldValid
              ? this.props?.inValidInput
              : this.props.limitWarning) || ""}
          </div>
        )}
      </div>
    );
  }
}
