import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import * as Constants from "../../constants/constants";
import PostWatchService from "../../services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import sizeLimits from "../../Common/SizeLimits.json";
import { withUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import {
  initialUserDetails,
  initialRoleDetails,
  statusOption,
  roleOption,
  tenantOption,
  selectOptions,
  NewShow,
} from "./config";
import {
  validateEmail,
  validateCharctersSpaceOnly,
  validatePlusCharacter,
  validateNumbersOnly,
  validatePhoneNumber,
  validateCustomPhoneNumber,
} from "../../Common/Helper";
import "./NewUser.scss";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.newShowPlaceHolder = { ...NewShow };
    this.state = {
      userPostJson: JSON.parse(JSON.stringify(initialUserDetails)),
      postInitiated: false,
      isPosting: false,
      tenantOption: [],
      canTakeMessageForUsers: [],
      roleOption: [],
      editMode: true,
      isFetchingTenants: false,
      isFetchingRoles: false,
      phone: "",
      phoneValid: true,
      uniqShows: null,
    };
  }
  componentDidMount() {
    if (this.props.mode === "edit") {
      // Select fiels is not accption 0 as option value, so converting to 2
      let userPostJson = JSON.parse(JSON.stringify(this.props?.userDetails));
      if (userPostJson.is_active === 0) {
        userPostJson.is_active = 2;
      }
      this.setState(
        (prevState) => ({
          userPostJson,
        }),
        () => {
          this.setState({ editMode: false });
          this.addShowPlaceholder();
        }
      );
    }

    // this.getTenant();
    // this.getRoles();
    // this.getDepartments();
    let uniqShows = _.uniqBy(this.props.shows, "show_id");
    uniqShows = _.sortBy(uniqShows, ["show_name"]);
    this.setState({ uniqShows });
  }

  addShowPlaceholder = () => {
    let userPostJson = { ...this.state.userPostJson };
    if (
      userPostJson?.user_shows &&
      !_.find(userPostJson?.user_shows, this.newShowPlaceHolder)
    ) {
      userPostJson.user_shows.push({ ...this.newShowPlaceHolder });
    }
    this.setState({ userPostJson });
  };
  getDistinctArray = (arr) => {
    if (arr?.length) {
      const distincitUserRoles = arr.filter(
        (ele, index, arr) =>
          index === arr.findIndex((item) => item.show_id === ele.show_id)
      );
      return distincitUserRoles;
    }
  };
  removeEmptyFeilds = (source) => {
    return source?.filter((obj) => obj["show_id"] !== null);
  };
  validateBeforeSave = (userDetails) => {
    let canSubmit =
      userDetails?.full_name &&
      userDetails?.display_name &&
      userDetails?.title &&
      userDetails?.phone &&
      validateEmail(userDetails?.login_email) &&
      userDetails?.login_email &&
      userDetails?.login_email.length <= sizeLimits.emailCharacterLimit &&
      validateEmail(userDetails?.notification_email) &&
      userDetails?.notification_email &&
      userDetails?.notification_email.length <=
        sizeLimits.emailCharacterLimit &&
      userDetails?.tenant_id &&
      userDetails?.usertype_id &&
      (userDetails?.user_shows.length > 0
        ? this.getDistinctArray(userDetails?.user_shows).length ===
          userDetails?.user_shows.length
        : true);
    return canSubmit;
  };
  postUser = () => {
    this.setState({ postInitiated: true });

    // Remove empty element(default row)
    let shows = this.removeEmptyFeilds(this.state?.userPostJson?.user_shows);
    if (this.validateBeforeSave(this.state?.userPostJson)) {
      this.setState({ isPosting: true });
      this.setState(
        (prevState) => ({
          userPostJson: {
            ...prevState.userPostJson,
            user_shows: shows,
          },
        }),
        () => {
          let userPostJson = { ...this.state?.userPostJson };
          // Select fiels is not accption 0 as option value, so converting to 2
          if (userPostJson.is_active === 2) {
            userPostJson.is_active = 0;
          }
          PostWatchService.postDataParams(
            Constants.postWatchServiceBaseUrl +
              "/postuserdetails?userId=" +
              this.props?.userContext?.user_profile?.user_id,
            userPostJson,
            this.props?.userContext?.active_tenant?.tenant_id
          ).then(
            (response) => {
              if (response.data.error) {
                this.props.handleMessageModalStatus("fail", true);
              } else {
                this.props.handleMessageModalStatus("success", true);
                this.props.callback(this.props?.setId);
              }
              this.setState({ isPosting: true, postInitiated: false });
            },
            (err) => {
              this.props.handleMessageModalStatus("fail", true);
              this.setState({ isPosting: true, postInitiated: false });
              // this.props?.handleSubmit("fail");
              // this.props.fieldChanged(true, "fail");
              //this.setState({ isPosting: false });
            }
          );
        }
      );
    }
  };

  handleAdd = (item) => {
    if (item?.show_id || !item) {
      let shows = this.state?.userPostJson?.user_shows;
      shows.push({
        show_id: null,
        show_name: "",
      });
      this.handleOnChange("user_shows", shows);
    }
  };

  handleRemove = (dataitem) => {
    let shows = this.state?.userPostJson?.user_shows;
    let index = _.findIndex(shows, {
      show_id: dataitem.show_id,
    });
    shows.splice(index, 1);
    this.handleOnChange("user_shows", shows);
  };

  handleOnChange = (field, value) => {
    // is_user_provisioner is not used in UI but is used in permissions of home.js, so manually updating
    if (field === "usertype_id") {
      let userPostJson = { ...this.state.userPostJson };
      if (value === 1) {
        userPostJson.is_user_provisioner = 1;
        userPostJson.user_shows = [];
        userPostJson.user_shows.push({
          show_id: null,
          show_name: "",
        });
      } else {
        userPostJson.is_user_provisioner = 0;
      }
      // If Admin, clear shows since all shows will be available for Admin by default
      if (value === 2) {
        userPostJson.user_shows = [];
        userPostJson.user_shows.push({
          show_id: null,
          show_name: "",
        });
      }
      this.setState({ userPostJson });
    }
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  handleAddShowChange = (field, item, index) => {
    let userPostJson = { ...this.state.userPostJson };
    userPostJson.user_shows[index] = {
      show_id: item.value,
      show_name: item.text,
    };
    this.props.handleMessageModalStatus("unsaved", true);
    this.setState({ userPostJson });
  };

  render() {
    let isLoading =
      this.props?.mode == "edit"
        ? this.state?.editMode ||
          this.state.isFetchingRoles ||
          this.state.isFetchingTenants
        : this.state.isFetchingRoles || this.state.isFetchingTenants;
    return (
      <div>
        {isLoading ? (
          <CircularProgress className="ContentLoader" />
        ) : (
          <MDBContainer className="newUserContainer">
            <MDBRow>
              <MDBCol sm="5">
                <BasicTextField
                  label="Full Name"
                  isMandatory={true}
                  value={this.state.userPostJson?.full_name || ""}
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    validateCharctersSpaceOnly(e.target.value) &&
                    this.handleOnChange(
                      "full_name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol sm="5">
                <BasicTextField
                  label="Display Name"
                  isMandatory={true}
                  value={this.state.userPostJson?.display_name || ""}
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    validateCharctersSpaceOnly(e.target.value) &&
                    this.handleOnChange(
                      "display_name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol sm="2" id="StatusSelectField">
                <SelectField
                  id={"key15"}
                  value={this.state.userPostJson?.is_active}
                  label={"Status"}
                  options={statusOption}
                  onChange={(e) =>
                    this.handleOnChange("is_active", e.target.value)
                  }
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="5">
                <BasicTextField
                  label="Title"
                  isMandatory={true}
                  value={this.state.userPostJson?.title || ""}
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    validateCharctersSpaceOnly(e.target.value) &&
                    this.handleOnChange(
                      "title",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol sm="3">
                <BasicTextField
                  label="Phone"
                  isMandatory={true}
                  showMandatory={this.state.postInitiated}
                  value={this.state.userPostJson?.phone || ""}
                  fieldValid={this.state.phoneValid === false ? true : false}
                  inValidInput={"Invalide Phone Number"}
                  limit={
                    validatePlusCharacter(this.state.phone.trim().charAt(0))
                      ? sizeLimits.phoneLimitIntl
                      : sizeLimits.phoneLimitUS
                  }
                  limitWarning={"Please Enter Valid Phone Number"}
                  onChange={(e) =>
                    validateCustomPhoneNumber(e.target.value) &&
                    this.handleOnChange(
                      "phone",
                      e.target.value ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="5">
                <BasicTextField
                  label="Login Email"
                  isMandatory={true}
                  limit={sizeLimits.emailCharacterLimit}
                  limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                  value={this.state.userPostJson?.login_email || ""}
                  onChange={(e) =>
                    this.handleOnChange(
                      "login_email",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                  showMandatory={this.state.postInitiated}
                  inValidInput={"Invalid Email"}
                  fieldValid={
                    !validateEmail(this.state.userPostJson?.login_email) &&
                    this.state.userPostJson?.login_email
                  }
                />
              </MDBCol>

              <MDBCol sm="6">
                <BasicTextField
                  label="Notification Email"
                  isMandatory={true}
                  limit={sizeLimits.emailCharacterLimit}
                  limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                  value={this.state.userPostJson?.notification_email || ""}
                  onChange={(e) =>
                    this.handleOnChange(
                      "notification_email",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                  showMandatory={this.state.postInitiated}
                  inValidInput={"Invalid Email"}
                  fieldValid={
                    !validateEmail(
                      this.state.userPostJson?.notification_email
                    ) && this.state.userPostJson?.notification_email
                  }
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <BasicLabel type={"subHeading"} text={"Credentials"} />
            </MDBRow>
            <MDBRow>
              <MDBCol
                md={3}
                id="rolesDropDown"
                className={`${
                  this.state.userDetails?.divisions?.length === 0
                    ? "searchSelectHeight searchSelect"
                    : "searchSelect"
                }`}
              >
                <BasicLabel text={"Tenant"} isMandatory={true} />
                <SelectField
                  id={"tenant-name"}
                  value={this.state?.userPostJson?.tenant_id || ""}
                  options={
                    this.props.tenants?.map((item) => ({
                      label: item?.tenant_name,
                      value: item?.tenant_id,
                    })) || []
                  }
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    this.handleOnChange("tenant_id", e.target.value)
                  }
                />
              </MDBCol>

              <MDBCol
                md={5}
                id="rolesDropDown"
                className={`${
                  this.state.userDetails?.divisions?.length === 0
                    ? "searchSelectHeight searchSelect"
                    : "searchSelect"
                }`}
              >
                <BasicLabel text={"User Level"} isMandatory={true} />
                <SelectField
                  id={"tenant-name"}
                  value={this.state?.userPostJson?.usertype_id || ""}
                  options={
                    this.props.userRoles?.map((item) => ({
                      label: item?.user_role,
                      value: item?.userrole_id,
                    })) || []
                  }
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    this.handleOnChange("usertype_id", e.target.value)
                  }
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-2">
              <BasicLabel type={"subHeading"} text={"Shows"} />
            </MDBRow>

            {/* End of Old code */}

            <MDBRow>
              <MDBCol md={4}>
                <BasicLabel text="Show" />
              </MDBCol>
            </MDBRow>
            <MDBRow className="user-shows-container">
              {this.state?.userPostJson?.user_shows?.map((item, index) => (
                <>
                  <MDBCol md={8} className="mb-1">
                    <SearchSelectField
                      id={"set-name"}
                      width={"90%"}
                      placeholder={"-Search or Select-"}
                      value={{
                        text: this.state?.userPostJson?.user_shows[index]
                          .show_name,
                        value: this.state?.userPostJson?.user_shows[index]
                          .show_id,
                      }}
                      options={
                        this.state?.uniqShows?.map((item) => ({
                          text: item?.show_name,
                          value: item?.show_id,
                        })) || []
                      }
                      onChange={(e, value) =>
                        this.handleAddShowChange("show_id", value, index)
                      }
                      disabled={
                        this.state?.userPostJson?.is_user_provisioner ||
                        this.state?.userPostJson?.usertype_id === 2
                      }
                    />
                    {/* <SelectField
                      id={"tenant-role"}
                      value={item?.show_id || ""}
                      options={
                        this.props.shows?.map((item) => ({
                          label: item?.show_name,
                          value: item?.show_id,
                        })) || []
                      }
                      onChange={(e) =>
                        this.handleAddShowChange(
                          "show_id",
                          e.target.value,
                          index
                        )
                      }
                    /> */}
                  </MDBCol>

                  <MDBCol md={1}>
                    <RemoveCircleIcon
                      onClick={() => this.handleRemove(item)}
                      className="m-1 remove-item"
                    />
                    {item?.show_id && (
                      <AddCircleIcon
                        className="m-1 add-item"
                        onClick={() =>
                          this.handleAdd(
                            this.state?.userPostJson?.user_shows[index]
                          )
                        }
                      />
                    )}
                  </MDBCol>
                </>
              ))}
            </MDBRow>
            {this.state?.userPostJson?.user_shows?.length > 0 &&
            this.getDistinctArray(this.state?.userPostJson?.user_shows)
              ?.length !== this.state?.userPostJson?.user_shows?.length ? (
              <span className="errorText">Duplicate Show Added</span>
            ) : null}

            <MDBRow className="btn-row">
              <MDBCol md={8}></MDBCol>
              <MDBCol md={2} className="d-flex justify-content-end p-0">
                <BasicButton
                  variant="contained"
                  type="inline"
                  text={
                    this.state.isPosting ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : this.props?.mode == "new" ? (
                      "Create"
                    ) : (
                      "Save"
                    )
                  }
                  disabled={
                    this.state.userPostJson?.full_name?.trim().length === 0
                  }
                  onClick={this.postUser.bind(this)}
                />
              </MDBCol>
              <MDBCol md={2} className="d-flex justify-content-end p-0">
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Close"}
                  onClick={this.props.onClose}
                  disabled={false}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default withUserContext(NewUser);
