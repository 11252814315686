import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";

import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";

import { validateCharctersSpaceOnly } from "../../Common/Helper";
import { filterObject } from "./Config";

class FindContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      filterObject: { ...filterObject },
    };
  }

  componentDidMount = (prevProps, prevState) => {};
  handleFilterChange = (field, value) => {
    // this.setState(prevState => ({
    //     filterCriteria: {
    //         ...prevState.filterCriteria,
    //         [field]: value
    //     }
    // }))
  };
  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      filterObject: {
        ...prevState.filterObject,
        [field]: value,
      },
    }));
  };

  onClear = () => {
    this.setState({ filterObject: { ...filterObject } });
  };

  render() {
    return (
      <div className="contact-list-container">
        {this.state.isLoading ? (
          <div className="New-Set-Loader">
            {" "}
            <CircularProgress color="inherit" size={24} />{" "}
          </div>
        ) : (
          <MDBCol>
            <MDBRow className="ModalBody p-2">
              {/* <MDBCol md={6} className="Content">
                                <Tabs>
                                    <Tab label="Details"/>
                                    <Tab label="Find"/>
                                </Tabs>
                            </MDBCol> */}
              <MDBCol md={12} className="Content">
                <MDBRow>
                  <MDBCol md={9}>
                    <BasicTextField
                      label="Full Name"
                      value={this.state.filterObject?.full_name || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "full_name",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol
                    md={3}
                    className={"d-flex justify-content-end align-items-end"}
                  >
                    <BasicLabel text=" " />
                    <BasicCheckbox
                      id={"inactive"}
                      label="Inactive"
                      value={!this.state.filterObject?.is_active}
                      checked={
                        this.state.filterObject?.is_active === 0 ? true : false
                      }
                      onChange={(e) =>
                        this.handleOnChange(
                          "is_active",
                          e.target.checked ? 0 : 1
                        )
                      }
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={6}>
                    <BasicTextField
                      label="Company"
                      value={this.state.filterObject?.company_name || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "company_name",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />

                    <BasicTextField
                      label="Title"
                      value={this.state.filterObject?.title || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "title",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />

                    <BasicTextField
                      label="Phone"
                      value={this.state.filterObject?.phonedetails || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "phonedetails",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </MDBCol>

                  <MDBCol md={6}>
                    <BasicTextField
                      label="Department"
                      value={this.state.filterObject?.department || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "department",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />

                    <BasicTextField
                      label="Email"
                      value={this.state.filterObject?.email || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "email",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                    <BasicTextField
                      label="Assistants"
                      value={this.state.filterObject?.assistants || ""}
                      onChange={(e) =>
                        validateCharctersSpaceOnly(e.target.value) &&
                        this.handleOnChange(
                          "assistants",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={8}></MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      variant="contained"
                      type="inline"
                      text={"Go"}
                      onClick={() => this.props.onGo(this.state.filterObject)}
                    />
                  </MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Clear"}
                      onClick={this.onClear}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={10}></MDBCol>
                  {/* <MDBCol md={2}>
                                        <BasicButton
                                            variant="contained"
                                            type="inline"
                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.mode == 'new' ?  "Create" : "Save"}
                                        />
                                    </MDBCol> */}
                  <MDBCol md={2} className="d-flex justify-content-end mt-2">
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Close"}
                      onClick={this.props.onClose}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        )}
      </div>
    );
  }
}
export default FindContact;
