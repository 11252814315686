import React from "react";
import TableComponent from "../../src/components/SharedComponents/Table";
import Modal from "../../src/components/SharedComponents/Modal/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tab from "@material-ui/core/Tab";
import _ from "lodash";
import {
  handlePaginationList,
  applyFilterTable,
  updateTableSortConfigObject,
  tableSortList,
} from "../Common/TableHelper";
import {
  configObjectAllUsers,
  configObjectAllUsersModal,
  chipFilterObject,
  ActiveOrInActive,
  postFilters,
  NewUser,
} from "./config";
import TablePagination from "@material-ui/core/TablePagination";
import "../UserManagement/usermanagement.scss";
import BasicButton from "../../src/components/SharedComponents/BasicButton/BasicButton";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import IconTextField from "../../src/components/SharedComponents/IconTextField/IconTextField";
import SelectField from "../../src/components/SharedComponents/SelectField/SelectField";
import * as Constants from "../../src/constants/constants";
import PostWatchService from "../../src/services/service";
import { withUserContext } from "../../src/contexts/UserContext";
import Chip from "@material-ui/core/Chip";

export default withUserContext(
  class UserManagement extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        allData: [],
        listBackUp: [],
        tableList: [],
        tableListSorted: [],
        a_z: false,
        page: 0,
        rowsPerPage: 10,
        totalRows: null,
        newUserModal: false,
        isFetchingUserDetails: false,
        mode: null,
        userId: null,
        userDetails: null,
        totalCount: 0,
        searchTerm: null,
        is_enable: false,
        statusOptions: ActiveOrInActive,
        config: JSON.parse(JSON.stringify({ ...configObjectAllUsers })),
        isFetchingUserRoles: false,
        isFetchingTenants: false,
        userRoles: null,
        userRolesFilterOptions: null,
        shows: null,
        showsFilterOptions: null,
        isFetchingShows: false,
        tenants: null,
        sortBy: "Value",
        isSortByAsc: true,
        sortCount: 0,
        filters: { ...postFilters },
        chipFilterObject: { ...chipFilterObject },
      };
    }

    closeNewUserModal = () => {
      this.setState({ newUserModal: false, userId: null, mode: null });
    };

    componentDidMount = () => {
      this.getAllUsers();
      this.getShows();
      this.getUserRoles();
      this.getTenants();
    };
    getShows = () => {
      let filters = {
        po_prefix: null,
        show_name: null,
        page_count: null,
        page_no: null,
      };

      this.setState({ isFetchingShows: true });
      PostWatchService.getDataWithFilters(
        Constants.postWatchServiceBaseUrl + "/getshows",
        filters,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            shows: response.data.records,
            isFetchingShows: false,
          });
        },
        (err) => {}
      );
    };
    getUserRoles = () => {
      this.setState({ isFetchingUserRoles: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/getuserroles",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          let filterObj = response.data?.map((item) => {
            return { value: item.userrole_id, label: item.user_role };
          });
          this.setState({
            userRoles: response.data,
            userRolesFilterOptions: filterObj,
            isFetchingUserRoles: false,
          });
        },
        (err) => {}
      );
    };
    getTenants = () => {
      this.setState({ isFetchingTenants: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl + "/gettenants",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            tenants: response.data,
            isFetchingTenants: false,
          });
        },
        (err) => {}
      );
    };

    getAllUsers = () => {
      this.setState({ isFetchingUserDetails: true, searchTerm: null });
      let filters = { ...this.state.filters };
      filters.page_count = this.state.rowsPerPage;
      this.setState({
        isFetchingPODetails: true,
        filters,
      });
      PostWatchService.getDataWithFilters(
        Constants.postWatchServiceBaseUrl + "/getuserslist",
        filters,
        this.props?.userContext?.user_profile?.tenant_id
      ).then(
        (response) => {
          this.setState({
            tableListSorted: response.data.records,
            isFetchingUserDetails: false,
            totalRows: response.data?.total_records_count,
          });
        },
        (err) => {
          console.log("Error in fetching set Details:", err);
          this.setState({
            isLoadingSetDetails: false,
            isFetchingUserDetails: false,
          });
        }
      );
    };

    handleUserSelection = (item, node) => {
      if (node === "full_name") {
        this.setState({
          mode: "edit",
          userId: item?.user_id,
          userDetails: { ...item },
          newUserModal: true,
        });
      }
    };

    handleUserDetailsUpdate = () => {
      this.setState({ page: 1, rowsPerPage: 10 });
      this.getAllUsers();
    };

    clearFilterHandler = () => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      Object.keys(chipFilterObject).map((key, index) => {
        return (chipFilterObject[key].value = null);
      });

      this.setState(
        {
          chipFilterObject,
          filters: { ...postFilters },
          selectedFilterVendor: null,
        },
        () => {
          this.getAllUsers();
        }
      );
    };

    handleChangeRowsPerPage = (event) => {
      this.setState(
        {
          rowsPerPage: event.target.value,
          page: 1,
        },
        () => {
          this.getAllUsers();
        }
      );
    };
    handleChangePage = (event, newPage) => {
      let filters = { ...this.state.filters };
      filters.page_no = newPage + 1;
      this.setState({ filters }, () => {
        this.getAllUsers();
      });
    };
    handleTableColumnClick = (column) => {
      if (column === this.state.sortBy) {
        this.setState({ sortCount: this.state.sortCount + 1 }, () => {
          this.handleTableSort(column);
        });
      } else
        this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        });
    };
    handleTableSort = (colName) => {
      this.setState(
        {
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
          config: updateTableSortConfigObject(
            configObjectAllUsers,
            this.state.isSortByAsc,
            this.state.sortBy,
            colName
          ),
        },
        () => {
          this.setState({
            sortBy: colName,
          });
          let filters = { ...this.state.filters };

          let headerNode = _.find(this.state.config.headings, {
            headingLabel: colName,
          });
          if (headerNode) {
            filters.sort_by = headerNode.dataNode;
          }

          filters.isSortByAsc = this.state.isSortByAsc;
          this.setState({ filters }, () => {
            this.getAllUsers();
          });
        }
      );
    };

    handleFilterChange = (field, value, callFlag = true) => {
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [field]: value,
            page_no: 1,
          },
        }),
        () => {
          if (callFlag) {
            this.getAllUsers();
            let chipFilterObject = { ...this.state.chipFilterObject };
            chipFilterObject[field].value = value;
            this.setState({
              chipFilterObject,
            });
          }
        }
      );
    };
    onKeyUpChange = (filter, value) => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      chipFilterObject[filter].value = value;
      this.setState({
        chipFilterObject,
      });
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [filter]: value,
            page_no: 1,
          },
        }),
        () => {
          this.getAllUsers();
        }
      );
    };
    handleChipDelete = (pro, item) => {
      let chipFilterObject = { ...this.state.chipFilterObject };
      chipFilterObject[pro].value = null;
      this.handleFilterChange(pro, null);
      this.setState({ chipFilterObject });
    };
    render() {
      let userPermissions = this.props?.userContext?.active_tenant?.permissions;
      let isLoading =
        this.state.isFetchingUserDetails ||
        this.state.isFetchingShows ||
        this.state.isFetchingUserRoles ||
        this.state.isFetchingTenants;
      return (
        <>
          <div>
            {userPermissions?.isManageUser ? (
              <div className="User-Management ">
                <MDBRow className="heading-row">
                  <MDBCol md={3}>
                    <h2>User Management</h2>
                  </MDBCol>
                  <MDBCol md={1} className="new-user-btn">
                    <BasicButton
                      variant="outlined"
                      icon=""
                      text="New User"
                      onClick={() =>
                        this.setState({
                          mode: "new",
                          newUserModal: true,
                          userDetails: { ...NewUser },
                        })
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBContainer fluid className="filters"></MDBContainer>
                <div>
                  {this.state.newUserModal ? (
                    <Modal
                      open={this.state.newUserModal || false}
                      modalName={"NewUser"}
                      onClose={this.closeNewUserModal}
                      comfirmModalTitle={
                        this.state.mode === "edit" ? "Edit User" : "New User"
                      }
                      mode={this.state.mode}
                      shows={this.state.shows}
                      tenants={this.state.tenants}
                      userRoles={this.state.userRoles}
                      userDetails={this.state.userDetails}
                      callback={this.handleUserDetailsUpdate.bind(this)}
                    />
                  ) : null}
                </div>
                <MDBRow>
                  <MDBCol md={11} className="mb-2">
                    <ul className="chips-list">
                      {Object.keys(this.state.chipFilterObject).map(
                        (key, index) => {
                          if (this.state.chipFilterObject[key].value) {
                            let obj = this.state.chipFilterObject[key];

                            return (
                              <li key={key}>
                                {
                                  <Chip
                                    onDelete={() =>
                                      this.handleChipDelete(key, {
                                        ...obj,
                                      })
                                    }
                                    label={
                                      this.state.chipFilterObject[key].label +
                                      ": " +
                                      this.state.chipFilterObject[key].value
                                    }
                                  />
                                }
                              </li>
                            );
                          }
                        }
                      )}
                    </ul>
                  </MDBCol>
                  <MDBCol md={1} className="mb-2 clear-filter-btn ">
                    {this.state.chipFilterObject && (
                      <BasicButton
                        text="Clear filters"
                        variant="outlined"
                        type="inline"
                        onClick={this.clearFilterHandler}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBContainer fluid className="urm-filters">
                  <MDBRow>
                    <MDBCol md={12} className="d-flex">
                      <IconTextField
                        placeholder={"Full Name"}
                        width={"100%"}
                        value={this.state?.filters?.full_name}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "full_name",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.onKeyUpChange(
                              "full_name",
                              e.target.value === null || e.target.value === ""
                                ? null
                                : e.target.value
                            );
                          }
                        }}
                        icon={"FaSearch"}
                      />
                      <IconTextField
                        placeholder={"Display Name"}
                        width={"100%"}
                        value={this.state?.filters?.display_name}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "display_name",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.onKeyUpChange(
                              "display_name",
                              e.target.value === null || e.target.value === ""
                                ? null
                                : e.target.value
                            );
                          }
                        }}
                        icon={"FaSearch"}
                      />

                      <IconTextField
                        placeholder={"Title"}
                        width={"100%"}
                        value={this.state?.filters?.title}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "title",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value,
                            false
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.onKeyUpChange(
                              "title",
                              e.target.value === null || e.target.value === ""
                                ? null
                                : e.target.value
                            );
                          }
                        }}
                        icon={"FaSearch"}
                      />

                      <SelectField
                        width={"60%"}
                        id={"filter_comp_name"}
                        options={
                          [
                            {
                              label: "Active",
                              value: "Active",
                            },
                            {
                              label: "In-Active",
                              value: "In-Active",
                            },
                          ] || []
                        }
                        placeHolderText="Status"
                        value={this.state?.filters?.is_active || ""}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "is_active",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          )
                        }
                      />
                      <SelectField
                        width={"100%"}
                        id={"usertype"}
                        options={
                          this.state.userRoles?.map((item) => ({
                            label: item?.user_role,
                            value: item?.user_role,
                          })) || []
                        }
                        placeHolderText="User Level"
                        value={this.state?.filters?.usertype_id || ""}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "usertype_id",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          )
                        }
                      />
                      <SelectField
                        width={"200px"}
                        id={"show_name"}
                        options={
                          this.state.shows?.map((item) => ({
                            label: item?.show_name,
                            value: item?.show_name,
                          })) || []
                        }
                        placeHolderText="(Has access to)Show Name"
                        value={this.state?.filters?.show_name || ""}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "show_name",
                            e.target.value === null || e.target.value === ""
                              ? null
                              : e.target.value
                          )
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                {isLoading ? (
                  <div className="ContentLoader">
                    <CircularProgress />
                  </div>
                ) : (
                  <div id="UserManagementTable" className="table-striped">
                    <TableComponent
                      list={this.state.tableListSorted || []}
                      config={this.state.config}
                      options={this.state}
                      isLoading={false}
                      sortCallback={this.handleTableColumnClick}
                      tabValue={this.state.tabValue}
                      hyperLinkNavigationCallback={(item, node) =>
                        this.handleUserSelection(item, node)
                      }
                    />
                    <TablePagination
                      component="div"
                      count={this.state?.totalRows}
                      rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                      page={this.state?.filters?.page_no - 1}
                      onChangePage={this.handleChangePage}
                      rowsPerPage={this.state?.rowsPerPage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>You do not have permissions to manage Users</div>
            )}
          </div>
        </>
      );
    }
  }
);
