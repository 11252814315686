import React from "react";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import Routes from "../route/Routes";
import { MDBCol, MDBRow } from "mdbreact";
import { MDBContainer } from "mdb-react-ui-kit";
export default class MainContent extends React.Component {
  render() {
    return (
      <div>
        <MDBContainer fluid>
          <MDBRow className="mainContent">
            <MDBCol className="col-qtr"></MDBCol>
            <MDBCol className="w-auto main-content" id="main-content">
              <div ref={this.mainContent}>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </div>
            </MDBCol>
            <MDBCol className="col-qtr"></MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}
