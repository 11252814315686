export const ChartOfAccountConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      width: "10%",
      inputType: "checkbox",
      border: true,
    },
    {
      headingLabel: "Detail#",
      icon: "",
      inputType: "text",
      width: "20%",
    },
    {
      headingLabel: "Description",
      icon: "",
      inputType: "text",
      width: "45%",
    },
    {
      headingLabel: "Created On",
      icon: "",
      inputType: "text",
      width: "20%",
    },
  ],
  dataNodes: ["is_active", "acc_code", "acc_desc", "created_at"],
  primaryKey: "acc_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "9%",
    },
    {
      dataNode: "acc_code",
      width: "20%",
      isMandatory: true,
      validateMethod: "validateNumbersOnly",
    },
    {
      dataNode: "acc_desc",
      width: "64%",
      isMandatory: true,
    },
  ],
};
export const chartOfAccountList = [
  {
    Inactive: " ",
    detail: "1000",
    description: "[Description1]",
    id: 1,
  },
  {
    Inactive: " ",
    detail: "2000",
    description: "[Description2]",
    id: 2,
  },
  {
    Inactive: " ",
    detail: "3000",
    description: "[Description3]",
    id: 3,
  },
  {
    inactive: " ",
    detail: "4000",
    description: "[Description4]",
    id: 4,
  },
  {
    Inactive: " ",
    detail: "5000",
    description: "[Description5]",
    id: 5,
  },
  {
    Inactive: " ",
    detail: "6000",
    description: "[Description6]",
    id: 6,
  },
  {
    Inactive: " ",
    detail: "7000",
    description: "[Description7]",
    id: 7,
  },
  {
    Inactive: " ",
    detail: "8000",
    description: "[Description8]",
    id: 8,
  },
];
