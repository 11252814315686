import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../contexts/UserContext";
import BasicTabs from "../SharedComponents/BasicTabs/BasicTabs";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "./Reports.scss";
import { GroupByOption, SortByOption } from "./Config";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.placeHolderObj = { filter_value: null, operator: null, value: null };
    this.state = {
      postInitiated: false,
      reportsTabs: [
        { tab_id: 1, tab_name: "Purchase Order" },
        { tab_id: 2, tab_name: "Set Unit Log" },
      ],
      selectedTabValue: 1,
      ReportCriteriaDetails: null,
      operator: null,
      isFetchingReportFilters: false,
      reportFilters: null,
      userPostJson: [],
      groupby: null,
      sortby: "asc",
      showNoDataError: false,
      criteriaList: [],
      orientation: "p",
    };
  }

  componentDidMount = () => {
    this.getreportfilters();
    this.addCriteria();
  };

  handleOnChange = (field, index, value) => {
    let userPostJson = [...this.state.userPostJson];
    if (index !== null) {
      userPostJson[index][field] = value;
      this.setState({ userPostJson });
      if (field === "filter_value") {
        let operator = _.find(this.state.reportFilters, {
          filter_value: value,
        });
        if (operator) {
          let criteriaList = [...this.state.criteriaList];
          criteriaList[index] = operator.filter_operators;
          this.setState({ criteriaList });
        }
      }
    } else {
      if (field === "groupby") this.setState({ groupby: value });
      if (field === "sortby") this.setState({ sortby: value });
    }
  };
  handleOrientationChange = (value) => {
    this.setState({
      orientation: value,
    });
  };
  handleTabChange = (e, tabId) => {
    this.setState(
      {
        selectedTabValue: tabId,
        userPostJson: [],
        groupby: null,
        showNoDataError: null,
        criteriaList: [],
      },
      () => {
        this.addCriteria();
      }
    );
  };

  getreportfilters = () => {
    this.setState({ isFetchingReportFilters: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl + `/getreportfilters?reportId=1`,
      "",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          reportFilters: response.data,
          isFetchingReportFilters: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingReportFilters: false,
        });
      }
    );
  };

  addCriteria = () => {
    let userPostJson = [...this.state.userPostJson];
    if (!_.find(userPostJson, this.placeHolderObj)) {
      userPostJson.push({ ...this.placeHolderObj });
    }
    this.setState({ userPostJson });
  };

  removeCriteria = (index) => {
    let userPostJson = [...this.state.userPostJson];
    userPostJson.splice(index, 1);
    this.setState({ userPostJson });
  };

  generateReport = () => {
    this.setState({ isFetchingReportFilters: true, showNoDataError: false });
    let url = null;
    let orientation = this.state.orientation;
    if (this.state.selectedTabValue === 1) {
      url = `getporeport?reportName=polog_report_${orientation}&groupby=${this.state.groupby}&sortby=${this.state.sortby}`;
    } else {
      url = `getsetunitsreport?reportName=setunit_report_${orientation}`;
    }
    PostWatchService.getDataWithMultiFilters(
      Constants.postWatchServiceBaseUrl +
        `/${url}&userId=${this.props?.userContext?.user_profile?.user_id}`,
      this.state.userPostJson,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.downloadUrl(response.data);
        this.setState({
          isFetchingReportFilters: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingReportFilters: false,
          showNoDataError: true,
        });
      }
    );
  };
  downloadUrl = (url) => {
    if (url === "No Data Found") {
      this.setState({ showNoDataError: true });
    } else {
      window.open(url, "_blank");
      this.setState({ showNoDataError: false });
    }
  };

  clearFilters = () => {
    this.setState({ userPostJson: [] }, () => {
      this.addCriteria();
    });
  };

  render() {
    let isLoading = this.state.isFetchingReportFilters;
    return (
      <div className="">
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <>
            <MDBContainer className="Reports">
              <MDBRow className="reports-tab">
                <BasicTabs
                  value={this.state?.selectedTabValue}
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                  tabList={this.state.reportsTabs.map((item) => ({
                    value: item.tab_id,
                    label: item.tab_name,
                  }))}
                  onChange={(e, tabId) => {
                    this.handleTabChange(e, tabId);
                  }}
                />
              </MDBRow>
              {this.state?.reportFilters && (
                <>
                  {this.state.selectedTabValue === 1 && (
                    <MDBRow className={"report-criteria-header"}>
                      <MDBCol md={4} className="groupBy">
                        <BasicLabel text="Group By" type={"text"} />
                        <SelectField
                          id={"group_by"}
                          size="small"
                          placeHolderText={"-Group By-"}
                          options={GroupByOption || []}
                          value={this.state?.groupby || ""}
                          onChange={(e) =>
                            this.handleOnChange("groupby", null, e.target.value)
                          }
                        />
                      </MDBCol>
                      <MDBCol md={4} className="sortBy">
                        <BasicLabel
                          text="Sort By Detail number"
                          type={"text"}
                        />
                        <SelectField
                          id={"sort_by"}
                          size="small"
                          placeHolderText={"-Sort By Detail number-"}
                          options={SortByOption || []}
                          value={this.state?.sortby || ""}
                          onChange={(e) =>
                            this.handleOnChange("sortby", null, e.target.value)
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                  )}
                  <MDBRow>
                    <BasicLabel text="Report Criteria" type={"text"} />
                    <MDBRow className={"report-criteria"}>
                      {this.state?.userPostJson?.map((item, index) => {
                        return (
                          <>
                            <MDBCol md={3} className={"criteria col"}>
                              <SelectField
                                size="small"
                                options={
                                  this.state?.reportFilters?.map((item) => ({
                                    value: item.filter_value,
                                    label: item.filter_text,
                                  })) || []
                                }
                                value={item.filter_value || null}
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "filter_value",
                                    index,
                                    e.target.value
                                  )
                                }
                                placeHolderText={"-Criteria-"}
                              />
                            </MDBCol>
                            <MDBCol md={4} className={"operator col"}>
                              <SelectField
                                size="small"
                                options={
                                  this.state.criteriaList[index]?.map(
                                    (item) => ({
                                      value: item.operator_text,
                                      label: item.operator_text,
                                    })
                                  ) || []
                                }
                                value={item.operator || null}
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "operator",
                                    index,
                                    e.target.value
                                  )
                                }
                                placeHolderText={"-Operator-"}
                              />
                            </MDBCol>
                            <MDBCol md={3} className={"operator col"}>
                              <BasicTextField
                                onChange={(e) =>
                                  this.handleOnChange(
                                    "value",
                                    index,
                                    e.target.value
                                  )
                                }
                                value={item.value || null}
                                placeholder={"-Value-"}
                              />
                            </MDBCol>
                            <MDBCol md={0} className="actions col">
                                {this.state.userPostJson.length> 1 ?
                                  <RemoveCircleIcon
                                className="m-1 remove-item"
                                onClick={() => this.removeCriteria(index)}
                                /> 
                                : null}
                              {item.filter_value &&
                                  item.operator &&
                                  item.value && (
                                    <AddCircleIcon
                                      className="m-1 add-item"
                                      onClick={this.addCriteria}
                                    />
                                  )}
                            </MDBCol>
                          </>
                        );
                      })}
                    </MDBRow>
                  </MDBRow>
                </>
              )}
              <MDBRow>
                <MDBCol md={6}>
                  {this.state?.showNoDataError && (
                    <span className="redStar f-65rem">No Data Found</span>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="btn-row">
                <MDBCol md={6} className="orientation-radio-btn">
                  <FormControl className="radio-btn">
                    <RadioGroup
                      row
                      value={this.state?.orientation}
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={(e) =>
                        this.handleOrientationChange(e.target.value)
                      }
                    >
                      <MDBCol md={4}>
                        <FormControlLabel
                          value={"p"}
                          control={<Radio />}
                          label="Portrait"
                        />
                      </MDBCol>
                      <MDBCol md={6}>
                        <FormControlLabel
                          value={"l"}
                          control={<Radio />}
                          label="Landscape"
                        />
                      </MDBCol>
                    </RadioGroup>
                  </FormControl>
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    variant="contained"
                    type="inline"
                    text={"Generate"}
                    disabled={
                      !this.state?.userPostJson[0]?.filter_value ||
                      !this.state?.userPostJson[0]?.operator ||
                      !this.state?.userPostJson[0]?.value
                    }
                    onClick={this.generateReport}
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={"Cancel"}
                    onClick={this.props.onClose}
                    disabled={false}
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={"Clear"}
                    onClick={this.clearFilters}
                    disabled={false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </>
        )}
      </div>
    );
  }
}

export default withUserContext(Reports);
