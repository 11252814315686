import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as appConstants from "../../constants/appConstants";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextArea from "../SharedComponents/BasicTextArea/BasicTextArea";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import PhoneList from "../SharedComponents/PhoneList/PhoneList";
import TextList from "../SharedComponents/TextList/TextList";
import sizeLimits from "../../Common/SizeLimits.json";

import {
  validateCharctersSpaceOnly,
  validateEmail,
  validatePlusCharacter,
  validateCustomPhoneNumber,
} from "../../Common/Helper";

class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.placeHolderObj = {
      phonevalue: null,
      phonetype_id: null,
    };
    this.assistantsPlaceHolderObj = {
      assistant_id: null,
      assistant_name: null,
    };
    this.state = {
      isLoading: false,
      phoneList: [{ number: null, type: null }],
      contactDetails: null,
      postInitiated: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        contactDetails: JSON.parse(JSON.stringify(this.props.contactDetails)),
      },
      () => {
        this.addPhoneNumber({ ...this.placeHolderObj });
        this.addAssistants({ ...this.assistantsPlaceHolderObj });
      }
    );
  };
  // static getDerivedStateFromProps(props, state) {
  //   if (props.contactDetails?.contact_id !== state.contactDetails?.contact_id) {
  //     //Change in props, json parse is added since its persisting the previous form changes
  //     return {
  //       contactDetails: JSON.parse(JSON.stringify(props.contactDetails)),
  //     };
  //   }
  //   return null; // No change to state
  // }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.contactDetails?.contact_id !==
      prevProps?.contactDetails?.contact_id
    ) {
      this.setState(
        {
          contactDetails: JSON.parse(JSON.stringify(this.props.contactDetails)),
        },
        () => {
          this.addPhoneNumber({ ...this.placeHolderObj });
          this.addAssistants({ ...this.assistantsPlaceHolderObj });
        }
      );
    }
  }

  addPhoneNumber = (obj) => {
    let contactDetails = { ...this.state.contactDetails };
    if (contactDetails?.phonedetails) {
      contactDetails.phonedetails.push(obj);
    }
    this.setState({ contactDetails });
  };
  removePhoneNumber = (index) => {
    let contactDetails = { ...this.state.contactDetails };
    contactDetails.phonedetails.splice(index, 1);
    this.setState({ contactDetails });
  };
  addAssistants = (obj) => {
    let contactDetails = { ...this.state.contactDetails };
    if (contactDetails?.assistants) {
      contactDetails.assistants.push(obj);
    }
    this.setState({ contactDetails });
  };
  removeAssistants = (value) => {
    let contactDetails = { ...this.state.contactDetails };
    if (contactDetails.assistants.length > 1) {
      contactDetails.assistants.splice(value, 1);
      this.setState({ contactDetails });
    }
  };

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      contactDetails: {
        ...prevState.contactDetails,
        [field]: value,
      },
    }));
  };
  handlePhoneListOnChange = (value, index, field) => {
    let contactDetails = { ...this.state.contactDetails };
    contactDetails.phonedetails[index][field] = value;
    this.setState({ contactDetails });
  };
  handleAssistantOnChange = (value, index) => {
    let contactDetails = { ...this.state.contactDetails };
    contactDetails.assistants[index]["assistant_name"] = value;
    this.setState({ contactDetails });
  };

  validatePhone = (phone) => {
    const phoneValidation = phone.map((phoneNumber) =>
      phoneNumber ? validateCustomPhoneNumber(phoneNumber.phonevalue) : false
    );
    console.log(phoneValidation);
    return phoneValidation.every((element) => element === true);
  };

  validateBeforeSave = (contactDetails) => {
    let canSubmit =
      contactDetails?.full_name?.length > 0 &&
      (contactDetails?.company_name
        ? contactDetails?.company_name?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.department
        ? contactDetails?.department?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.title
        ? contactDetails?.title?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.email?.length > 0
        ? validateEmail(contactDetails?.email) &&
          contactDetails?.email?.length <= sizeLimits.emailCharacterLimit_100
        : true) &&
      contactDetails?.phonedetails.length > 0 &&
      contactDetails?.phonedetails[0]?.phonevalue.length > 0 &&
      this.validatePhone(contactDetails?.phonedetails) &&
      (contactDetails?.address_1
        ? contactDetails?.address_1?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.city
        ? contactDetails?.city?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.zip_code
        ? contactDetails?.zip_code?.length <= sizeLimits.usZipCharacterLimit
        : true) &&
      (contactDetails?.country
        ? contactDetails?.country?.length <= sizeLimits.nameCharacterLimit
        : true) &&
      (contactDetails?.notes
        ? contactDetails?.notes?.length <= sizeLimits.notesCharacterLimit_2000
        : true);

    return canSubmit;
  };

  clearEmptyPhoneNumbers = (contactDetails) => {
    return contactDetails.filter((obj) => obj.phonevalue && obj.phonetype_id);
  };
  clearEmptyAssistants = (assistants) => {
    return assistants.filter(
      (obj) => obj.assistant_name && obj.assistant_name.length > 0
    );
  };

  onSave = () => {
    this.setState({ postInitiated: true });
    let contactDetails = { ...this.state.contactDetails };
    let phoneList = this.clearEmptyPhoneNumbers(contactDetails.phonedetails);
    let assistants = this.clearEmptyAssistants(contactDetails.assistants);
    contactDetails.phonedetails = phoneList;
    contactDetails.assistants = assistants;
    if (this.validateBeforeSave(contactDetails)) {
      this.props.onSave(contactDetails);
    }
  };
  render() {
    return (
      <div className="contact-list-container">
        {this.props.isLoading ? (
          <div className="New-Set-Loader">
            {" "}
            <CircularProgress color="inherit" size={24} />{" "}
          </div>
        ) : (
          <>
            {this.state.contactDetails?.user_id ? (
              <MDBCol>
                <MDBRow className="ModalBody p-2">
                  <MDBCol md={12} className="Content">
                    <MDBRow>
                      <MDBCol md={8}>
                        <BasicTextField
                          label="Full Name"
                          isMandatory={true}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          value={this.state?.contactDetails?.full_name || ""}
                          showMandatory={this.state.postInitiated || false}
                          onChange={(e) => {
                            const re = /^[a-zA-Z_@./#&+-\s]*$/g;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              validateCharctersSpaceOnly(e.target.value) &&
                                this.handleOnChange(
                                  "full_name",
                                  e.target.value.length > 0
                                    ? e.target.value
                                    : null
                                );
                            }
                          }}
                        />
                      </MDBCol>
                      <MDBCol
                        md={4}
                        className={"d-flex justify-content-end align-items-end"}
                      >
                        <BasicCheckbox
                          id={"add_to_favorites"}
                          label="Add to favorites"
                          checked={this.state?.contactDetails?.is_favourite}
                          value={this.state?.contactDetails?.is_favourite || 0}
                          onChange={(e) =>
                            this.handleOnChange(
                              "is_favourite",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        <span>&nbsp;&nbsp;</span>
                        <BasicCheckbox
                          id={"inactive"}
                          label="Inactive"
                          checked={!this.state?.contactDetails?.is_active}
                          value={this.state?.contactDetails?.is_active || 0}
                          onChange={(e) =>
                            this.handleOnChange(
                              "is_active",
                              e.target.checked ? 0 : 1
                            )
                          }
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={6}>
                        <BasicTextField
                          label="Company"
                          value={this.state?.contactDetails?.company_name || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "company_name",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                        <BasicTextField
                          label="Title"
                          value={this.state?.contactDetails?.title || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "title",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                        {/* options = { states?.map( item => ({ value: item.value, label: item.label })) || [] }
                                                    value = { this.state?.contactDetails?.state } */}
                        <BasicLabel
                          text="Phone List"
                          type={"text"}
                          isMandatory={true}
                        />
                        <PhoneList
                          phoneTypePlaceholder="Select"
                          items={this.state?.contactDetails?.phonedetails || []}
                          limit={"15"}
                          isMandatory={true}
                          showMandatory={this.state.postInitiated}
                          limitWarning={appConstants?.MAX_CHAR_15}
                          handlePhoneListOnChange={this.handlePhoneListOnChange}
                          callTypeOptions={this.props?.phoneTypeList || []}
                          numberValueProperty={"phonevalue"}
                          typeValueProperty={"phonetype_id"}
                          addCallback={this.addPhoneNumber}
                          removeCallback={(index) =>
                            this.removePhoneNumber(index)
                          }
                        />
                      </MDBCol>

                      <MDBCol md={6}>
                        <BasicTextField
                          label="Department"
                          value={this.state?.contactDetails?.department || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "department",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />

                        <BasicTextField
                          label="Email"
                          value={this.state?.contactDetails?.email || ""}
                          limit={"100"}
                          limitWarning={appConstants?.MAX_CHAR_100}
                          inValidInput={"Invalid Email"}
                          fieldValid={
                            !validateEmail(this.state?.contactDetails?.email) &&
                            this.state?.contactDetails?.email
                          }
                          onChange={(e) =>
                            this.handleOnChange(
                              "email",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                        <BasicLabel
                          text={"Assistants"}
                          type={"text"}
                          isMandatory={this.props?.isMandatory || false}
                        />
                        <TextList
                          items={this.state?.contactDetails?.assistants || []}
                          valueProperty={"assistant_id"}
                          labelProperty={"assistant_name"}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          addCallback={this.addAssistants}
                          removeCallback={(index) => {
                            this.removeAssistants(index);
                          }}
                          onChange={(value, index) =>
                            this.handleAssistantOnChange(value, index)
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md={6}>
                        <BasicTextField
                          label="Address"
                          value={this.state?.contactDetails?.address_1 || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "address_1",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                        <BasicTextField
                          value={this.state?.contactDetails?.address_2 || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "address_2",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                      </MDBCol>
                      <MDBCol md={6}>
                        <BasicTextField
                          label="City"
                          value={this.state?.contactDetails?.city || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "city",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />

                        <MDBRow>
                          <MDBCol md={8}>
                            <BasicTextField
                              label="State"
                              value={this.state?.contactDetails?.state_id || ""}
                              limit={"50"}
                              limitWarning={appConstants?.MAX_CHAR_50}
                              onChange={(e) =>
                                validateCharctersSpaceOnly(e.target.value) &&
                                this.handleOnChange(
                                  "state_id",
                                  e.target.value.length > 0
                                    ? e.target.value
                                    : null
                                )
                              }
                            />
                          </MDBCol>
                          <MDBCol md={4}>
                            <BasicTextField
                              label="Zip Code"
                              value={this.state?.contactDetails?.zip_code || ""}
                              limit={"5"}
                              limitWarning={appConstants?.MAX_CHAR_5}
                              onChange={(e) =>
                                validateCharctersSpaceOnly(e.target.value) &&
                                this.handleOnChange(
                                  "zip_code",
                                  e.target.value.length > 0
                                    ? e.target.value
                                    : null
                                )
                              }
                            />
                          </MDBCol>
                        </MDBRow>

                        <BasicTextField
                          label="Country"
                          value={this.state?.contactDetails?.country || ""}
                          limit={"50"}
                          limitWarning={appConstants?.MAX_CHAR_50}
                          onChange={(e) =>
                            validateCharctersSpaceOnly(e.target.value) &&
                            this.handleOnChange(
                              "country",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md={12}>
                    <MDBRow>
                      <BasicTextArea
                        label={"Notes"}
                        rows={2}
                        id={"notes"}
                        value={this.state?.contactDetails?.notes}
                        limit={"2000"}
                        limitWarning={appConstants?.MAX_CHAR_2000}
                        onChange={(e) =>
                          this.handleOnChange("notes", e.target.value)
                        }
                      />
                    </MDBRow>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={8}></MDBCol>
                  {/* <MDBCol
                md={2}
                className="d-flex justify-content-end mb-2"
              ></MDBCol> */}
                  <MDBCol
                    md={4}
                    className="d-flex justify-content-between mb-2"
                  >
                    <BasicButton
                      type="basic"
                      text={
                        this.props.mode === appConstants.LABEL_NEW
                          ? "Create"
                          : "Save"
                      }
                      onClick={this.onSave}
                      disabled={
                        this.state?.contactDetails?.full_name?.trim().length ===
                        0
                      }
                    />
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Close"}
                      onClick={this.props.onClose}
                      disabled={false}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ) : (
              <div class="p-4">No contacts found</div>
            )}
          </>
        )}
      </div>
    );
  }
}
export default ContactDetails;
