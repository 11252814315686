import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../contexts/UserContext";
import BasicTabs from "../SharedComponents/BasicTabs/BasicTabs";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import "./ShowsModal.scss";
import "./ShowEpisodes.scss";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MessageModal from "../../../src/components/SharedComponents/MessageModal";
import {
  criteriaList,
  OperatorList,
  GroupByOption,
  initialUserDetails,
  NewShow,
} from "./Config";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
  validateCharctersOnly,
  validateCharOnly,
} from "../../../src/Common/Helper";
import { CircularProgress } from "@material-ui/core";
import * as Constants from "../../constants/constants";
import PostWatchService from "../../../src/services/service";
import _ from "lodash";
import ShowEpisodes from "./ShowEpisodes";

class ShowsModal extends React.Component {
  constructor(props) {
    super(props);
    this.placeHolderObj_ProdCompany = {
      prod_company_id: null,
      currency_id: null,
      exchange_rate: null,
      is_deleted: 0,
    };
    this.placeHolderObj_OtherCurrency = {
      other_curr_id: null,
      other_curr_exch_rate: null,
      is_deleted: 0,
    };
    this.selectedTabValueCopy = 1;
    this.state = {
      userPostJson: this.props.selectedShow
        ? JSON.parse(JSON.stringify(this.props.selectedShow))
        : null,
      postInitiated: false,
      reportsTabs: [
        { tab_id: 1, tab_name: "Details" },
        { tab_id: 2, tab_name: "Episodes" },
      ],
      selectedTabValue: 1,
      criteriaDetails: null,
      operator: null,
      filters: { comp_name: null, page: null, rowsPerPage: null },
      isFetchingProdCompanies: null,
      productionCompanies: null,
      isFetchingCurrencies: false,
      currencies: null,
      haveUnsavedChanges: false,
      isMessageModalOpen: false,
      seasonsList: null,
    };
  }
  componentDidMount = () => {
    // For new show
    if (this.props.mode === "new") {
      this.setState(
        { userPostJson: JSON.parse(JSON.stringify(NewShow)) },
        () => {
          this.addProdCompany();
          this.addOtherCurrency();
        }
      );
    } else {
      this.getShowDetails(this.props.selectedShow?.show_id);
    }
    this.getProductionCompanies();
    this.getCurrencies();
  };

  getShowDetails = (showId) => {
    this.setState({ isFetchingShowDetails: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getshowdetails?showId=" + showId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            userPostJson: response.data[0],
            isFetchingShowDetails: false,
            prodCompaniesList: response.data[0].prod_companies,
          },
          () => {
            this.addProdCompany(false);
            this.addOtherCurrency(false);
          }
        );
      },
      (err) => {
        this.setState({
          isFetchingShowDetails: false,
        });
      }
    );
  };

  getSeasons = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getseasons",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          seasonsList: response.data,
        });
      },
      (err) => {
        console.log("error in get seasons");
      }
    );
  };

  getProductionCompanies = () => {
    this.setState({
      isFetchingProdCompanies: true,
    });
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getproductioncompanies",
      this.state.filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        this.setState({
          productionCompanies: res.data?.records,
          isFetchingProdCompanies: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingProdCompanies: false });
      });
  };

  getCurrencies = () => {
    this.setState({
      isFetchingCurrencies: true,
    });
    let filter = {
      page_count: null,
      page_no: null,
    };

    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getcurrencies",
      filter,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        this.setState({
          currencies: res.data?.records,
          isFetchingCurrencies: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isFetchingCurrencies: false });
      });
  };

  handleOnChange = (field, value) => {
    this.setState({ haveUnsavedChanges: true });
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  handleProdcompanyChange = (field, index, value) => {
    let userPostJson = { ...this.state.userPostJson };
    userPostJson.prod_companies[index][field] = value;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };
  handleOtherCurreniesChange = (field, index, value) => {
    let userPostJson = { ...this.state.userPostJson };
    userPostJson.other_show_currencies[index][field] = value;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  handleTabChange = (e, tabId) => {
    if (this.state.haveUnsavedChanges) {
      // this.props.handleMessageModalStatus("unsaved", true);
      this.selectedTabValueCopy = tabId;
      this.setState({ isMessageModalOpen: true });
    } else {
      this.setState({
        selectedTabValue: tabId,
        isMessageModalOpen: false,
        haveUnsavedChanges: false,
      });
    }
  };

  onModalConfirm = () => {
    this.setState({
      selectedTabValue: this.selectedTabValueCopy,
      haveUnsavedChanges: false,
      isMessageModalOpen: false,
    });
  };

  addProdCompany = (unsavedFlag = true) => {
    let userPostJson = { ...this.state.userPostJson };
    if (
      userPostJson?.prod_companies &&
      !_.find(userPostJson?.prod_companies, this.placeHolderObj_ProdCompany)
    ) {
      userPostJson.prod_companies.push({ ...this.placeHolderObj_ProdCompany });
      this.setState({ userPostJson });
    }
    if (unsavedFlag) {
      this.setState({ userPostJson, haveUnsavedChanges: true });
    }
  };

  removeProdCompany = (dataitem) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));

    // userPostJson.prod_companies.splice(index, 1);
    let index = _.findIndex(userPostJson.prod_companies, {
      prod_company_id: dataitem.prod_company_id,
    });
    userPostJson.prod_companies[index].is_deleted = 1;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  addOtherCurrency = (unsavedFlag = true) => {
    let userPostJson = { ...this.state.userPostJson };
    if (
      userPostJson?.other_show_currencies &&
      !_.find(
        userPostJson?.other_show_currencies,
        this.placeHolderObj_OtherCurrency
      )
    ) {
      userPostJson.other_show_currencies.push({
        ...this.placeHolderObj_OtherCurrency,
      });
      this.setState({ userPostJson });
    }
    if (unsavedFlag) {
      this.setState({ userPostJson, haveUnsavedChanges: true });
    }
  };
  removeOtherCurrency = (dataitem) => {
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    // userPostJson.other_show_currencies.splice(index, 1);
    let index = _.findIndex(userPostJson.other_show_currencies, {
      other_curr_id: dataitem.other_curr_id,
    });
    userPostJson.other_show_currencies[index].is_deleted = 1;
    this.setState({ userPostJson, haveUnsavedChanges: true });
  };

  validateBeforeSave = (userPostJson) => {
    let canSubmit =
      userPostJson?.show_name?.length > 0 &&
      userPostJson?.po_prefix?.length > 0;
    return canSubmit;
  };

  convertToNumbers = (userPostJson) => {
    if (userPostJson?.prod_companies?.length > 0) {
      _.forEach(userPostJson.prod_companies, (obj) => {
        obj.exchange_rate = parseFloat(obj.exchange_rate);
      });
    }
    if (userPostJson?.other_show_currencies?.length > 0) {
      _.forEach(userPostJson.other_show_currencies, (obj) => {
        obj.other_curr_exch_rate = parseFloat(obj.other_curr_exch_rate);
      });
    }
    return userPostJson;
  };
  clearEmptyProdCompany = (prod_companies) => {
    return prod_companies.filter((obj) => obj.prod_company_id);
  };
  clearEmptyOtherCurrency = (other_currencies) => {
    return other_currencies.filter((obj) => obj.other_curr_id);
  };
  onSave = () => {
    this.setState({ postInitiated: true });
    let userPostJson = JSON.parse(JSON.stringify(this.state.userPostJson));
    let prod_companies = this.clearEmptyProdCompany(
      userPostJson.prod_companies
    );
    let other_currencies = this.clearEmptyOtherCurrency(
      userPostJson.other_show_currencies
    );
    userPostJson.prod_companies = prod_companies;
    userPostJson.other_show_currencies = other_currencies;
    if (this.validateBeforeSave(userPostJson)) {
      userPostJson = this.convertToNumbers(userPostJson);
      this.saveShowDetails(userPostJson);
    }
  };
  saveShowDetails = (userPostJson) => {
    this.setState({ isFetchingShowDetails: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postshow?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      userPostJson,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          isFetchingShowDetails: false,
          haveUnsavedChanges: false,
        });
        // New show created will not hae showid, so take it from response which is created after saving
        if (userPostJson.show_id === null) {
          this.getShowDetails(response.data[0].show_id);
        } else {
          this.getShowDetails(userPostJson.show_id);
        }
        this.props.onSave();
      },
      (err) => {
        this.setState({ isFetchingShowDetails: false });
        console.log("Post Show error: " + err);
      }
    );
  };

  updatehaveUnsavedChanges = (flag) => {
    this.setState({ haveUnsavedChanges: flag });
  };

  render() {
    let isLoading =
      this.state.isFetchingShowDetails ||
      this.state.isFetchingProdCompanies ||
      this.state.isFetchingCurrencies;
    return (
      <div>
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <MDBContainer className="show-Reports">
              <>
                <MDBRow>
                  <MDBCol md={8}>
                    <BasicLabel text="Show Name" isMandatory={true} />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={8}>
                    <BasicTextField
                      value={this.state.userPostJson?.show_name || ""}
                      onChange={(e) =>
                        validateCharctersNumberSpecialCharOnly(
                          e.target.value
                        ) && this.handleOnChange("show_name", e.target.value)
                      }
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      disabled={this.state.selectedTabValue === 2}
                    />
                  </MDBCol>
                  <MDBCol md={1}></MDBCol>
                  <MDBCol md={3}>
                    <BasicCheckbox
                      id={"active_inactive"}
                      label={"Mark as Inactive"}
                      value={this.state.userPostJson?.is_active || ""}
                      checked={!this.state.userPostJson?.is_active}
                      onChange={(e) =>
                        this.handleOnChange(
                          "is_active",
                          e.target.checked ? 0 : 1
                        )
                      }
                      disabled={this.state.selectedTabValue === 2}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={2}>
                    <BasicTextField
                      label={"PO Prefix"}
                      value={this.state.userPostJson?.po_prefix || ""}
                      onChange={(e) =>
                        e.target.value.length > 0
                          ? validateCharOnly(e.target.value) &&
                            this.handleOnChange("po_prefix", e.target.value)
                          : this.handleOnChange("po_prefix", null)
                      }
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      disabled={this.state.selectedTabValue === 2}
                    />
                  </MDBCol>
                  {/* <MDBCol md={3}>
                    <BasicTextField
                      label={"PO Start No"}
                      value={this.state.userPostJson?.po_start_no || ""}
                      onChange={(e) =>
                        this.handleOnChange("po_start_no", e.target.value)
                      }
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      disabled={this.state.selectedTabValue === 2}
                    />
                  </MDBCol> */}
                </MDBRow>
                <MDBRow className="reports-tab">
                  <BasicTabs
                    value={this.state?.selectedTabValue}
                    variant={"scrollable"}
                    scrollButtons={"auto"}
                    tabList={this.state.reportsTabs.map((item) => ({
                      value: item.tab_id,
                      label: item.tab_name,
                    }))}
                    disabled={!this.state.userPostJson?.show_id}
                    onChange={(e, tabId) => {
                      this.handleTabChange(e, tabId);
                    }}
                  />
                </MDBRow>
                {this.state.selectedTabValue === 1 && (
                  <>
                    <MDBRow>
                      <MDBCol md={6} className="groupBy">
                        <BasicLabel text="Production Companies" type={"text"} />
                      </MDBCol>
                      <MDBCol md={3} className="groupBy">
                        <BasicLabel text="Currency" type={"text"} />
                      </MDBCol>
                      <MDBCol md={2} className="groupBy">
                        <BasicLabel text="Exchange Rates" type={"text"} />
                      </MDBCol>
                      <MDBCol md={1} className="groupBy"></MDBCol>
                    </MDBRow>
                    <MDBRow className="prod-companies-container">
                      {this.state.userPostJson?.prod_companies?.map(
                        (item, index) => {
                          return (
                            <>
                              {item.is_deleted === 0 && (
                                <>
                                  <MDBCol md={6} className="criteria p-0">
                                    <SelectField
                                      size="small"
                                      options={
                                        this.state.productionCompanies?.map(
                                          (item) => ({
                                            value: item.prod_company_id,
                                            label: item.comp_name,
                                            is_active: item.is_active,
                                          })
                                        ) || []
                                      }
                                      showMandatory={
                                        this.props.showMandatory && index === 0
                                      }
                                      isMandatory={
                                        this.props.isMandatory && index === 0
                                      }
                                      value={item["prod_company_id"] || null}
                                      onChange={(e) => {
                                        this.handleProdcompanyChange(
                                          "prod_company_id",
                                          index,
                                          e.target.value
                                        );
                                      }}
                                      placeholder={
                                        this.props?.phoneTypePlaceholder ||
                                        "-Select-"
                                      }
                                    />
                                  </MDBCol>
                                  <MDBCol md={3} className="operator p-0">
                                    <SelectField
                                      size="small"
                                      options={
                                        this.state.currencies?.map((item) => ({
                                          value: item.currency_id,
                                          label: item.currency_code,
                                          is_active: item.is_active,
                                        })) || []
                                      }
                                      value={item["currency_id"] || null}
                                      onChange={(e) => {
                                        this.handleProdcompanyChange(
                                          "currency_id",
                                          index,
                                          e.target.value
                                        );
                                      }}
                                      placeholder={
                                        this.props?.phoneTypePlaceholder ||
                                        "-Select-"
                                      }
                                    />
                                  </MDBCol>
                                  <MDBCol md={2} className={"operator p-0"}>
                                    <BasicTextField
                                      value={item["exchange_rate"] || null}
                                      onChange={(e) => {
                                        this.handleProdcompanyChange(
                                          "exchange_rate",
                                          index,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </MDBCol>
                                  <MDBCol md={1} className="actions">
                                    <RemoveCircleIcon
                                      className="m-1 remove-item"
                                      removeProdCompany
                                      onClick={() =>
                                        this.removeProdCompany(item)
                                      }
                                    />

                                    {item?.prod_company_id && (
                                      <AddCircleIcon
                                        className="m-1 add-item"
                                        onClick={this.addProdCompany}
                                      />
                                    )}
                                  </MDBCol>
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={6} className="groupBy"></MDBCol>
                      <MDBCol md={3} className="groupBy">
                        <BasicLabel text="Other Currency" type={"text"} />
                      </MDBCol>
                      <MDBCol md={2} className="groupBy">
                        <BasicLabel text="Exchange Rates" type={"text"} />
                      </MDBCol>
                      <MDBCol md={1} className="groupBy"></MDBCol>
                    </MDBRow>
                    <MDBRow className="other_currencies-container p-0">
                      {this.state.userPostJson?.other_show_currencies?.map(
                        (item, index) => {
                          return (
                            <>
                              {item.is_deleted === 0 && (
                                <>
                                  <MDBCol
                                    md={6}
                                    className="criteria p-0"
                                  ></MDBCol>
                                  <MDBCol md={3} className="operator p-0">
                                    <SelectField
                                      size="small"
                                      options={
                                        this.state.currencies?.map((item) => ({
                                          value: item.currency_id,
                                          label: item.currency_code,
                                          is_active: item.is_active,
                                        })) || []
                                      }
                                      value={item["other_curr_id"] || null}
                                      onChange={(e) => {
                                        this.handleOtherCurreniesChange(
                                          "other_curr_id",
                                          index,
                                          e.target.value
                                        );
                                      }}
                                      placeholder={"-Select-"}
                                    />
                                  </MDBCol>
                                  <MDBCol md={2} className="operator p-0">
                                    <BasicTextField
                                      onChange={(e) => {
                                        this.handleOtherCurreniesChange(
                                          "other_curr_exch_rate",
                                          index,
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        item["other_curr_exch_rate"] || null
                                      }
                                      inValidInput={"Please enter valid number"}
                                    />
                                  </MDBCol>
                                  <MDBCol md={1} className="actions">
                                    <RemoveCircleIcon
                                      className="m-1 remove-item"
                                      onClick={() =>
                                        this.removeOtherCurrency(item)
                                      }
                                    />
                                    {item?.other_curr_id && (
                                      <AddCircleIcon
                                        className="m-1 add-item"
                                        onClick={this.addOtherCurrency}
                                      />
                                    )}
                                  </MDBCol>
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md={12}>
                        <BasicTextArea
                          label={"Notes"}
                          rows={2}
                          id={"message"}
                          limit={"2000"}
                          value={this.state.userPostJson?.notes || ""}
                          onChange={(e) =>
                            validateCharctersNumberSpecialCharOnly(
                              e.target.value
                            ) && this.handleOnChange("notes", e.target.value)
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="btn-row">
                      <MDBCol md={7}></MDBCol>
                      <MDBCol md={3}>
                        <BasicButton
                          variant="contained"
                          type="inline"
                          text={"Save"}
                          onClick={this.onSave}
                        />
                      </MDBCol>
                      <MDBCol md={2}>
                        <BasicButton
                          type="inline"
                          variant="outlined"
                          text={"Cancel"}
                          onClick={this.props.onClose}
                        />
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
                {this.state.selectedTabValue === 2 &&
                  this.state.userPostJson?.show_id && (
                    <ShowEpisodes
                      show_id={this.state.userPostJson?.show_id}
                      updatehaveUnsavedChanges={this.updatehaveUnsavedChanges}
                      onClose={this.props.onClose}
                      {...this.props}
                    >
                      {" "}
                    </ShowEpisodes>
                  )}
              </>
              <MessageModal
                open={this.state.isMessageModalOpen}
                showError={this.state.haveUnsavedChanges || false}
                primaryButtonText={"Ok"}
                secondaryButtonText={"Cancel"}
                hideCancel={false}
                onConfirm={() => this.onModalConfirm()}
                handleClose={(e) =>
                  this.setState({ isMessageModalOpen: false })
                }
              />
            </MDBContainer>
          </div>
        )}
      </div>
    );
  }
}

export default withUserContext(ShowsModal);
