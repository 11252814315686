import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

class BasicTabs extends React.Component {
  render() {
    return (
      <Box className="TabComponent" sx={{ width: "100%" }}>
        <Tabs
          value={this.props.value}
          onChange={this.props.onChange}
          textColor={"primary"}
          className="root"
          indicatorColor="primary"
          disabled={this.props.disabled}
          variant={this.props?.variant ? this.props?.variant : ""}
          scrollButtons={
            this.props?.scrollButtons ? this.props?.scrollButtons : ""
          }
          aria-label="primary tabs example"
        >
          {this.props?.tabList.map((tabItem, index) => (
            <Tab key={index} value={tabItem.value} label={tabItem.label} />
          ))}
                     
        </Tabs>
      </Box>
    );
  }
}
export default BasicTabs;
