export { showOption, seasonOption, episodeOption, initialPODetails };
const showOption = [
  { label: "The Big Bang theory", value: 1 },
  { label: "Friends", value: 2 },
  { label: "Animal Kingdom", value: 3 },
  { label: "Manifest", value: 4 },
];
const seasonOption = [
  { label: "Season 1", value: 1 },
  { label: "Season 2", value: 2 },
  { label: "Season 3", value: 2 },
];
const episodeOption = [
  { label: "Episode 1", value: 1 },
  { label: "Episode 2", value: 2 },
];
const initialPODetails = {
  show_id: null,
  season_id: null,
  episode_id: null,
};
