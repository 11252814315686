export const initialUserDetails = {
  user_id: null,
  full_name: null,
  display_name: null,
  tenant_id: null,
  tenant_name: null,
  usertype_id: null,
  user_type: null,
  login_email: null,
  notification_email: null,
  phone: null,
  title: null,
  is_active: 1,
  is_user_provisioner: 0,
  user_shows: [{ show_id: null, show_name: "" }],
  is_active_text: null,
};

export const initialRoleDetails = {
  tenant_id: null,
  role_id: null,
};

export const statusOption = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const tenantOption = [{ label: "WB Television", value: 1 }];

export const roleOption = [
  { label: "Admin", value: 1 },
  { label: "Regular User", value: 2 },
  { label: "Read-Only", value: 3 },
];

export const selectOptions = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "In-Active",
    value: 0,
  },
];

export const NewShow = {
  show_id: null,
  show_name: null,
};
