import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { MDBContainer } from "mdb-react-ui-kit";
import { withUserContext } from "../../contexts/UserContext";
import Button from "@material-ui/core/Button";
import IconTextField from "../../components/SharedComponents/IconTextField/IconTextField";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import TableComponent from "../../components/SharedComponents/Table";
import { validateNumbersOnly } from "../../../src/Common/Helper";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import { poExportConfig, POExportData } from "./Config";
import MessageModal from "../../components/SharedComponents/MessageModal";
import "./EditPORange.scss";

class EditPORange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poNumbersList: null,
      isFetchingDetails: false,
      poRange: { start: null, end: null },
      isFetchingRangeDetails: false,
      showRangeError: false,
      isFetchingCheckDetails: false,
      showConfirmMessage: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getPORange();
  }

  getPORange = () => {
    this.setState({ isFetchingDetails: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getporangedetails?showSeasonId=" +
        this.props?.selectedSeason?.show_season_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let data = response.data?.map((item) => {
          item.check = item.is_deleted == 0 ? false : true;
          return item;
        });
        this.setState({
          poNumbersList: data,
          isFetchingDetails: false,
        });
        this.props?.userContext?.setPORange(data);
      },
      (err) => {
        this.setState({ isFetchingDetails: false });
      }
    );
  };

  savePORange = () => {
    this.setState({ isFetchingDetails: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postporange?userId=${this.props?.userContext?.user_profile?.user_id}&poPrefix=${this.props?.showDetails?.po_prefix}&poStartNo=${this.state?.poRange?.start}&poEndNo=${this.state?.poRange?.end}`,
      this.state.poNumbersList,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.getPORange();
        // Get updated PO range label upon new range added
        this.getPORangeLabel(this.props?.selectedSeason?.show_season_id);
        this.setState({ isFetchingDetails: false });
        this.props.onSave();
      },
      (err) => {
        console.log("Post PO range error: " + err);
        this.setState({ isFetchingDetails: false });
      }
    );
  };

  getPORangeLabel = (showSeasonId) => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getshowseasonporange?showSeasonId=" +
        showSeasonId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.props.userContext.setPoRangeLabel(response.data[0].po_range);
      },
      (err) => {}
    );
  };

  arrayCheckUncheck = (id, dataItem) => {
    let poNumbersList = this.state.poNumbersList?.map((item) => {
      let newItem = { ...item };
      if (item?.po_number === dataItem?.po_number) {
        newItem.is_deleted = id ? 1 : 0;
        newItem.check = id;
      }
      return newItem;
    });
    this.setState({
      poNumbersList,
    });
  };

  onRangeChange = (field, value) => {
    this.setState((prevState) => ({
      poRange: {
        ...prevState.poRange,
        [field]: value,
      },
    }));
  };

  onSelectAllClick = () => {
    let poNumbersList = [...this.state.poNumbersList];
    _.forEach(poNumbersList, (item) => {
      if (item.is_avail == 1) {
        item.is_deleted = 1;
        item.check = true;
      }
    });
    this.setState({ poNumbersList });
  };

  addPONmbers = () => {
    if (this.state?.poRange?.start && this.state?.poRange?.end) {
      let poNumbersList = [...this.state.poNumbersList];
      for (
        var i = this.state?.poRange?.start;
        i <= this.state?.poRange?.end;
        i++
      ) {
        let obj = {
          ponum_id: null,
          showseason_id: this.props?.selectedSeason?.show_season_id,
          po_number: i,
          prefixnum: this.props?.showDetails?.po_prefix + " " + i,
          is_deleted: 0,
          is_avail: 1,
        };
        poNumbersList.push(obj);
      }
      this.setState({ poNumbersList }, () => {
        // this.savePORange();
      });
    }
  };
  // Calculate count of the items
  checkRange = () => {
    if (this.state?.poRange?.start && this.state?.poRange?.end) {
      this.setState({
        showConfirmMessage: true,
        count:
          parseInt(this.state.poRange.end, 10) -
          parseInt(this.state.poRange.start, 10) +
          1,
      });
    }
  };
  onModalConfirm = () => {
    this.setState({
      isFetchingCheckDetails: true,
      showRangeError: false,
      showConfirmMessage: false,
    });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        `/checkporangevalidity?poPrefix=${this.props?.showDetails?.po_prefix}&poStartNo=${this.state?.poRange?.start}&poEndNo=${this.state?.poRange?.end}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({ isFetchingCheckDetails: false });
        if (response.data[0]?.count > 0) {
          this.setState({
            showRangeError: true,
          });
        } else {
          this.addPONmbers();
        }
      },
      (err) => {
        this.setState({ isFetchingCheckDetails: false });
      }
    );
  };

  render() {
    let isLoading = this.state.isFetchingDetails;
    return (
      <div>
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <>
            <MDBContainer fluid className="edit-porange-main-content">
              <MDBRow>
                <MDBCol md={12}>
                  <div className="f-75rem c-black mt-2">
                    {this.props?.showName}(
                    {this.props?.selectedSeason?.season_name})
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol md={12}>
                  <div className="f-75rem c-black mt-2">
                    Add PO #'s by Range
                  </div>
                </MDBCol>
                <MDBCol md={12} className="edit-po-details">
                  <MDBRow>
                    <MDBCol md={3}>
                      <IconTextField
                        placeholder={"Start #"}
                        value={this.state?.poRange?.start || null}
                        onChange={(e) =>
                          e.target.value.length > 0
                            ? validateNumbersOnly(e.target.value) &&
                              this.onRangeChange("start", e.target.value)
                            : this.onRangeChange("start", null)
                        }
                      />
                    </MDBCol>
                    <MDBCol md={1}>
                      <div className="f-75rem c-black mt-2">to</div>
                    </MDBCol>
                    <MDBCol md={3}>
                      <IconTextField
                        placeholder={"End #"}
                        value={this.state?.poRange?.end || null}
                        onChange={(e) =>
                          e.target.value.length > 0
                            ? validateNumbersOnly(e.target.value) &&
                              this.onRangeChange("end", e.target.value)
                            : this.onRangeChange("end", null)
                        }
                        icon={"FaSearch"}
                      />
                    </MDBCol>
                    <MDBCol md={1} className="add-icon p-0">
                      <AddCircleIcon
                        className="m-1"
                        onClick={this.checkRange}
                      />
                    </MDBCol>
                    <MDBCol md={1} className="">
                      {this.state?.isFetchingCheckDetails ? (
                        <div className="ContentLoader edit-range-loader">
                          <CircularProgress />
                        </div>
                      ) : (
                        ""
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    {this.state.showRangeError && (
                      <MDBCol md={12} className="py-2 f-75rem redStar">
                        Provided Range already in use
                      </MDBCol>
                    )}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={12} className="py-2 edit-po-buttons">
                      <Button
                        variant="text"
                        className="export-po-btn green-button"
                        onClick={this.onSelectAllClick}
                      >
                        Select all Unused
                      </Button>
                      <Button
                        variant="text"
                        className="export-po-btn red-button"
                        onClick={this.savePORange}
                      >
                        Delete Selected
                      </Button>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="table-striped">
                    <MDBCol md={12}>
                      <div className="EditPORangeTable">
                        <TableComponent
                          list={this.state.poNumbersList || []}
                          config={poExportConfig}
                          isLoading={this.state.isFetchingPOHeaders || false}
                          arrayCheckUncheck={this.arrayCheckUncheck}
                          maxHeight={"300"}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="btn-row">
                    <MDBCol md={6}></MDBCol>
                    <MDBCol md={3}>
                      <BasicButton text={"Save"} onClick={this.savePORange} />
                    </MDBCol>
                    <MDBCol md={2}>
                      <BasicButton
                        type="inline"
                        variant="outlined"
                        text={"Cancel"}
                        onClick={this.props.onClose}
                        disabled={false}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {this.state.showConfirmMessage && (
                <MessageModal
                  open={this.state.showConfirmMessage}
                  showError={this.state.haveUnsavedChanges || false}
                  primaryButtonText={"Confirm"}
                  secondaryButtonText={"Cancel"}
                  hideCancel={false}
                  onConfirm={() => this.onModalConfirm()}
                  handleClose={(e) =>
                    this.setState({ showConfirmMessage: false })
                  }
                  showCustom={true}
                  message={`Are you sure you would like to add ${this.state.count} line items`}
                  title={"Confirmation"}
                />
              )}
            </MDBContainer>
          </>
        )}
      </div>
    );
  }
}

export default withUserContext(EditPORange);
