export const criteriaList = [
  { label: "criteria 1", value: 1 },
  { label: "criteria 2", value: 2 },
];
export const OperatorList = [
  { label: "Operator 1", value: 1 },
  { label: "Operator 2", value: 2 },
];
export const GroupByOption = [
  { label: "PO Number", value: 1 },
  { label: "Operator ", value: 2 },
];
export const initialUserDetails = {
  show_name: null,
  po_prefix: null,
  po_startNo: null,
  production_companies: null,
  currency: null,
  exchange_rate: null,
  notes: null,
};

export const NewShow = {
  show_id: null,
  show_name: "",
  po_prefix: "",
  po_range: null,
  po_start_no: null,
  notes: "",
  is_active: 1,
  prod_companies: [],
  other_show_currencies: [],
  show_seasons: [],
};
