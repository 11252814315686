import axios from "axios";
import authService from "../home/app.config";
import * as Constants from "../constants/constants";

class PostWatchService {
  constructor() {}

  async getData(httpPath, tenantId, encrypted = false) {
    let token = await authService.getAccessToken();

    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...(encrypted && { encryptionType: 1 }),
    };
    return axios.get(httpPath, { headers });
  }

  // Will remove this function later

  async getDataWithoutAuth(httpPath, tenantId, encrypted = false) {
    let token = await authService.getAccessToken();

    let headers = {
      "Content-Type": "application/json",
      // 'Authorization': `Bearer ${token}`,
      tenantid: tenantId,
      ...(encrypted && { encryptionType: 1 }),
    };
    return axios.get(httpPath, { headers });
  }

  async getDataParams(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.get(httpPath, {
      headers,
    });
  }
  async getDataWithPath(httpPath, tenantid) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid,
    };
    return axios.get(httpPath, {
      headers,
    });
  }

  async getUserContactsWithSearchObject(httpPath, tenantid, userId, searchObj) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid,
    };
    return axios.get(
      httpPath +
        "?userId=" +
        userId +
        "&searchJson=" +
        btoa(JSON.stringify(searchObj)),
      {
        headers,
      }
    );
  }

  async getDataWithFilters(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.get(httpPath + "?searchJson=" + btoa(JSON.stringify(params)), {
      headers,
    });
  }
  async getDataWithMultiFilters(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.get(httpPath + "&searchJson=" + btoa(JSON.stringify(params)), {
      headers,
    });
  }

  async getLandingDataParams(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.get(
      httpPath +
        "?searchJson=" +
        btoa(JSON.stringify(params)) +
        "&loginId=" +
        emailId,
      {
        headers,
      }
    );
  }
  async getLandingDataParamsWithoutQuestionMark(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.get(httpPath + "searchJson=" + btoa(JSON.stringify(params)), {
      headers,
    });
  }
  async getDataWithCancel(
    httpPath,
    cancelToken,
    tenantId,
    encryptionType = 1,
    aesKey = ""
  ) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
      encryptionType: 1,
    };
    if (encryptionType == 1) {
      return axios.get(httpPath, {
        headers: headers,
        cancelToken: cancelToken.token,
      });
    } else {
      return this.getEncryptedData(
        httpPath,
        tenantId,
        aesKey,
        cancelToken.token
      );
    }
  }

  async getDataWithCancelWithTenant(
    httpPath,
    cancelToken,
    tenantIdNew,
    encryptionType = 1
  ) {
    let token = await authService.getAccessToken();
    let tenantId = tenantIdNew;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
    };
    if (encryptionType == 1) {
      return axios.get(httpPath, {
        headers: headers,
        cancelToken: cancelToken.token,
      });
    } else {
      return this.getEncryptedData(httpPath, headers, cancelToken.token);
    }
  }
  async postDataBuffer(httpPath, body, tenantId) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
    };
    return axios.post(httpPath, body, { headers });
  }
  async postDataParams(httpPath, body, tenantId, encryptionType = 1) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptiontype: encryptionType,
      tenantid: tenantId,
    };
    if (encryptionType == 1) {
      // return axios.post(httpPath, btoa(JSON.stringify(body)), { headers });
      return axios.post(
        httpPath,
        btoa(unescape(encodeURIComponent(JSON.stringify(body)))),
        { headers }
      );
    } else if (encryptionType == 2) {
      //Retrieve Secret Key and use for decryption
      return this.getData(
        Constants.postWatchServiceBaseUrl + `/aesSecretKey`
      ).then(
        (response) => {
          let secretKey = response?.data;
          var CryptoJS = require("crypto-js");
          // Decrypt AES Key First
          var aesDecrypted = JSON.parse(atob(secretKey)).secret_key;
          // Decrypt
          var bytes = CryptoJS.AES.encrypt(JSON.stringify(body), aesDecrypted);
          return axios.post(httpPath, bytes.toString(), { headers });
        },
        (err) => {
          console.log("Error in post:", err);
        }
      );
    }
    //return null;
  }

  async deleteDataParams(httpPath, params, tenantId) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      encryptionType: 1,
      tenantid: tenantId,
    };
    return axios.delete(httpPath + "?input=" + btoa(JSON.stringify(params)), {
      headers,
    });
  }
  async deleteParam(httpPath, tenantId) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
    };
    return axios.delete(httpPath, { headers });
  }

  async fetchData(httpPath) {
    let token = await authService.getAccessToken();
    let tenantId = localStorage.getItem("active_tenant_id");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
    };

    let options = {
      headers: new Headers(headers),
    };

    return fetch(httpPath, options);
  }

  async fetchFile(httpPath) {
    return fetch(httpPath);
  }
  async postData(httpPath, body, tenantId) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
      encryptiontype: 1,
    };
    return axios.post(
      httpPath,
      btoa(unescape(encodeURIComponent(JSON.stringify(body)))),
      { headers }
    );
  }

  async postDataWithoutPayload(httpPath, tenantId) {
    let token = await authService.getAccessToken();
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
      encryptiontype: 1,
    };
    return axios.post(httpPath, "", { headers });
  }

  async uploadFileFormData(httpPath, formData) {
    let token = await authService.getAccessToken();
    return axios({
      method: "post",
      url: httpPath,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data;; charset=utf-8; boundary='----arbitraryboundary'`,
        encryptiontype: 1,
      },
    });
  }
  async uploadFile(httpPath, formData) {
    //
    let token = await authService.getAccessToken();

    // let headers = {
    //     'Content-Type': 'multipart/form-data',
    //     'Authorization': `Bearer ${token}`
    // };
    //Base64.extendString();
    //var axios = axios.create();
    //axios.defaults.headers.common = {};
    var client = axios.create({
      transformRequest: [
        (data, headers) => {
          //delete headers.common["Content-Type"];
          delete headers.put["Content-Type"];
          headers.put["Content-Type"] = "";
          return data;
        },
      ],
    });

    //delete client.defaults.headers.common["Content-Type"];
    return client.put(
      httpPath, // encodeGetParams(formData)
      // btoa(encodeURIComponent(formData).replace(/%([0-9A-F]{2})/g,
      // function toSolidBytes(match, p1) {
      //     return String.fromCharCode('0x' + p1);
      // }))
      //Base64.encode(formData)
      //formData.toBase64()
      //Base64.btoa(formData)
      formData
    );
  }

  async downloadFile(httpPath) {
    //
    //let token = await authService.getAccessToken();
    var client = axios.create({
      transformRequest: [
        (data, headers) => {
          //delete headers.common["Content-Type"];
          // delete headers.delete["Content-Type"];
          // headers.delete["Content-Type"] = '';
          return data;
        },
      ],
    });

    return client.get(httpPath, { responseType: "blob" });
  }

  encodeGetParams(params) {
    // var result = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    // return btoa(result);
    return btoa(
      encodeURIComponent(params).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  async getEncryptedData(httpPath, tenantId, aesKey = "", cancelToken = null) {
    //, isEncrypted = 0, encryptionType = 2
    //Marking Encryption Type as 2 for AES encryption type
    //No other encryption techinques as of now
    let data = null;
    let token = await authService.getAccessToken();
    // let tenantId = localStorage.getItem("active_tenant_id");
    let headerSection = {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: tenantId,
    };
    headerSection.headers = headers;
    if (cancelToken) {
      headerSection.cancelToken = cancelToken;
    }
    if (aesKey != "") {
      return axios.get(httpPath, headerSection).then(
        (res) => {
          if (res && res.data) {
            var CryptoJS = require("crypto-js");
            // Decrypt AES Key First
            var aesDecrypted = JSON.parse(atob(aesKey)).secret_key;
            var bytes = CryptoJS.AES.decrypt(res.data, aesDecrypted);
            data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return data;
          }
        },
        (err) => {}
      );
    }
    //Retrieve Secret Key and use for decryption
    // return this.getData(Constants.postWatchServiceBaseUrl + `/aesSecretKey`)
    //     .then(response => {
    //         let secretKey = response?.data?.secret_key;
    //         return axios.get(httpPath, headerSection).then(res => {
    //             if (res && res.data) {
    //                 var CryptoJS = require("crypto-js");
    //                 // Decrypt
    //                 var bytes = CryptoJS.AES.decrypt(res.data, secretKey);
    //                 data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //                 return data;
    //             }
    //         }, (err) => { })
    //     },
    //         (err) => {
    //             console.log("Error in fetching Address Types:", err)
    //         })
  }
}

export default new PostWatchService();
