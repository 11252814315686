import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import * as appConstants from "../../constants/appConstants";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from "../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import { withUserContext } from "../../contexts/UserContext";
import MessageModal from "../../../src/components/SharedComponents/MessageModal";
import {
  callDetailsDefault,
  call,
  timeFrame,
  closed,
  urgency,
  defaultContactDetails,
  filterObject,
  filterServiceObject,
  newContact,
} from "./Config";
import * as Constants from "../../constants/constants";
import PostWatchService from "../../services/service";
import "./LandingPage.scss";
import Radio from "@material-ui/core/Radio";
import BasicCheckbox from "../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTabs from "../../components/SharedComponents/BasicTabs/BasicTabs";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateField from "../../components/SharedComponents/DateField/DateField";
import Chip from "@material-ui/core/Chip";
import PhoneLogResults from "../../components/PhoneLogResults/PhoneLogResults";
import Modal from "../../components/SharedComponents/Modal/Modal";
import { SearchData } from "../../components/PhoneLogResults/PhoneLogResultsConfig";
import { AppService } from "../../services/AppService";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.tableDataCopy = null;
    this.filterServiceObject = { ...filterServiceObject };
    this.selectedContactValue = null;
    this.isEdit = false;
    this.phoneTypeList = null;
    this.state = {
      showNewSetModal: false,
      isLoadingSetList: false,
      isLoading: false,
      callType: null,
      callDetails: { call_direction: null },
      contactDetails: null,
      userContacts: null,
      selectedContact: null,
      currentGroupMemberTabId: null,
      toggleEditContactModal: false,
      toggleNewContactModal: false,
      filterCriteria: [],
      groupMembers: [],
      tableData: [],
      filterObject: { ...filterObject },
      postInitiated: false,
      callTakenByList: null,
      haveUnsavedChanges: false,
      isMessageModalOpen: false,
      tempCurrentGroupMemberTabId: null,
    };
  }

  checkManageUser = () => {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;

    if (userPermissions?.isManageUser) {
      this.props.history.push("/userManagement");
    } else {
      // this.props?.userContext.fetchsetList();
      // this.props?.userContext.getSetStaticList();
      // this.fetchAllSet();
    }
  };

  componentDidMount() {
    this.getGroupMembers();
    this.getCallType();
    this.checkManageUser();
    this.getPhoneType();
    this.tableDataCopy = [...SearchData];
    // subscribe to home component contacts update
    this.contacts$ = AppService.getContactsData().subscribe((data) => {
      if (data) {
        this.setUserContacts([...data]);
      }
    });
    this.lookup$ = AppService.getLookupData().subscribe((lookupType) => {
      if (lookupType === appConstants.LABEL_CALLTYPES) {
        this.getCallType();
      }
      if (lookupType === appConstants.LABEL_PHONETYPES) {
        this.getPhoneType();
      }
    });
    this.getPhoneLogs$ = AppService.getPhoneLogs().subscribe(() => {
      if (this.state.haveUnsavedChanges) {
        this.setState({
          isMessageModalOpen: true,
          tempCurrentGroupMemberTabId: this.state?.groupMembers[0]?.user_id,
        });
      } else {
        if (
          this.state?.groupMembers[0]?.user_id ===
          this.state.currentGroupMemberTabId
        ) {
          // If in inbox tab just refresh the grid
          this.refreshLogsGrid();
        } else {
          // If in other members tab, change the tab and refresh the grid
          this.setState(
            {
              currentGroupMemberTabId: this.state?.groupMembers[0]?.user_id,
            },
            () => {
              this.handleTabChange("", this.state?.groupMembers[0]?.user_id);
            }
          );
        }
      }
    });
  }
  componentWillUnmount() {
    this.contacts$.unsubscribe();
    this.lookup$.unsubscribe();
    this.getPhoneLogs$.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.userContext?.active_tenant?.tenant_id !==
      prevProps?.userContext?.active_tenant?.tenant_id
    ) {
      this.checkManageUser();
    }
  }
  getCallType = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getallmasterdata?masterTable=" +
        appConstants.LABEL_CALLTYPES
    ).then(
      (response) => {
        this.setState({ callType: response.data });
      },
      (err) => {
        console.log("Error in fetching call type:", err);
      }
    );
  };

  getGroupMembers = () => {
    PostWatchService.getDataWithPath(
      Constants.postWatchServiceBaseUrl +
        "/getgroupmembers?userId=" +
        this.props?.userContext?.user_profile?.user_id,
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            groupMembers: response.data,
            currentGroupMemberTabId: response.data[0]?.user_id,
            isLoadingSets: false,
          },
          () => {
            this.getCallLogs();
            this.getUserContacts();
            this.props?.userContext?.setGroupMembers(response.data);
            this.setCallTakenByDropDown();
          }
        );
      },
      (err) => {
        this.setState({ isLoadingSets: false });
        console.log("Error in fetching getGroupMembers", err);
      }
    );
  };

  onScroll = () => {
    this.filterServiceObject.currentTotal = this.state.tableData.length;
    this.getCallLogs();
  };

  pinIconClick = (item) => {
    let postObject = null;
    let contacts = this.state.userContacts.map((obj) => {
      if (obj.contact_id === item.value.contact_id) {
        obj.is_favourite = obj.is_favourite ? 0 : 1;
        postObject = obj;
        return obj;
      } else {
        return obj;
      }
    });
    if (postObject) {
      this.setState({ userContacts: contacts });
      PostWatchService.postDataParams(
        Constants.postWatchServiceBaseUrl + "/postcontactdetails ",
        postObject,
        this.props?.userContext?.user_profile?.tenant_id
      ).then(
        (response) => {
          this.getUserContacts();
        },
        (err) => {
          console.log("Error in saving CallDetails", err);
        }
      );
    }
  };

  getCallLogs = () => {
    // Show loader only on initial load
    if (this.state?.tableData?.length === 0) {
      this.setState({ isLoading: true });
    }
    PostWatchService.getLandingDataParams(
      Constants.postWatchServiceBaseUrl + "/getcalllogs",
      this.filterServiceObject,
      this.state.currentGroupMemberTabId,
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        let data = this.state.tableData;
        data.push(...response.data.data);
        this.setState({
          tableData: data,
          isLoading: false,
        });
      },
      (err) => {
        this.setState({ isLoading: false });
        console.log("Error in fetching CallLogs", err);
      }
    );
  };

  getPhoneType = () => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getallmasterdata?masterTable=" +
        appConstants.LABEL_PHONETYPES
    ).then(
      (response) => {
        this.phoneTypeList = response.data;
        this.props.userContext?.setPhoneTypes(response.data);
      },
      (err) => {
        console.log("Error in fetching phone type:", err);
      }
    );
  };
  getUserContacts = () => {
    PostWatchService.getDataWithPath(
      Constants.postWatchServiceBaseUrl +
        "/getusercontacts?userId=" +
        this.state.currentGroupMemberTabId,
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        this.props.userContext?.updateUserContacts([...response.data]);
      },
      (err) => {
        this.setState({ isLoading: false });
        console.log("Error in fetching user contacts", err);
      }
    );
  };

  setUserContacts = (data) => {
    data.push({ ...newContact });
    this.setState({ userContacts: data }, () => {
      if (this.selectedContactValue) {
        this.updateSelectedContact();
      }
    });
  };

  filterObjectHandler = (pro, value, label) => {
    let filterObject = { ...this.state.filterObject };

    filterObject[pro] = { ...filterObject[pro], value, label };
    this.filterServiceObject[filterObject[pro].name] = value;

    if (pro === "fromDate" || pro === "toDate") {
      filterObject["timeframe"].value = null;
      this.filterServiceObject["timeframe"] = null;
    }
    if (pro === "timeframe") {
      filterObject["fromDate"].value = null;
      filterObject["toDate"].value = null;
      this.filterServiceObject["fromDate"] = null;
      this.filterServiceObject["toDate"] = null;
    }

    /** Hard code time for "todate", required for API */
    if (pro === "toDate") {
      let toValue = filterObject["toDate"].value;
      this.filterServiceObject["toDate"] = toValue + " 23:59:59";
    }

    // On every filter change set currentTotal to zero to get first set of results
    filterObject.currentTotal = 0;
    this.filterServiceObject.currentTotal = 0;

    this.setState({ filterObject, tableData: [] }, () => {
      if (
        pro === "toDate" &&
        moment(value) < moment(this.state.filterObject?.fromDate.value)
      ) {
        // dont make api call if "To" date is greater than "From" date
        return false;
      } else {
        this.getCallLogs();
      }
    });
  };

  handleChipDelete = (pro, item) => {
    let filterObject = { ...this.state.filterObject };
    filterObject[pro] = item;
    this.filterServiceObject[filterObject[pro].name] = null;
    // On every filter change set currentTotal to zero to get first set of results
    filterObject.currentTotal = 0;
    this.filterServiceObject.currentTotal = 0;
    this.setState({ filterObject, tableData: [] }, () => {
      this.getCallLogs();
    });
  };

  handleContactChange = (item) => {
    if (item?.value?.contact_id === 9999) {
      this.setState((prevState) => {
        return {
          toggleNewContactModal: !prevState.toggleNewContactModal,
          selectedContact: null,
          contactDetails: this.getNewContactDefault(),
          callDetails: this.getCallDetailsDefault(),
        };
      });
      this.clearCallDetails();
      this.selectedContactValue = null;
    } else {
      if (item) {
        this.selectedContactValue = item;
        let callDetails = this.getCallDetailsDefault();
        callDetails.callback_id = item?.value?.phonedetails[0]?.contactphone_id;
        callDetails.contact_id = item?.value?.contact_id;
        this.setState({ selectedContact: item.value, callDetails });
      } else {
        // Clear phonelog form and set to intial state
        this.clearCallDetails();
      }
    }
  };
  handleOnChange = (field, value) => {
    this.setState({ haveUnsavedChanges: true });
    this.setState(
      (prevState) => ({
        callDetails: {
          ...prevState.callDetails,
          [field]: value,
        },
      }),
      () => {
        if (field === "calltype_id") {
          let message = "";
          let mesgText = "";
          if (this.state.callDetails?.message) {
            message = this.state.callDetails?.message?.split("~");
            mesgText =
              message && message.length === 1 ? message[0] : message[1];
          }

          this.handleOnChange(
            "message",
            this.state.callType?.find((item) => item.id === value)?.value +
              " ~ " +
              mesgText.trim()
          );
        }
      }
    );
  };

  onModalConfirm = () => {
    this.setState(
      { haveUnsavedChanges: false, isMessageModalOpen: false },
      () => {
        this.handleTabChange(null, this.state.tempCurrentGroupMemberTabId);
      }
    );
  };

  handleTabChange = (e, currentGroupMemberTabId) => {
    if (this.state.haveUnsavedChanges) {
      this.setState({
        isMessageModalOpen: true,
        tempCurrentGroupMemberTabId: currentGroupMemberTabId,
      });
    } else {
      this.setState(
        {
          selectedContact: null,
          callDetails: this.getCallDetailsDefault(),
          currentGroupMemberTabId,
        },
        () => {
          this.setCallTakenByDropDown();
        }
      );
      this.props?.userContext?.setCurrentGroupMember(currentGroupMemberTabId);
      this.props.userContext?.updateUserContacts(null);
      this.clearCallDetails();
      this.resetFilters();
      this.setState({ tableData: [] });
    }
  };

  resetFilters = () => {
    this.filterServiceObject = { ...filterServiceObject };
    this.setState({ filterObject: { ...filterObject } }, () => {
      this.getCallLogs();
      this.getUserContacts();
    });
  };
  setCallTakenByDropDown = () => {
    let list = this.state.groupMembers.find(
      (member) => member.user_id === this.state.currentGroupMemberTabId
    );
    this.setState({ callTakenByList: list?.messagetakenby_details });
  };
  handleEditContact = (contactDetails) => {
    this.setState({
      toggleEditContactModal: !this.state.toggleEditContactModal,
    });
  };
  updateSelectedContact = () => {
    let contact = this.state.userContacts.filter(
      (item) => item.contact_id === this.selectedContactValue.value.contact_id
    );
    if (contact?.length) {
      this.handleContactChange({
        text: contact[0].full_name,
        value: contact[0],
      });
    }
  };
  saveEditContact = (contactDetails) => {
    this.saveContact(contactDetails, "toggleEditContactModal");
  };
  saveNewContact = (contactDetails) => {
    this.saveContact(contactDetails, "toggleNewContactModal");
  };
  saveContact = (contactDetails, toggleContactModal) => {
    this.props.userContext.setAppLoader(true);
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl + "/postcontactdetails ",
      contactDetails,
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        this.props.userContext.setAppLoader(false);
        if (response.data.error) {
          AppService.showMessageModal("fail");
        } else {
          AppService.showMessageModal("success");
          this.getUserContacts();
        }
      },
      (err) => {
        this.setState({ isLoadingSets: false });
        AppService.showMessageModal("fail");
        this.props.userContext.setAppLoader(false);
        console.log("Error in saving CallDetails", err);
      }
    );
  };

  validateBeforeSave = (callDetails) => {
    let canSubmit =
      (callDetails?.calltype_id && callDetails?.callback_id) || false;
    return canSubmit;
  };

  saveCallDetails = (callDetailsObj) => {
    /* Dont set postInitiated when save is called from close calllog, since this will trigger the validation in the
    new phone log form and validations will show up in the UI */
    if (!callDetailsObj) {
      this.setState({ postInitiated: true });
    }
    this.setState({ tableData: [] });
    this.filterServiceObject.currentTotal = 0;
    let callDetails = callDetailsObj ? callDetailsObj : this.state?.callDetails;
    callDetails.groupmember_login_email = this.props.userContext?.currentGroupMember?.login_email;
    callDetails.login_email = this.props.userContext?.user_profile?.login_email;
    callDetails.contact_full_name = this.selectedContactValue?.value?.full_name;
    if (this.validateBeforeSave(callDetails)) {
      this.props.userContext.setAppLoader(true);
      PostWatchService.postDataParams(
        Constants.postWatchServiceBaseUrl + "/postcalllogdetails",
        callDetails,
        this.props?.userContext?.user_profile?.tenant_id
      ).then(
        (response) => {
          this.props.userContext.setAppLoader(false);
          this.getCallLogs();
          this.clearCallDetails();
          this.setState({ postInitiated: false, haveUnsavedChanges: false });
        },
        (err) => {
          this.setState({ isLoadingSets: false, postInitiated: false });
          this.props.userContext.setAppLoader(false);
          console.log("Error in saving CallDetails", err);
        }
      );
    }
  };
  clearCallDetails = () => {
    this.isEdit = false;
    this.selectedContactValue = null;
    this.setState({
      selectedContact: null,
      haveUnsavedChanges: false,
      callDetails: this.getCallDetailsDefault(),
    });
  };
  getCallDetailsDefault = () => {
    let obj = { ...callDetailsDefault };
    obj.user_id = this.props?.userContext?.user_profile?.user_id;
    obj.callforuser_id = this.state.currentGroupMemberTabId;
    return obj;
  };
  getNewContactDefault = () => {
    let obj = { ...defaultContactDetails };
    obj.user_id = this.state.currentGroupMemberTabId;
    return obj;
  };
  onRowClick = (callDetails) => {
    this.setState({ callDetails }, () => {
      this.setSelectedContact();
    });
  };

  onResponseClick = (callDetails) => {
    var callDetailsObj = { ...callDetails };
    callDetailsObj.call_direction = 1;
    callDetailsObj.calllog_id = null;
    this.setState({ callDetails: callDetailsObj }, () => {
      this.setSelectedContact();
    });
  };
  onCloseClick = (callDetails) => {
    var callDetailsObj = { ...callDetails };
    callDetailsObj.is_closed = 1;
    this.saveCallDetails(callDetailsObj);
  };
  filterContact = () => {
    return this.state.userContacts.filter((item) => {
      return this.state.callDetails.contact_id === item.contact_id;
    });
  };
  setSelectedContact = () => {
    let contact = this.filterContact();
    if (contact.length) {
      let obj = {
        value: contact[0],
        text: contact[0].full_name,
      };
      this.selectedContactValue = obj;
      this.setState({
        selectedContact: contact[0],
      });
      this.isEdit = true;
    }
  };
  handleCloseNewContactModal = () => {
    this.setState({ toggleNewContactModal: !this.state.toggleNewContactModal });
  };
  handleCloseEditContactModal = () => {
    this.setState({
      toggleEditContactModal: !this.state.toggleEditContactModal,
    });
  };

  refreshLogsGrid = () => {
    this.filterServiceObject.currentTotal = 0;
    this.setState({ tableData: [] }, () => {
      this.getCallLogs();
    });
  };

  render() {
    return (
      <MDBContainer fluid className="landing-page-main-content">
        <MDBRow>
          <BasicTabs
            value={this.state?.currentGroupMemberTabId}
            variant={"scrollable"}
            scrollButtons={"auto"}
            tabList={this.state.groupMembers.map((item) => ({
              value: item.user_id,
              label: item.full_name,
            }))}
            onChange={(e, tabId) => {
              this.handleTabChange(e, tabId);
            }}
          />
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div className="search-content">
              <MDBRow>
                <MDBCol md={3} className="custom-23">
                  <BasicLabel text={"Name"} />
                  <SearchSelectField
                    id={"set-name"}
                    width={"90%"}
                    placeholder={"-Search or Select-"}
                    editIcon={!this.isEdit}
                    disabled={this.isEdit}
                    onEditClicked={() => this.handleEditContact()}
                    value={this.selectedContactValue || null}
                    options={
                      this.state.userContacts?.map((item) => ({
                        value: item,
                        text: item.full_name,
                      })) || []
                    }
                    onChange={(e, value) => this.handleContactChange(value)}
                    pinIconClick={(item) => {
                      this.pinIconClick(item);
                    }}
                    showPinIcon="true"
                  />
                  {this.state?.selectedContact?.contact_id && (
                    <span>
                      <BasicLabel text={"Company/Title"} />{" "}
                      <span>
                        {this.state?.selectedContact?.company_name}
                        {this.state?.selectedContact?.title &&
                        this.state?.selectedContact?.company_name
                          ? ` / ${this.state?.selectedContact?.title}`
                          : this.state?.selectedContact?.title}
                      </span>
                      <BasicLabel text={"Department"} />{" "}
                      <span>{this.state?.selectedContact?.department}</span>
                    </span>
                  )}
                </MDBCol>
                <MDBCol md={2}>
                  <BasicLabel text={"Call Back No."} />
                  <SelectField
                    id={"callback-numbers"}
                    width={"90%"}
                    disabled={!this.selectedContactValue}
                    placeholder={"-Search or Select-"}
                    options={
                      this.state?.selectedContact?.phonedetails?.map(
                        (item) => ({
                          value: item?.contactphone_id,
                          label: item?.phonevalue,
                        })
                      ) || []
                    }
                    showMandatory={
                      this.state.postInitiated &&
                      !this.state?.callDetails?.callback_id
                    }
                    value={this.state?.callDetails?.callback_id || ""}
                    onChange={(e, value) =>
                      this.handleOnChange("callback_id", e.target.value)
                    }
                  />
                  <BasicLabel text={" "} /> <span>&nbsp;</span>
                  {this.state?.selectedContact?.contact_id && (
                    <React.Fragment>
                      <BasicLabel text={"Assitant"} />{" "}
                      <span>
                        {this.state?.selectedContact?.assistants
                          .map((item) => {
                            return item.assistant_name;
                          })
                          .join(", ")}
                      </span>{" "}
                    </React.Fragment>
                  )}
                </MDBCol>
                <MDBCol md={2}>
                  <MDBRow>
                    <MDBCol md={8}>
                      <BasicLabel text={"Call Direction"} />
                      <FormControl>
                        <RadioGroup
                          row
                          value={this.state?.callDetails?.call_direction}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          onChange={(e) =>
                            this.handleOnChange(
                              "call_direction",
                              parseInt(e.target.value, 10)
                            )
                          }
                        >
                          <MDBCol md={6}>
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="In"
                              disabled={
                                this.isEdit || !this.selectedContactValue
                              }
                            />
                          </MDBCol>
                          <MDBCol md={6}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Out"
                              disabled={
                                this.isEdit || !this.selectedContactValue
                              }
                            />
                          </MDBCol>
                        </RadioGroup>
                      </FormControl>
                    </MDBCol>
                    <MDBCol md={4}>
                      <BasicLabel text={"Urgent"} />
                      <BasicCheckbox
                        id={"urgent"}
                        disabled={this.isEdit || !this.selectedContactValue}
                        value={this.state?.callDetails?.is_urgent}
                        checked={this.state?.callDetails?.is_urgent}
                        onChange={(e) =>
                          this.handleOnChange(
                            "is_urgent",
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md={12} className={"centerElement"}>
                      <BasicLabel text={"Call Type"} />
                      <SelectField
                        id={"caltype"}
                        width={"80%"}
                        placeholder={"-Search or Select-"}
                        disabled={!this.selectedContactValue}
                        options={
                          this.state.callType?.map((item) => ({
                            value: item.id,
                            label: item.value,
                          })) || []
                        }
                        showMandatory={
                          this.state.postInitiated &&
                          !this.state?.callDetails?.calltype_id
                        }
                        value={this.state?.callDetails?.calltype_id || ""}
                        onChange={(e) =>
                          this.handleOnChange("calltype_id", e.target.value)
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md={4}>
                  <BasicTextArea
                    label={"Message"}
                    rows={3}
                    id={"message"}
                    limit={"2000"}
                    disabled={!this.selectedContactValue}
                    limitWarning={appConstants?.MAX_CHAR_2000_2}
                    value={this.state.callDetails?.message || ""}
                    onChange={(e) =>
                      this.handleOnChange("message", e.target.value)
                    }
                  />
                </MDBCol>
                <MDBCol md={1} className="custom-10 p-0">
                  {this.state?.callDetails?.calllog_id && (
                    <React.Fragment>
                      <BasicLabel text={"Date Created"} />{" "}
                      <span>{this.state.callDetails?.created_at}</span>
                      <BasicLabel text={"Date Updated"} />{" "}
                      <span>{this.state.callDetails?.updated_at}</span>
                      <BasicLabel text={"Date Closed"} />{" "}
                      <span>{this.state.callDetails?.closed_date}</span>{" "}
                    </React.Fragment>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={4}></MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md={7} className="custom-56"></MDBCol>
                <MDBCol md={2} className="d-flex justify-content-end p-0">
                  <BasicCheckbox
                    id={"emailOnSave"}
                    label={"Email on save"}
                    checked={this.state.callDetails?.emailonsave}
                    value={this.state.callDetails?.emailonsave}
                    disabled={!this.selectedContactValue}
                    onChange={(e) =>
                      this.handleOnChange(
                        "emailonsave",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={1} className="d-flex justify-content-end p-0">
                  {" "}
                  {
                    <BasicButton
                      className="btnwidth"
                      variant="contained"
                      type="inline"
                      text={"Save"}
                      disabled={!this.selectedContactValue}
                      onClick={() => this.saveCallDetails()}
                    />
                  }
                </MDBCol>
                <MDBCol md={1} className="d-flex justify-content-end pl-0">
                  {" "}
                  {
                    <BasicButton
                      className="btnwidth"
                      variant="outlined"
                      type="inline"
                      text={this.isEdit ? "Cancel" : "Clear"}
                      disabled={!this.selectedContactValue}
                      onClick={() => this.clearCallDetails()}
                    />
                  }
                </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <div className="results-container">
            <div className="search-content">
              <MDBIcon
                fas
                size="21x"
                icon="sync-alt"
                className="m-2 ml-3 float-end"
                onClick={this.refreshLogsGrid}
              />
              <ul className="chips-list">
                {Object.keys(this.state.filterObject).map((key, index) => {
                  if (this.state.filterObject[key].value) {
                    let obj = this.state.filterObject[key];
                    return (
                      <li key={key}>
                        {
                          <Chip
                            onDelete={() =>
                              this.handleChipDelete(key, {
                                ...obj,
                                ...{ value: null, label: null },
                              })
                            }
                            label={
                              this.state.filterObject[key].filterLabel +
                              ": " +
                              this.state.filterObject[key].label
                            }
                          />
                        }
                      </li>
                    );
                  }
                })}
              </ul>
              <MDBRow>
                <MDBCol sm={1} className="pr-0">
                  <SelectField
                    id={"call"}
                    placeHolderText={"-In/Out-"}
                    value={this.state?.filterObject?.in_out?.value || null}
                    options={
                      call?.map((item) => ({
                        value: item.value,
                        label: item.text,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "in_out",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol sm={1} className="pr-0">
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Call Type-"}
                    value={this.state?.filterObject?.call_type?.value || null}
                    options={
                      this.state.callType?.map((item) => ({
                        value: item.id,
                        label: item.value,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "call_type",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Call from-"}
                    value={this.state?.filterObject?.call_from?.value || null}
                    options={
                      this.state.userContacts?.map((item) => ({
                        value: item.contact_id,
                        label: item.full_name,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "call_from",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Call Taken By-"}
                    value={this.state?.filterObject?.taken_by?.value || null}
                    options={
                      this.state.callTakenByList?.map((item) => ({
                        value: item.messagetakenby_id,
                        label: item.messagetakenby_name,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "taken_by",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol>
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Urgency-"}
                    value={this.state?.filterObject?.urgent?.value || null}
                    options={
                      urgency?.map((item) => ({
                        value: item.value,
                        label: item.text,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "urgent",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol>
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Closed-"}
                    value={this.state?.filterObject?.closed?.value || null}
                    options={
                      closed?.map((item) => ({
                        value: item.value,
                        label: item.text,
                      })) || []
                    }
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "closed",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol>
                  <SelectField
                    id={"set-name"}
                    placeHolderText={"-Timeframe-"}
                    value={this.state?.filterObject?.timeframe?.value || null}
                    options={timeFrame || []}
                    onChange={(e, child) =>
                      this.filterObjectHandler(
                        "timeframe",
                        e.target.value,
                        child.props.children
                      )
                    }
                  />
                </MDBCol>
                <MDBCol sm={1} className="p-0 mt-1">
                  <DateField
                    id={"set-name"}
                    value={this.state?.filterObject?.fromDate?.value || null}
                    onChange={(e) =>
                      this.filterObjectHandler(
                        "fromDate",
                        e.target.value,
                        e.target.value
                      )
                    }
                  />
                </MDBCol>
                <MDBCol sm={1} className="p-0 mt-1">
                  <DateField
                    id={"set-name"}
                    value={this.state?.filterObject?.toDate?.value || null}
                    onChange={(e) =>
                      this.filterObjectHandler(
                        "toDate",
                        e.target.value,
                        e.target.value
                      )
                    }
                    showErrorBorder={
                      moment(this.state.filterObject?.toDate?.value) <
                      moment(this.state.filterObject?.fromDate?.value)
                    }
                    mandatoryWarning={"Error"}
                  />
                </MDBCol>
              </MDBRow>
            </div>
          </div>

          <PhoneLogResults
            data={this.state.tableData}
            isLoading={this.state.isLoading}
            onRowClick={this.onRowClick}
            onResponseClick={this.onResponseClick}
            onCloseClick={this.onCloseClick}
            onScroll={this.onScroll}
          ></PhoneLogResults>
        </MDBRow>
        <React.Fragment>
          {this.state.toggleEditContactModal && (
            <Modal
              open={this.state.toggleEditContactModal || false}
              modalName={"ContactList"}
              comfirmModalTitle={"Edit Contact"}
              mode={appConstants.LABEL_EDIT}
              userData={[this.state.selectedContact]}
              phoneTypeList={this.phoneTypeList}
              onSave={this.saveEditContact}
              onClose={this.handleCloseEditContactModal}
            />
          )}
        </React.Fragment>
        <React.Fragment>
          {this.state.toggleNewContactModal && (
            <Modal
              open={this.state.toggleNewContactModal || false}
              modalName={"ContactList"}
              comfirmModalTitle={"New Contact"}
              mode={appConstants.LABEL_NEW}
              userData={[this.state.contactDetails]}
              phoneTypeList={this.phoneTypeList}
              onSave={this.saveNewContact}
              onClose={this.handleCloseNewContactModal}
            />
          )}
        </React.Fragment>
        <MessageModal
          open={this.state.isMessageModalOpen}
          showError={this.state.haveUnsavedChanges || false}
          primaryButtonText={"Ok"}
          secondaryButtonText={"Cancel"}
          hideCancel={false}
          onConfirm={() => this.onModalConfirm()}
          handleClose={(e) => this.setState({ isMessageModalOpen: false })}
        />
      </MDBContainer>
    );
  }
}

export default withUserContext(LandingPage);
