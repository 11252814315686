export const poExportConfig = {
  headings: [
    {
      headingLabel: "",
      icon: "",
      width: "5%",
      inputType: "check",
    },
    {
      headingLabel: "PO #",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Date",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Vendor",
      icon: "",
      width: "20%",
      inputType: "text",
    },
    {
      headingLabel: "Total Cost",
      icon: "",
      width: "10%",
      inputType: "text",
    },
  ],
  dataNodes: [
    "check",
    "po_number",
    "created_at",
    "vendor_name",
    "extended_cost_value",
  ],
  primaryKey: "po_id",
  nodeFields: [
    {
      column: "selected",
      icon: "check",
    },
  ],
};

export const POExportData = [
  {
    po_id: 1,
    selected: false,
    created_at: "11/4/2022",
    vendor_name: "Test Vendor 1",
    total_cost: "387323",
  },
  {
    po_id: 2,
    selected: true,
    created_at: "21/2/2022",
    vendor_name: "Test Vendor 2",
    total_cost: "1634565",
  },
];
