import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import { withUserContext } from "../../contexts/UserContext";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from "../../components/SharedComponents/BasicTextArea/BasicTextArea";
import "./VendorModal.scss";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PostWatchService from "../../../src/services/service";
import * as Constants from "../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import sizeLimits from "../../Common/SizeLimits.json";
import {
  validateEmail,
  validateCustomPhoneNumber,
  validateNumbersOnly,
} from "../../Common/Helper";
import _ from "lodash";
import {
  newLocation,
  newContact,
  contactList,
  locationList,
  newVendor,
} from "../VendorModal/Config";

class VendorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContactValue: null,
      selectedLocationValue: null,
      postInitiated: false,
      operator: null,
      locationListItems: [],
      contactFields: false,
      locationFields: false,
      vendor: null,
      contactDropdownOptions: null,
      locationDropdownOptions: null,
      selectedContactDetails: null,
      selectedLocationDetails: null,
      isPosting: false,
    };
  }
  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      vendor: {
        ...prevState.vendor,
        [field]: value,
      },
    }));
  };

  handleSelectedContactOnChange = (field, value) => {
    if (value === 9999) {
      let contact = { ...newContact };
      contact.contact_name = "";
      this.setState({ selectedContactDetails: contact });
      this.setState((prevState) => ({
        selectedContactValue: {
          ...prevState.selectedContactValue,
          [field]: value,
        },
      }));
    } else {
      let contact = _.find(this.props.vendor.vendor_contacts, {
        vendor_contact_id: value,
      });
      if (contact) {
        this.setState({
          selectedContactValue: contact,
          selectedContactDetails: contact,
        });
      }
    }
  };

  handleSelectedLocationOnChange = (field, value) => {
    if (value === 9999) {
      let location = { ...newLocation };
      location.location_name = "";
      this.setState({ selectedLocationDetails: location });
      this.setState((prevState) => ({
        selectedLocationValue: {
          ...prevState.selectedLocationValue,
          [field]: value,
        },
      }));
    } else {
      let location = _.find(this.props.vendor.vendor_locations, {
        vendor_location_id: value,
      });
      if (location) {
        this.setState({
          selectedLocationValue: location,
          selectedLocationDetails: location,
        });
      }
    }
  };

  componentDidMount() {
    let vendor = null;

    if (this.props.vendor === -1) {
      vendor = JSON.parse(JSON.stringify({ ...newVendor }));
      let newContactCopy = { ...newContact };
      vendor.vendor_contacts.unshift(newContactCopy);

      this.setState({ contactDropdownOptions: vendor.vendor_contacts });
      vendor.vendor_locations.unshift({ ...newLocation });
      this.setState({ locationDropdownOptions: vendor.vendor_locations });
      let newContactCopy2 = { ...newContact };
      newContactCopy2.contact_name = "";
      let newLocationCopy2 = { ...newLocation };
      newLocationCopy2.location_name = "";
      this.setState({
        selectedContactDetails: newContactCopy2,
        selectedLocationDetails: newLocationCopy2,
        vendor,
      });
      this.setState({
        selectedContactValue: vendor.vendor_contacts[0],
        selectedLocationValue: vendor.vendor_locations[0],
      });
    } else {
      vendor = JSON.parse(JSON.stringify(this.props.vendor));
      let newContactCopy = { ...newContact };
      vendor.vendor_contacts.unshift(newContactCopy);

      this.setState({ contactDropdownOptions: vendor.vendor_contacts });
      vendor.vendor_locations.unshift({ ...newLocation });
      this.setState({ locationDropdownOptions: vendor.vendor_locations });

      this.setState({
        selectedContactDetails: { ...vendor.vendor_contacts[1] },
        selectedLocationDetails: { ...vendor.vendor_locations[1] },
        vendor,
      });
      this.setState({
        selectedContactValue: vendor.vendor_contacts[1],
        selectedLocationValue: vendor.vendor_locations[1],
      });
    }
  }

  validateBeforeSave = (userPostJson) => {
    let canSubmit =
      userPostJson?.vendor_name?.length > 0 &&
      // userPostJson?.tax_id >= 0 &&
      // userPostJson?.tax_id != null &&
      // userPostJson?.ap_number != null &&
      // userPostJson?.ap_number >= 0 &&
      (this.state.selectedContactDetails?.contact_email?.length > 0
        ? validateEmail(this.state.selectedContactDetails.contact_email) &&
          this.state.selectedContactDetails.contact_email &&
          this.state.selectedContactDetails.contact_email.length <=
            sizeLimits?.emailCharacterLimit
        : true) &&
      (userPostJson?.phone?.length > 0
        ? validateCustomPhoneNumber(userPostJson?.phone) &&
          userPostJson?.phone &&
          userPostJson?.phone.length <= sizeLimits?.phoneLimitUS
        : true) &&
      (userPostJson?.ap_number?.length > 0
        ? validateNumbersOnly(userPostJson?.ap_number) &&
          userPostJson?.ap_number
        : true);
    return canSubmit;
  };

  onSave = () => {
    this.setState({ postInitiated: true });
    let userPostJson = { ...this.state.vendor };
    console.log(userPostJson);
    if (this.validateBeforeSave(userPostJson)) {
      this.postData();
    }
  };

  postData = (data) => {
    this.setState({ isPosting: true });
    let vendor = JSON.parse(JSON.stringify(this.state.vendor));
    vendor.is_active = vendor.is_active ? 0 : 1;

    vendor.vendor_contacts = [];
    vendor.vendor_locations = [];
    vendor.vendor_contacts[0] = this.state.selectedContactDetails;
    vendor.vendor_locations[0] = this.state.selectedLocationDetails;
    if (this.state.selectedContactDetails?.vendor_contact_id === 9999) {
      vendor.vendor_contacts[0].vendor_contact_id = null;
    }
    if (this.state.selectedLocationDetails?.vendor_location_id === 9999) {
      vendor.vendor_locations[0].vendor_location_id = null;
    }
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postvendors?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      vendor,
      this.props?.userContext?.active_tenant?.tenant_id,
      1
    ).then(
      (response) => {
        if (!response.data.error) {
          this.setState({ isPosting: false });
          console.log("post response", response);
          this.props.onSave();
        } else {
          this.setState({ isPosting: false });
        }
      },
      (err) => {
        this.setState({ isPosting: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  handleOnLocationDetailsChange = (field, value) => {
    this.setState((prevState) => ({
      selectedLocationDetails: {
        ...prevState.selectedLocationDetails,
        [field]: value,
      },
    }));
  };

  handleOnContactDetailsChange = (field, value) => {
    this.setState((prevState) => ({
      selectedContactDetails: {
        ...prevState.selectedContactDetails,
        [field]: value,
      },
    }));
  };

  render() {
    return (
      <div>
        {this.state?.isPosting ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <>
            {this.state.vendor && (
              <MDBContainer className="vendorModal">
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicLabel text="Vendor Name" isMandatory={true} />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicLabel text="Phone" />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicTextField
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      value={this.state?.vendor?.vendor_name || ""}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      onChange={(e) =>
                        this.handleOnChange(
                          "vendor_name",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicTextField
                      value={this.state?.vendor?.phone || ""}
                      limit={sizeLimits.phoneLimitUS}
                      limitWarning={`Exceeding ${sizeLimits.phoneLimitUS} characters limit`}
                      onChange={(e) =>
                        this.handleOnChange(
                          "phone",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                      inValidInput={"Invalid Phone"}
                      fieldValid={
                        !validateCustomPhoneNumber(this.state?.vendor?.phone) &&
                        this.state?.vendor?.phone
                      }
                    />
                  </MDBCol>

                  <MDBCol md={3}>
                    <BasicCheckbox
                      id={"active_inactive"}
                      label={"Mark as Inactive"}
                      value={this.state?.vendor?.is_active || ""}
                      checked={this.state?.vendor?.is_active}
                      onChange={(e) =>
                        this.handleOnChange("is_active", e.target.checked)
                      }
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={3}>
                    <BasicLabel text="Tax ID" />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicLabel text="AP Number" />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={3}>
                    <BasicTextField
                      value={this.state?.vendor?.tax_id || ""}
                      // isMandatory={true}
                      // showMandatory={this.state.postInitiated}
                      onChange={(e) =>
                        this.handleOnChange(
                          "tax_id",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicTextField
                      isMandatory={true}
                      value={this.state?.vendor?.ap_number || ""}
                      // isMandatory={true}
                      // showMandatory={this.state.postInitiated}
                      limit={sizeLimits.apNumberLimit}
                      limitWarning={`Exceeding ${sizeLimits.apNumberLimit} characters limit`}
                      onChange={(e) =>
                        this.handleOnChange(
                          "ap_number",
                          e.target.value.length > 0 ? e.target.value : null
                        )
                      }
                      // inValidInput={"Invalid Ap Number"}
                      fieldValid={
                        !validateNumbersOnly(this.state?.vendor?.ap_number) &&
                        this.state?.vendor?.ap_number
                      }
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md={6}>
                    <BasicLabel text={"Contact"} />
                    <SelectField
                      size="small"
                      options={
                        this.state.contactDropdownOptions?.map((item) => ({
                          value: item.vendor_contact_id,
                          label: item.contact_name,
                        })) || []
                      }
                      value={
                        this.state.selectedContactValue?.vendor_contact_id ||
                        null
                      }
                      onChange={(e) => {
                        this.handleSelectedContactOnChange(
                          "vendor_contact_id",
                          e.target.value
                        );
                      }}
                      placeholder={"-Select-"}
                    />
                  </MDBCol>
                </MDBRow>
                {this.state?.selectedContactDetails && (
                  <MDBRow>
                    <MDBCol md={6}>
                      <MDBRow>
                        <MDBCol md={12}>
                          <BasicTextField
                            label={"Name"}
                            value={
                              this.state?.selectedContactDetails
                                ?.contact_name || ""
                            }
                            onChange={(e) =>
                              this.handleOnContactDetailsChange(
                                "contact_name",
                                e.target.value.length > 0
                                  ? e.target.value
                                  : null
                              )
                            }
                          />
                        </MDBCol>
                        <MDBCol md={12}>
                          <BasicTextField
                            label={"Email"}
                            value={
                              this.state?.selectedContactDetails
                                ?.contact_email || ""
                            }
                            limit={sizeLimits.emailCharacterLimit}
                            limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                            onChange={(e) =>
                              this.handleOnContactDetailsChange(
                                "contact_email",
                                e.target.value.length > 0
                                  ? e.target.value
                                  : null
                              )
                            }
                            inValidInput={"Invalid Email"}
                            fieldValid={
                              !validateEmail(
                                this.state?.selectedContactDetails
                                  ?.contact_email
                              ) &&
                              this.state?.selectedContactDetails?.contact_email
                            }
                          />
                          <span className="errorText">
                            {this.props?.allMails?.includes(
                              this.state.userPostJson?.login_email
                            ) && "Email already exists"}
                          </span>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={6}>
                      <BasicTextArea
                        label={"Notes"}
                        rows={3}
                        id={"message"}
                        limit={"2000"}
                        value={
                          this.state?.selectedContactDetails?.contact_notes ||
                          ""
                        }
                        onChange={(e) =>
                          this.handleOnContactDetailsChange(
                            "contact_notes",
                            e.target.value.length > 0 ? e.target.value : null
                          )
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                )}
                <hr />

                <MDBRow>
                  <MDBCol md={6}>
                    <BasicLabel text={"Location"} />
                    <SelectField
                      size="small"
                      options={
                        this.state.vendor.vendor_locations?.map((item) => ({
                          value: item.vendor_location_id,
                          label: item.location_name,
                        })) || []
                      }
                      value={
                        this.state.selectedLocationValue?.vendor_location_id ||
                        null
                      }
                      onChange={(e) => {
                        this.handleSelectedLocationOnChange(
                          "vendor_location_id",
                          e.target.value
                        );
                      }}
                      placeholder={"-Select-"}
                    />
                  </MDBCol>
                </MDBRow>
                {this.state?.selectedLocationDetails && (
                  <MDBRow>
                    <MDBCol md={6}>
                      <MDBRow>
                        <BasicTextField
                          label={"Location Name"}
                          value={
                            this.state?.selectedLocationDetails
                              ?.location_name || ""
                          }
                          onChange={(e) =>
                            this.handleOnLocationDetailsChange(
                              "location_name",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                        <BasicTextArea
                          label={"Address"}
                          rows={3}
                          id={"message"}
                          limit={"2000"}
                          value={
                            this.state?.selectedLocationDetails
                              ?.location_address || ""
                          }
                          onChange={(e) =>
                            this.handleOnLocationDetailsChange(
                              "location_address",
                              e.target.value.length > 0 ? e.target.value : null
                            )
                          }
                        />
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md={6}>
                      <BasicTextArea
                        label={"Notes"}
                        rows={3}
                        id={"message"}
                        limit={"2000"}
                        value={
                          this.state?.selectedLocationDetails?.location_notes ||
                          ""
                        }
                        onChange={(e) =>
                          this.handleOnLocationDetailsChange(
                            "location_notes",
                            e.target.value.length > 0 ? e.target.value : null
                          )
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                )}
                <MDBRow className="btn-row">
                  <MDBCol md={8}></MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      variant="contained"
                      type="inline"
                      text={"Save"}
                      onClick={this.onSave}
                    />
                  </MDBCol>
                  <MDBCol md={2}>
                    <BasicButton
                      type="inline"
                      variant="outlined"
                      text={"Cancel"}
                      onClick={this.props.onClose}
                      disabled={false}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )}{" "}
          </>
        )}
      </div>
    );
  }
}

export default withUserContext(VendorModal);
