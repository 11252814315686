import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import FormControl from "@material-ui/core/FormControl";
import "./BasicCheckbox.scss";
// import { createSvgIcon } from '@material-ui/core/utils';

// const CancelIcon = createSvgIcon(
//     <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25">
//     <path d="M0 0h22v22H0z" fill="none" stroke="#808080"/>
//     <path d="M21 19.1H3V5h18v14.1zM21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" fill='#808080' stroke-width="0.05px" stroke='#808080'/>
//     <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41z" fill='#4d4d4d' stroke-width="2px" stroke='#4d4d4d'/></svg>,
//     'CancelIcon',
//   );

export default class BasicCheckbox extends React.Component {
  render() {
    return (
      <div
        className="BasicCheckboxContainer"
        style={{ width: this.props?.width ? this.props?.width : "" }}
      >
        <FormControl component="fieldset" className="padding-ver-3">
          {/* <FormGroup aria-label="position" row>
                    <FormControlLabel
                        className={this.props?.className || ""}
                        control={<Checkbox color="primary"
                            checked={this.props?.checked}
                            onChange={this.props?.onChange}
                            disabled={this.props?.disabled ? this.props?.disabled : false}
                            checkedIcon={<CancelIcon style={{ fill: '#4d4d4d' }} />} />}
                            
                        label={this.props?.label ? this.props?.label : ""}
                        labelPlacement={this.props?.align ? this.props?.align : "start"}
                    />
                </FormGroup> */}
          <input
            type="checkbox"
            className="basic-cb"
            id={this.props?.id}
            value={this.props?.value}
            disabled={this.props?.disabled || false}
            onChange={this.props?.onChange}
            name={this.props?.name || this.props?.id}
            checked={this.props?.checked}
          />
          <label for={this.props?.id} aria-describedby="label">
            {this.props?.label}
          </label>
        </FormControl>
      </div>
    );
  }
}
