import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import ArrowCircleLeft from "@material-ui/icons/ArrowCircleLeft";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ContactList.scss";

import BasicTabs from "../SharedComponents/BasicTabs/BasicTabs";
import ContactDetails from "../ContactDetails/ContactDetails";
import FindContact from "../FindContact/FindContact";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import * as appConstants from "../../constants/appConstants";

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: null,
      currentContact: null,
      isLoading: false,
      phoneList: [{ number: null, type: null }],
      tabValue: 1,
      contactIndex: 0,
      totalContacts: 0,
    };
    this.tabList = null;
  }

  componentDidMount = (prevProps, prevState) => {
    this.setState({
      contactDetails: this.props?.contactDetails,
      currentContact: this.props?.contactDetails[0] || [],
      totalContacts: this.props?.contactDetails.length,
    });
  };
  handleFilterChange = (field, value) => {
    // this.setState(prevState => ({
    //     filterCriteria: {
    //         ...prevState.filterCriteria,
    //         [field]: value
    //     }
    // }))
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  findUserContacts = (searchObj) => {
    this.setState({ isLoading: true, tabValue: 1, noResultsFound: false });
    PostWatchService.getUserContactsWithSearchObject(
      Constants.postWatchServiceBaseUrl + "/getusercontacts",
      this.props?.userContext?.user_profile?.tenant_id,
      this.props?.userContext?.currentGroupMemberId,
      searchObj
    ).then(
      (response) => {
        if (response.data.length === 0) {
          this.setState({ noResultsFound: true });
        }
        this.setState({
          contactDetails: response.data,
          isLoading: false,
          contactIndex: 0,
          totalContacts: response.data.length,
          currentContact: response.data[0] || [],
        });
      },
      (err) => {
        this.setState({ isLoading: false, noResultsFound: false });
        console.log("Error in fetching user contacts", err);
      }
    );
  };

  forwardClick = () => {
    if (this.state.contactIndex + 1 < this.state.totalContacts) {
      var contact = this.state?.contactDetails[this.state.contactIndex + 1];
      var index = this.state.contactIndex + 1;
      this.setState({
        contactIndex: index,
        currentContact: contact,
      });
    }
  };

  backClick = () => {
    if (this.state.contactIndex > 0) {
      this.setState({
        contactIndex: this.state.contactIndex - 1,
        currentContact: this.state?.contactDetails[this.state.contactIndex - 1],
      });
    }
  };

  rendertab = () => {
    switch (this.state.tabValue) {
      case 1:
        return (
          <ContactDetails
            onSave={this.props.onSave}
            onClose={this.props.onClose}
            contactDetails={this.state?.currentContact}
            statesList={this.props?.statesList}
            phoneTypeList={this.props?.phoneTypeList}
            mode={this.props?.mode}
            isLoading={this.state.isLoading}
            contactIndex={this.state.contactIndex}
            noResultsFound={this.state.noResultsFound}
          />
        );
      case 2:
        return (
          <FindContact
            onClose={this.props.onClose}
            onGo={this.findUserContacts}
          />
        );
      default:
        return (
          <FindContact
            onClose={this.props.onClose}
            onGo={this.findUserContacts}
          />
        );
    }
  };

  onSave = () => {};

  render() {
    return (
      <div className="contact-list-container">
        {this.state.isLoading ? (
          <div className="New-Set-Loader">
            {" "}
            <CircularProgress color="inherit" size={24} />{" "}
          </div>
        ) : (
          <div>
            {this.props.mode === appConstants.LABEL_FIND && (
              <MDBRow className={"align-items-center"}>
                <MDBCol md={8}>
                  <BasicTabs
                    value={this.state.tabValue}
                    tabList={[
                      { label: "Details", value: 1 },
                      { label: "Find", value: 2 },
                    ]}
                    onChange={(e, newValue) => {
                      this.handleTabChange(e, newValue);
                    }}
                  />
                </MDBCol>
                {this.state.tabValue === 1 && (
                  <>
                    <MDBCol md={1} className={"align-middle"}>
                      <ArrowBackIosIcon
                        className="m-1"
                        onClick={this.backClick}
                      />
                    </MDBCol>
                    {this.state?.contactIndex +
                      1 +
                      " of " +
                      this.state?.totalContacts}
                    <MDBCol md={1} className={"align-middle"}>
                      <ArrowForwardIos
                        className="m-1"
                        onClick={this.forwardClick}
                      />
                    </MDBCol>
                  </>
                )}
              </MDBRow>
            )}
            {this.state?.currentContact && this.rendertab()}
          </div>
        )}
      </div>
    );
  }
}
export default ContactList;
