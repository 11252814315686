import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import React from "react";
import {
  manageLookupType,
  manageLookupConfig,
  jsonData,
} from "./ManageLookupConfig";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import InlineButtonField from "../../components/SharedComponents/InlineButtonField/InlineButtonField";
import TableComponent from "../SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {
  handlePaginationList,
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import messages from "../../Common/Messages.json";
import sizeLimits from "../../Common/SizeLimits.json";
import { withUserContext } from "../../contexts/UserContext";
import "./ManageLookup.scss";

class ManageLookup extends React.Component {
  constructor(props) {
    super(props);
    this.isLoading = false;
    this.state = {
      selectedType: "",
      tableData: [],
      config: manageLookupConfig,
      showContent: false,
      inlineButtonField: "",
      setIndex: 0,
      sortCount: 0,
      sortBy: "Value",
      isSortByAsc: true,
      formEdited: false,
      rowsPerPage: 10,
      page: 0,
      lookupType: [],
    };
    // setTimeout(() => {
    //   this.fetchDetails();
    // }, 3000);
  }

  componentDidMount() {
    this.setState({ lookupType: manageLookupType });
    /* let data = [];
        if(this.props.userContext?.active_tenant?.tenant_id === 4){
            lookupType.map((item)=>{
                if(item.value != "lu_role_type" && item.value != "lu_contract_status" && item.value != "lu_network"){
                    data.push(item)
                }
            });
            this.setState({lookupType:data})
        } else {
            this.setState({lookupType:manageLookupType})
        } */
  }

  fetchDetails = (val) => {
    this.isLoading = true;
    this.setState({ selectedType: val }, () => {
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
          "/getallmasterdata?masterTable=" +
          val
      ).then(
        (response) => {
          this.isLoading = false;
          let formattedList = response.data?.map((item) => ({
            value: item.id,
            label: item.value,
            check: item.is_active === 1 ? true : false,
          }));
          if (formattedList.length > 0) {
            this.setState({
              tableData: formattedList,
              showContent: true,
              inlineButtonField: "",
              page: 0,
              setIndex: 0,
            });
          }
          this.setState({
            renderList: handlePaginationList(
              this.state.page,
              this.state.rowsPerPage,
              this.state.tableData
            ),
          });
        },

        (err) => {
          this.isLoading = false;
          this.setState({ selectedType: val });

          console.log("Error in fetching Market Types:", err);
        }
      );
    });
  };
  arrayCheckUncheck = (event, dataItem) => {
    let details = this.state.tableData;
    details.map((item) => {
      if (item.value === dataItem.value) {
        item.check = dataItem.check;
      }
    });
    this.setState({ tableData: details, formEdited: true }, () => {
      this.props.handleMessageModalStatus("unsaved", true);
    });
  };

  editData = (dataItem, node) => {
    this.setState({
      inlineButtonField: dataItem.label,
      setIndex: dataItem.value,
    });
  };

  editList = () => {
    if (this.state.setIndex === 0) {
      if (
        this.state.inlineButtonField &&
        this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit &&
        !this.state.tableData
          ?.map((item) => item?.label?.toLowerCase())
          ?.includes(this.state.inlineButtonField)
      ) {
        let details = this.state.tableData;
        details.push({
          value: null,
          label: this.state.inlineButtonField,
          check: true,
        });
        this.setState(
          { tableData: details, formEdited: true, inlineButtonField: "" },
          () => {
            this.setState({
              renderList: handlePaginationList(
                this.state.page,
                this.state.rowsPerPage,
                this.state.tableData
              ),
            });
            this.props.handleMessageModalStatus("unsaved", true);
          }
        );
      }
    } else {
      if (
        this.state.inlineButtonField.length <= sizeLimits.nameCharacterLimit &&
        this.state.inlineButtonField &&
        !this.state.tableData
          ?.map((item) =>
            item?.value === this.state.setIndex
              ? null
              : item?.label?.toLowerCase()
          )
          ?.includes(this.state.inlineButtonField)
      ) {
        let details = this.state.tableData;
        details.map((item) => {
          if (item.value === this.state.setIndex) {
            item.label = this.state.inlineButtonField;
          }
        });
        this.setState(
          {
            tableData: details,
            formEdited: true,
            inlineButtonField: "",
            setIndex: 0,
          },
          () => {
            this.props.handleMessageModalStatus("unsaved", true);
          }
        );
      }
    }
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        config: updateTableSortConfigObject(
          this.state.config,
          this.state.isSortByAsc,
          this.state.sortBy,
          colName
        ),
      },
      () => {
        this.setState({
          sortBy: colName,
          renderList: handlePaginationList(
            this.state.page,
            this.state.rowsPerPage,
            tableSortList(
              "Name",
              this.getSortNode(colName),
              this.state.tableData,
              this.state.isSortByAsc
            )
          ),
        });
      }
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
      renderList: handlePaginationList(
        newPage,
        this.state.rowsPerPage,
        this.state.tableData
      ),
    });
  };

  getSortNode = (col) => {
    switch (col) {
      case "Value":
        return "label";
      case "Active":
        return "staticMode";
    }
  };

  handleSubmit = () => {
    this.setState({ isSubmit: true });
    let lookupArray = this.state.tableData.map((item) => ({
      value: item.label.trim(),
      id: item.value,
      is_active: item.check ? 1 : 0,
    }));
    let lookupJson = {
      table_name: this.state.selectedType,
      values: lookupArray,
    };
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl + "/postmasterdata",
      lookupJson,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({ isSubmit: false }, () => {
          if (response.data.error) {
            this.props.handleMessageModalStatus("fail", true);
          } else {
            this.props.handleMessageModalStatus("success", true);
            // this.props.userContext?.getSetStaticList();
            this.props.userContext.updateLookUpData(this.state.selectedType);
          }
        });
      },
      (err) => {
        console.log("Error in fetching Work Details:", err);
        this.setState({ isSubmit: false }, () => {
          this.props.handleMessageModalStatus("fail", true);
        });
      }
    );
  };

  render() {
    return (
      <MDBContainer className="ManageLookupContainer">
        <MDBRow>
          <MDBCol md={8}>
            <div className="pt5 lookup-type">
              <SelectField
                placeHolderText={"- Select Type -"}
                value={this.state.selectedType}
                options={manageLookupType}
                onChange={(e) => {
                  this.fetchDetails(e.target.value);
                }}
              />
              {this.isLoading && <CircularProgress className="ContentLoader" />}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <div className="pt5 lookup-table">
            <TableComponent
              list={this.state.renderList || []}
              config={manageLookupConfig}
              isLoading={false}
              arrayCheckUncheck={this.arrayCheckUncheck}
              editData={this.editData}
              sortCallback={this.handleTableColumnClick}
            />
          </div>
        </MDBRow>
        {this.state?.renderList?.length > 0 && (
          <MDBRow className="Pagination">
            <TablePagination
              component="div"
              count={this.state.tableData ? this.state.tableData?.length : 0}
              rowsPerPageOptions={[]}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              rowsPerPage={this.state.rowsPerPage}
            />
          </MDBRow>
        )}
        <MDBRow>
          {!this.state.showContent ? (
            <div className="pt5">
              <span className="productionFont pl3">
                {" "}
                Choose a Lookup Type to View its values
              </span>
            </div>
          ) : null}
        </MDBRow>
        <MDBRow>
          {this.state.showContent ? (
            <div className="add-lookup">
              <MDBRow>
                <MDBCol md={12}>
                  <div className="pt5 add-new-block">
                    <MDBCol md={12} className="inline-block addnew-block">
                      <InlineButtonField
                        id={"key20"}
                        value={this.state.inlineButtonField}
                        placeholder={
                          this.state.setIndex === 0 ? "Add Value" : "Edit Value"
                        }
                        onChange={(e) =>
                          this.setState({ inlineButtonField: e.target.value })
                        }
                        buttonOnClick={(e) => this.editList()}
                        limit={sizeLimits.nameCharacterLimit}
                        limitWarning={messages.exceed50CharacterWarning || ""}
                        fieldValid={
                          this.state.setIndex === 0
                            ? this.state.tableData
                                ?.map((item) => item?.label?.toLowerCase())
                                ?.includes(
                                  this.state.inlineButtonField?.toLowerCase()
                                )
                            : this.state.tableData
                                ?.map((item) =>
                                  item?.value == this.state.setIndex
                                    ? null
                                    : item?.label?.toLowerCase()
                                )
                                ?.includes(
                                  this.state.inlineButtonField?.toLowerCase()
                                )
                        }
                      />
                    </MDBCol>
                    <MDBCol md={4} className="inline-block pl1">
                      {this.state.setIndex != 0 ? (
                        <Button
                          onClick={() =>
                            this.setState({
                              setIndex: 0,
                              inlineButtonField: "",
                            })
                          }
                          variant="contained"
                          size="small"
                          color="primary"
                        >
                          Cancel
                        </Button>
                      ) : null}
                    </MDBCol>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="p-2">
                <MDBCol md={6}></MDBCol>
                <MDBCol md={3} className="model-button">
                  <BasicButton
                    className="btnwidth"
                    variant="contained"
                    type="inline"
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        this.props?.primaryButtonText
                      )
                    }
                    disabled={false}
                    onClick={() => this.handleSubmit()}
                  />
                </MDBCol>
                <MDBCol md={3} className="model-button">
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={this.props?.secondaryButtonText}
                    disabled={false}
                    onClick={() => this.props.onModalClose()}
                  />
                </MDBCol>
              </MDBRow>
            </div>
          ) : null}
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(ManageLookup);
