export const handlePaginationList = (page, rowsPerPage, list) => {
  let startIndex = page * rowsPerPage;
  let lastIndex = startIndex + rowsPerPage;
  let paginatedList = list?.slice(startIndex, lastIndex);
  return paginatedList;
};

export const updateTableSortConfigObject = (
  configObject,
  isSortByAsc,
  currentSortBy,
  nextSortBy
) => {
  let newHeadingArray = configObject.headings.map((item) => {
    if (item.headingLabel === nextSortBy && nextSortBy !== "Actions") {
      item.icon = isSortByAsc ? "sort-alpha-down" : "sort-alpha-up";
    } else if (
      nextSortBy !== currentSortBy &&
      item.headingLabel === currentSortBy &&
      nextSortBy !== "Actions"
    ) {
      item.icon = "";
    }
    return item;
  });
  delete configObject.headings;
  configObject.headings = newHeadingArray;
  return configObject;
};

export const tableSortList = (type, typeField, list, isSortByAsc) => {
  let sortedList;
  switch (type) {
    case "Name":
      if (isSortByAsc) {
        sortedList = list?.sort((a, b) =>
          a[typeField]?.toString().split(" ").join("").toLowerCase() >
          b[typeField]?.toString().split(" ").join("").toLowerCase()
            ? 1
            : -1
        );
      } else {
        sortedList = list?.sort((a, b) =>
          b[typeField]?.toString().split(" ").join("").toLowerCase() >
          a[typeField]?.toString().split(" ").join("").toLowerCase()
            ? 1
            : -1
        );
      }
      break;

    case "Date":
      if (isSortByAsc) {
        sortedList = list?.sort((a, b) =>
          Date.parse(a[typeField]?.toString().trim()) >
          Date.parse(b[typeField]?.toString().trim())
            ? 1
            : -1
        );
      } else {
        sortedList = list?.sort((a, b) =>
          Date.parse(b[typeField]?.toString().trim()) >
          Date.parse(a[typeField]?.toString().trim())
            ? 1
            : -1
        );
      }
      break;
  }
  return sortedList;
};

export const applyFilterTable = (config, data, filterValues, filtersNone) => {
  var finalList = [];
  var finalResult = data.slice();
  var primaryKey = config.primaryKey;
  if (!filtersNone) {
    filterValues.map((filter) => {
      var keyValue = filter.split(":");
      var name = keyValue[0].trim();
      var value = keyValue[1].trim().toLowerCase();

      var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

      Object.keys(config?.headings).map((item) => {
        console.log(config?.headings[item]);
        if (config?.headings[item].headingLabel == name) {
          index = item;
          return;
        }
      });

      var nodeName = config?.dataNodes[index];
      var filteredList = finalResult?.filter((item) =>
        item[nodeName] != null
          ? item[nodeName].toString().toLowerCase().indexOf(value) > -1
          : false
      );
      // finalResult = finalResult.concat(filteredList?.filter((list) => !finalResult.find(p => p[primaryKey] == list[primaryKey])));
      finalResult = filteredList.slice();
    });
  } else {
    finalResult = data;
  }
  //this.setState({filteredList : finalResult});
  return finalResult;
};
