export const setUnitConfig = {
  headings: [
    {
      headingLabel: "Inactive",
      icon: "",
      width: "10%",
      inputType: "checkbox",
    },
    {
      headingLabel: "Unit Code",
      icon: "",
      width: "20%",
      inputType: "text",
    },
    {
      headingLabel: "Description",
      icon: "",
      width: "45%",
      inputType: "text",
    },
    {
      headingLabel: "Created On",
      icon: "",
      inputType: "text",
      width: "20%",
    },
  ],
  dataNodes: ["is_active", "unit_code", "description", "created_at"],
  primaryKey: "setunit_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
  inlineEdits: [
    {
      dataNode: "is_active",
      width: "9%",
    },
    {
      dataNode: "unit_code",
      width: "20%",
      isMandatory: true,
    },
    {
      dataNode: "description",
      width: "45%",
      isMandatory: true,
    },
  ],
};
export const setUnitList = [
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 1,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 2,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 3,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 4,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 5,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 6,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 7,
  },
  {
    inactive: "yes",
    unit_code: "1000",
    description: "[Description]",
    id: 8,
  },
];
