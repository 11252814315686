import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTabs from "../../../components/SharedComponents/BasicTabs/BasicTabs";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableComponent from "../../../components/SharedComponents/Table";
import * as Constants from "../../../constants/constants";
import PostWatchService from "../../../../src/services/service";
import { currenciesConfig, currencyList } from "./Config";
import { handlePaginationList } from "../../../Common/TableHelper";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Seasons.scss";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
  validateNumbersOnly,
} from "../../../../src/Common/Helper";
import MessageModal from "../../../../src/components/SharedComponents/MessageModal";
import Message from "../../../../src/Common/Messages.json";

class Seasons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInitiated: false,
      isSubmit: false,
      isLoading: false,
      tableListSorted: [],
      page: 1,
      rowsPerPage: 10,
      totalRows: 0,
      hasDuplicateError: false,
      filters: null,
      sortBy: null,
      sortOrder: "asc",
      a_z: false,
      config: JSON.parse(JSON.stringify({ ...currenciesConfig })),
      isMessageModalOpen: false,
      isErrorMessageModalOpen: false,
    };
  }
  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  componentDidMount() {
    this.getSeasonsDetails();
  }

  handleInlineEdits = (id, dataItem) => {
    let seasonDetails = [...this.state.tableListSorted];
    this.setState({
      tableListSorted: seasonDetails.map((item) => {
        if (item.season_id === id) item["editing"] = true;
        return item;
      }),
    });
  };

  saveInlineEdits = (obj, index) => {
    let data = { ...obj[index] };
    data.is_active = data.is_active ? 0 : 1;
    if (this.validateBeforeSave(data)) {
      this.postData(data);
      this.setState({ hasDuplicateError: false });
    }
  };

  validateBeforeSave = (seasonData) => {
    let canSubmit =
      (seasonData?.start_year
        ? validateNumbersOnly(seasonData?.start_year)
        : false) &&
      (seasonData?.end_year
        ? validateNumbersOnly(seasonData?.end_year)
        : false);

    return canSubmit;
  };

  postData = (data) => {
    this.setState({ isLoading: true });
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl +
        `/postseason?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      1,
      1
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          //From API we are getting three errors, one for invalid year(error code 2000), 1062 for duplicate and 2100 for "not allowed for delete"
          if (response.data[0]?.Code === 2100) {
            this.setState({
              isErrorMessageModalOpen: true,
              isMessageModalOpen: false,
              hasDuplicateError: false,
            });
          }
          if (response.data[0]?.Code === 2000) {
            this.setState({
              hasDuplicateError: response.data[0]?.Message,
            });
          }
          if (response.data[0]?.Code === 1062) {
            this.setState({
              hasDuplicateError: "Duplicate Year Entered",
            });
          }
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isMessageModalOpen: false,
            hasDuplicateError: false,
            isLoading: false,
            postInitiated: false,
          });
          this.getSeasonsDetails();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({ isLoading: false, isSubmit: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  getSeasonsDetails = () => {
    this.setState({ isLoading: true });
    let filters = { ...this.state.filters };
    filters.page_count = this.state.rowsPerPage;
    filters.page_no = this.state.page;
    filters.sort_by = this.state.sortBy;
    filters.sort_order = this.state.sortOrder;
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getseasons",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let tableResults = res?.data?.records?.map((obj) => {
          obj.is_active = obj.is_active ? 0 : 1;
          return obj;
        });
        let seasonDetails = tableResults;
        this.setState({
          tableListSorted: seasonDetails,
          totalRows: res.data?.total_records_count,
          isLoading: false,
          isSubmit: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  getPosition = (val) => {
    switch (val) {
      case "Inactive":
        return 0;
      case "Display Name":
        return 1;
      case "Season Start":
        return 2;
      case "Season End":
        return 3;
      case "Created On":
        return 4;
    }
  };
  getColumnName = (val) => {
    switch (val) {
      case "Inactive":
        return "is_active";
      case "Display Name":
        return "start_year";
      case "Season Start":
        return "start_year";
      case "Season End":
        return "end_year";
      case "Created On":
        return "created_at";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let order = null;
    let config = JSON.parse(JSON.stringify(this.state?.config));
    if (this.state.a_z && config?.headings[Position]?.icon != "") {
      if (config?.headings[Position]?.icon == "sort-alpha-up") {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-down";
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      config?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      config.headings[Position].icon = "sort-alpha-up";
      order = "asc";
    }
    this.setState(
      { sortBy: col_name, sortOrder: order, config, page: 1 },
      () => {
        this.getSeasonsDetails();
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (!newDataItem) {
      return false;
    }
    if (this.validateBeforeSave(newDataItem)) {
      // let list = [...this.state.tableListSorted];
      let newData = {
        is_active: newDataItem?.is_active ? 0 : 1,
        start_year: parseInt(newDataItem?.start_year),
        end_year: parseInt(newDataItem?.end_year),
      };
      // list.push(newData);
      // this.setState({
      //   tableListSorted: list,
      // });
      this.postData(newData);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1, hasDuplicateError: null }, () => {
      this.getSeasonsDetails();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        page: 1,
        hasDuplicateError: null,
      },
      () => {
        this.getSeasonsDetails();
      }
    );
  };

  handleDelCallBack = (id, dataItem) => {
    this.deleteItem = dataItem;
    this.setState({ isMessageModalOpen: true });
  };

  onModalConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postData(item);
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };

  render() {
    let isLoading = this.state.isPosting;
    return (
      <div>
        <MDBContainer className="currencyContainer">
          <MDBRow className="table-striped">
            {isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (
              <>
                {this.state?.hasDuplicateError?.length > 0 && (
                  <span className="redStar f-65rem text-end mb-2">
                    {this.state?.hasDuplicateError}
                  </span>
                )}
                <TableComponent
                  list={this.state?.tableListSorted || []}
                  className={"clearencRecord"}
                  config={this.state.config}
                  isLoading={this.state?.isLoading || false}
                  sortCallback={this.sortColumn}
                  stickyHeader={true}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  addItemToList={true}
                  showMandatory={this.state.postInitiated}
                  handleDelCallBack={this.handleDelCallBack}
                />
                <div className="Pagination">
                  <TablePagination
                    component="div"
                    count={this.state?.totalRows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    page={this.state?.page - 1}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state?.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </MDBRow>
        </MDBContainer>
        {this.state?.isMessageModalOpen && (
          <MessageModal
            open={this.state.isMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdmin}
            title={"Confirm Deletion"}
            primaryButtonText={"Ok"}
            secondaryButtonText={"Cancel"}
            hideCancel={false}
            onConfirm={() => this.onModalConfirm()}
            handleClose={(e) => this.setState({ isMessageModalOpen: false })}
          />
        )}
        {this.state?.isErrorMessageModalOpen && (
          <MessageModal
            open={this.state.isErrorMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdminError}
            title={"Message"}
            primaryButtonText={"Ok"}
            hideCancel={true}
            onConfirm={() => this.onErrorModalConfirm()}
            handleClose={(e) =>
              this.setState({ isErrorMessageModalOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

export default withUserContext(Seasons);
