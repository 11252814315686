import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import BasicButton from "./BasicButton/BasicButton";
import Messages from "../../Common/Messages.json";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid grey",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
  },
  label: {
    fontSize: "0.75rem",
  },
}));

export default function MessageModal(props) {
  const classes = useStyles();
  let hideCancel = props.hideCancel || false;
  let hideSubmit = props?.primaryButtonText != "" ? true : false;
  let message = props?.showCustom
    ? props?.message
    : props?.showError
    ? Messages.unsavedMessage
    : props?.showFail
    ? Messages.failMessage
    : props?.showSuccess
    ? Messages.saveEditMessage
    : Messages.defaultMessage;
  let title = props?.showCustom
    ? props?.title
    : props?.showError
    ? Messages.unsavedTitle
    : props?.showFail
    ? Messages.failTitle
    : props?.showSuccess
    ? Messages.saveTitle
    : Messages.defaultTitle;
  return (
    <div className="BasicModalContainer">
      <Modal
        open={props?.open ? props?.open : false}
        //onClose={props?.handleClose}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <div className="MessageTitle">
            <label>{title}</label>
          </div>
          <div className="MessageContent">
            <span className="save-message">{message}</span>
            <br />
            <div className="ButtonDiv">
              {hideSubmit ? (
                <BasicButton
                  variant="contained"
                  text={props?.primaryButtonText}
                  type="inline"
                  onClick={props?.onConfirm}
                  disabled={false}
                />
              ) : null}
              {!hideCancel && (
                <div className="FloatRight">
                  <BasicButton
                    variant="outlined"
                    type="inline"
                    text={props?.secondaryButtonText}
                    onClick={props?.handleClose}
                    disabled={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
