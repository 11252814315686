import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTabs from "../../../components/SharedComponents/BasicTabs/BasicTabs";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableComponent from "../../../components/SharedComponents/Table";
import { chartOfAccountList, ChartOfAccountConfig } from "./Config";
import * as Constants from "../../../constants/constants";
import PostWatchService from "../../../../src/services/service";
import MessageModal from "../../../../src/components/SharedComponents/MessageModal";
import "./ChartOfAccount.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppService } from "../../../../src/services/AppService";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
  validateNumbersOnly,
} from "../../../../src/Common/Helper";
import Message from "../../../../src/Common/Messages.json";

class ChartOfAccount extends React.Component {
  constructor(props) {
    super(props);
    this.deleteItem = null;
    this.state = {
      postInitiated: false,
      renderList: [],
      isSubmit: false,
      isLoading: false,
      tableListSorted: [],
      filters: { acc_code: null, description: null },
      page: 1,
      rowsPerPage: 10,
      totalRows: 0,
      isMessageModalOpen: false,
      isErrorMessageModalOpen: false,
      sortBy: null,
      sortOrder: "asc",
      a_z: false,
      config: JSON.parse(JSON.stringify({ ...ChartOfAccountConfig })),
    };
  }

  componentDidMount() {
    this.onKeyUpChange();
  }

  handleInlineEdits = (id, dataItem) => {
    let chartOfAccountDetail = this.state.renderList;
    this.setState({
      haveUnsavedChanges: true,
      renderList: chartOfAccountDetail.map(
        (item) => {
          if (item.acc_id === id) item["editing"] = true;
          return item;
        },
        () => {
          this.props.handleMessageModalStatus("unsaved", true);
          console.log("handleInlineEdit", this.state.renderList);
        }
      ),
    });
  };

  saveInlineEdits = (obj, index) => {
    let data = { ...obj[index] };
    data.is_active = data.is_active ? 0 : 1;
    if (this.validateBeforeSave(data)) {
      this.postData(data);
    }
  };

  validateBeforeSave = (chartOfAccountData) => {
    let canSubmit =
      chartOfAccountData?.acc_code > 0 &&
      chartOfAccountData?.acc_desc?.length > 0 &&
      (chartOfAccountData?.acc_code
        ? validateNumbersOnly(chartOfAccountData?.acc_code)
        : true) &&
      (chartOfAccountData?.acc_desc
        ? validateCharctersNumberSpecialCharOnly(chartOfAccountData?.acc_desc)
        : true);

    return canSubmit;
  };

  postData = (data) => {
    this.setState({ isPosting: true });
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl +
        `/postchartofaccounts?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      this.props?.userContext?.active_tenant?.tenant_id,
      1
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isErrorMessageModalOpen: true,
            isMessageModalOpen: false,
            isPosting: false,
          });
        } else {
          this.setState({
            isMessageModalOpen: false,
            isPosting: false,
            postInitiated: false,
          });
          this.getChartOfAccountDetails();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({ isPosting: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  getChartOfAccountDetails = () => {
    let filters = { ...this.state.filters };
    filters.page_count = this.state.rowsPerPage;
    filters.page_no = this.state.page;
    filters.sort_by = this.state.sortBy;
    filters.sort_order = this.state.sortOrder;
    this.setState({
      isLoading: true,
      isSubmit: true,
      filters,
    });
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getchartofaccounts",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let tableResults = res.data?.records.map((obj) => {
          obj.is_active = obj.is_active ? 0 : 1;
          return obj;
        });
        let chartOfAccountDetail = tableResults;
        this.setState({
          renderList: chartOfAccountDetail,
          tableListSorted: tableResults,
          totalRows: res.data?.total_records_count,
          isLoading: false,
          isSubmit: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  getPosition = (val) => {
    switch (val) {
      case "Inactive":
        return 0;
      case "Detail#":
        return 1;
      case "Description":
        return 2;
      case "Created On":
        return 3;
      default:
        return 1;
    }
  };

  getColumnName = (val) => {
    switch (val) {
      case "Inactive":
        return "is_active";
      case "Detail#":
        return "acc_code";
      case "Description":
        return "acc_desc";
      case "Created On":
        return "created_at";
      default:
        return "Detail#";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let order = null;
    let config = JSON.parse(JSON.stringify(this.state?.config));
    if (this.state.a_z && config?.headings[Position]?.icon != "") {
      if (config?.headings[Position]?.icon == "sort-alpha-up") {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-down";
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      config?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      config.headings[Position].icon = "sort-alpha-up";
      order = "asc";
    }
    this.setState(
      { sortBy: col_name, sortOrder: order, config, page: 1 },
      () => {
        this.getChartOfAccountDetails();
      }
    );
  };

  onFilterChange = (filter, value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filter]: value,
      },
    }));
  };

  onKeyUpChange = (filter, value) => {
    this.setState({ page: 1 });
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [filter]: value,
          page_count: this.state.rowsPerPage,
          page_no: 1,
        },
      }),
      () => {
        this.getChartOfAccountDetails();
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ postInitiated: true });
    if (this.validateBeforeSave(newDataItem)) {
      let list = [...this.state.renderList];
      let newData = {
        is_active: newDataItem?.is_active ? 0 : 1,
        acc_code: newDataItem?.acc_code,
        acc_desc: newDataItem?.acc_desc,
      };
      list.push(newData);
      this.setState({
        renderList: list,
      });
      this.postData(newData);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => {
      this.getChartOfAccountDetails();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        page: 1,
      },
      () => {
        this.getChartOfAccountDetails();
      }
    );
  };

  arrayCheckUncheck = (id, dataItem) => {
    console.log(dataItem, "id", id);
  };

  handleDelCallBack = (id, dataItem) => {
    this.deleteItem = dataItem;
    this.setState({ isMessageModalOpen: true });
  };

  onModalConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postData(item);
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };
  render() {
    let isLoading = this.state.isPosting;
    return (
      <div>
        <MDBContainer className="chartOfAccountContainer">
          <MDBRow>
            <MDBCol md={2} className="search">
              <IconTextField
                placeholder={"Search by Detail # "}
                value={this.state?.filters?.acc_code || null}
                onChange={(e) =>
                  this.onFilterChange(
                    "acc_code",
                    e.target.value === null || e.target.value === ""
                      ? null
                      : e.target.value
                  )
                }
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.onKeyUpChange(
                      "acc_code",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    );
                  }
                }}
                icon={"search"}
              />
            </MDBCol>

            <MDBCol md={3} className="search">
              <IconTextField
                placeholder={"Search by Description "}
                value={
                  this.state?.filters?.description ||
                  validateCharctersNumberSpecialCharOnly
                }
                onChange={(e) =>
                  validateCharctersNumberSpecialCharOnly(e.target.value) &&
                  this.onFilterChange(
                    "description",
                    e.target.value === null || e.target.value === ""
                      ? null
                      : e.target.value
                  )
                }
                icon={"search"}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.onKeyUpChange(
                      "description",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    );
                  }
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="table-striped">
            {isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <TableComponent
                  list={this.state?.tableListSorted || []}
                  config={this.state?.config}
                  isLoading={this.state?.isLoading || false}
                  sortCallback={this.sortColumn}
                  stickyHeader={true}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  addItemToList={true}
                  arrayCheckUncheck={this.arrayCheckUncheck}
                  showModal={this.props.showModal}
                  showMandatory={this.state.postInitiated}
                  handleDelCallBack={this.handleDelCallBack}

                  // filterList={this.props.filterList || []}
                  //handleChipClose={this.props.handleChipClose}
                />
                <div className="Pagination">
                  <TablePagination
                    component="div"
                    count={this.state?.totalRows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    page={this.state?.page - 1}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state?.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </MDBRow>
        </MDBContainer>
        {this.state?.isMessageModalOpen && (
          <MessageModal
            open={this.state.isMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdmin}
            title={"Confirm Deletion"}
            primaryButtonText={"Ok"}
            secondaryButtonText={"Cancel"}
            hideCancel={false}
            onConfirm={() => this.onModalConfirm()}
            handleClose={(e) => this.setState({ isMessageModalOpen: false })}
          />
        )}
        {this.state?.isErrorMessageModalOpen && (
          <MessageModal
            open={this.state.isErrorMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdminError}
            title={"Message"}
            primaryButtonText={"Ok"}
            hideCancel={true}
            onConfirm={() => this.onErrorModalConfirm()}
            handleClose={(e) =>
              this.setState({ isErrorMessageModalOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

export default withUserContext(ChartOfAccount);
