import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { MDBIcon } from "mdbreact";
import BasicLabel from "../BasicLabel/BasicLabel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "./SelectField.scss";

export default class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      mandatoryWarning: "Mandatory Field",
      selectUnion: "Please select Union",
      placeHolderText: props.placeHolderText
        ? props.placeHolderText
        : "-Select",
    };
  }

  render() {
    let selectOptions = this.props?.options || [];
    let error =
      (this.props.showMandatory && !this.props?.value) ||
      this.props.fieldValid ||
      false;
    let showAsLabel = this.props.showAsLabel || false;
    const Placeholder = () => {
      return <div>{this.state.placeHolderText}</div>;
    };
    return (
      <div
        className={`SelectFieldContainer ${this.props?.className} ${
          error ? "error" : ""
        }`}
        style={{ width: this.props?.width ? this.props?.width : "" }}
      >
        {this.props?.label && (
          <BasicLabel
            text={this.props?.label || ""}
            type={"text"}
            isMandatory={this.props?.isMandatory || false}
          />
        )}
        {showAsLabel ? (
          <div className="showAsLabel">
            {selectOptions?.find((option) => option.value === this.props?.value)
              ?.label || ""}{" "}
          </div>
        ) : (
          <>
            <Select
              id={this.props?.id}
              value={this.props?.value || ""}
              variant="outlined"
              displayEmpty
              required={this.props?.isMandatory || false}
              className={
                this.props?.className && this.props?.value
                  ? this.props?.className
                  : this.props?.className && !this.props?.value
                  ? `${this.props?.className} placeHoldertext`
                  : !this.props?.className && this.props?.value
                  ? ""
                  : "placeHoldertext"
              }
              disabled={this.props?.disabled || false}
              onChange={this.props?.onChange}
              onOpen={() => this.setState({ isOpen: true })}
              onClose={() => this.setState({ isOpen: false })}
              IconComponent={() =>
                this.state.isOpen ? (
                  <MDBIcon icon="chevron-down" />
                ) : (
                  <MDBIcon icon="chevron-down" />
                )
              }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {/* <MenuItem disabled value="">{this.props?.defaultMenuText || "- Select -"}</MenuItem> */}
              <MenuItem disabled value="">
                {this.props?.defaultMenuText || this.state.placeHolderText}
              </MenuItem>
              {selectOptions?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    disabled={item.is_active === 0 ? true : false}
                    value={item.value}
                    className={
                      item.value === 9999 ? "bg-blue-custom-select-option" : ""
                    }
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {this.props?.showAdd && this.props?.value && (
              <AddCircleIcon
                className="m-1"
                onClick={this.props?.addCallback}
              />
            )}
          </>
        )}
        {error && (
          <div className="errorText">
            {this.props.showMandatory && !this.props?.value
              ? this.state.mandatoryWarning
              : this.props.fieldValid
              ? this.props.inValidInput
              : this.props.disableSignatory
              ? this.state.selectUnion
              : ""}
          </div>
        )}
      </div>
    );
  }
}
